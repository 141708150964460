// Import CSS
import 'simplelightbox/dist/simple-lightbox.css';
import '../css/lightbox.rotate.img.css';
// import 'datatables.net/css/datatables.min.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-fixedheader-bs5/css/fixedHeader.bootstrap5.min.css';
import contentUiCss from 'tinymce/skins/ui/oxide/content.css';
import contentCss from 'tinymce/skins/content/default/content.css';
// import 'dropzone/dist/dropzone.css';
// import 'dark-mode-switch/dark-mode.css'; // It's in myStyles.css
import '../css/styles.css';
import '../css/myStyles.css';
// Import JS
import * as bootstrap from 'bootstrap';
// import './bootstrap.bundle.min.js';
import './fonts.js';
// import * as feather from 'feather-icons'; // if not imported with webpack.ProvidePlugin
import Chart from 'chart.js/auto'; // https://www.chartjs.org/docs/latest/
import './theme.js';
import datatablesFrench from './datatables_fr_FR.json';
import jszip, { forEach } from 'jszip';
window.JSZip = jszip;
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import 'datatables.net';
import 'datatables.net-bs5';
// import 'datatables.net-autofill-bs5';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-colreorder-bs5';
import 'datatables.net-datetime';
// import 'datatables.net-fixedcolumns-bs5';
import 'datatables.net-fixedheader-bs5';
// import 'datatables.net-keytable-bs5';
// import 'datatables.net-responsive-bs5';
// import 'datatables.net-rowgroup-bs5';
// import 'datatables.net-rowreorder-bs5';
// import 'datatables.net-scroller-bs5';
import 'datatables.net-searchbuilder-bs5';
// import 'datatables.net-searchpanes-bs5';
import Swal from 'sweetalert2' // https://sweetalert2.github.io/
import tinymce from 'tinymce'; // https://www.tiny.cloud/docs/tinymce/6/
import 'tinymce/models/dom'
/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default';
/* A theme is also required */
import 'tinymce/themes/silver';
/* Import the skin */
import 'tinymce/skins/ui/oxide/skin.css';
/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/image';
/* Import tinyMCE's French language pack */
import './langs/fr_FR.js'
import './lightbox.rotate.img.js';
// import Dropzone from 'dropzone'; // https://docs.dropzone.dev/
import SimpleLightbox from "simplelightbox"; // https://simplelightbox.com/
// import simpleLightbox from "simpleLightbox/dist/simple-lightbox.jquery";
import regeneratorRuntime from "regenerator-runtime"; // Async/Await made available here
import 'litepicker'; // https://wakirin.github.io/Litepicker
import 'litepicker/dist/plugins/ranges';
// import 'litepicker/dist/plugins/mobilefriendly';
import 'dark-mode-switch'; // https://github.com/coliff/dark-mode-switch
import hotkeys from 'hotkeys-js'; // https://github.com/jaywcjlove/hotkeys-js
import { Calendar } from '@fullcalendar/core'; // https://fullcalendar.io/docs
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
// import multiMonthPlugin from '@fullcalendar/multimonth';
// import scrollGridPlugin from '@fullcalendar/scrollgrid';
import Sortable from 'sortablejs'; // https://github.com/SortableJS/Sortable - http://sortablejs.github.io/Sortable/

var globals,
App = {

	settings: {
		defLatLng: [48.86, 2.33],
		defZoom: 6,
		bottomScrolled: false,
		getFilter: false,
		myEvent: 'loadEvent',
		myPage: '',
		isMobile: false,
		serverAddress: (location.hostname!='localhost') ? "https://crm.secureboat.fr:8443/db/" : "https://crm.secureboat.fr:8443/db/",
		serverBaseUrl: (location.hostname!='localhost') ? "https://crm.secureboat.fr:8443/" : "https://crm.secureboat.fr:8443/",
		devBaseUrl: "http://localhost:8080/",
		baseQuery: {id: localStorage.getItem('id'), type: localStorage.getItem('type'), pwd: localStorage.getItem('pwd'), site: localStorage.getItem('site'), ap: ''},
		datatablesFrench: '',
		today: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
		yesterday: new Date(new Date().setDate(new Date().getDate()-1)),
		aWeekAgo: new Date(new Date().setDate(new Date().getDate()-7)),
		aMonthAgo: new Date(new Date().setDate(new Date().getDate()-29)),
		tomorrow: new Date(new Date().setDate(new Date().getDate()+1)),
		inaWeek: new Date(new Date().setDate(new Date().getDate()+7)),
		inThirtyDays: new Date(new Date().setDate(new Date().getDate()+30)),
		inSixtyDays: new Date(new Date().setDate(new Date().getDate()+60)),
		inNinetyDays: new Date(new Date().setDate(new Date().getDate()+90)),
		todayFrench: '',
		myLineChart: false,
		myBarChart: false,
		myPieChart: false,
		optionsTaxes: '',
		optionsUnits: '',
		icsFile: null,
		prevSearch: '',
		currentFormId: null,
		previousFormId: null,
		tinyInitialContent: '',
		originalMailSubject: '',
		originalMailBody: '',
		canvases: [],
		pass: localStorage.getItem('pass'),
		login: localStorage.getItem('login'),
		pwd: localStorage.getItem('pwd'),
		name: localStorage.getItem('name'),
		firstname: localStorage.getItem('firstname'),
		phone: localStorage.getItem('phone'),
		id: localStorage.getItem('id'),
		email: localStorage.getItem('email'),
		active: localStorage.getItem('active'),
		type: localStorage.getItem('type'),
		adminPass: '',
		mails: localStorage.getItem('mails'),
		phone2: localStorage.getItem('phone2'),
		address: localStorage.getItem('address'),
		address2: localStorage.getItem('address2'),
		zip: localStorage.getItem('zip'),
		city: localStorage.getItem('city'),
		country: localStorage.getItem('country'),
		wpid: localStorage.getItem('wpid'),
		birthdate: localStorage.getItem('birthdate'),
		job: localStorage.getItem('job'),
		avatar: localStorage.getItem('avatar'),
		initials: localStorage.getItem('initials'),
		comments: localStorage.getItem('comments'),
		site: localStorage.getItem('site'),
		changeSite: localStorage.getItem('changeSite'),
		siteSettings: localStorage.getItem('siteSettings')
	},
	refreshGlobals: function(data) {
		globals.pass = data.pass;
		globals.login = data.login;
		globals.pwd = data.pwd;
		globals.name = data.name;
		globals.firstname = data.firstname;
		globals.phone = data.phone;
		globals.id = data.id;
		globals.email = data.email;
		globals.active = data.active;
		globals.type = data.type;
		globals.mails = data.mails;
		globals.phone2 = data.phone2;
		globals.address = data.address;
		globals.address2 = data.address2;
		globals.zip = data.zip;
		globals.city = data.city;
		globals.country = data.country;
		globals.wpid = data.wpid;
		globals.birthdate = data.birthdate;
		globals.job = data.job;
		globals.avatar = data.avatar;
		globals.initials = data.initials;
		globals.comments = data.comments;
		globals.site = data.site;
		// console.log("refreshGlobals: "+globals.site);
	},
	init: function() {
		// kick things off
		globals = this.settings;
		// Dropzone.autoDiscover = false;
		this.bindUIActions();
		$("#now-date").append(globals.year);
		fetch(datatablesFrench).then((res) => res.json()).then((data) => { // Fetching DataTables French locales
			globals.datatablesFrench = data;
			// console.log(globals.datatablesFrench);
		});
	},
	checkAccountStatus: async function () {
		await $.post(globals.serverAddress, {id: localStorage.getItem('id'), login: localStorage.getItem('login'), pwd: localStorage.getItem('pwd'), site: localStorage.getItem('site'), req: 'checkAccountStatus'}, function(data){
			if(data.check!="GO") {
				Swal.fire({
					title: 'Problème de vérification de compte',
					html: '<p class="text-danger">Il y a un problème avec votre compte, veuillez réessayer plus tard.<br>Si vous pensez qu\'il s\'agit d\'une erreur, veuillez nous contacter.</p>',
					icon: 'error',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#d01b09',
					timer: 2600,
					timerProgressBar: true,
				})
				App.logMeOut();
				return;
			}
			else {
				// Every things is ok so we refresh globals and we kick things off...
				localStorage.setItem('login', data.login);
				localStorage.setItem('pwd', data.pwd);
				localStorage.setItem('name', data.name);
				localStorage.setItem('firstname', data.firstname);
				localStorage.setItem('phone', data.phone);
				localStorage.setItem('email', data.email);
				localStorage.setItem('id', data.id);
				localStorage.setItem('active', data.active);
				localStorage.setItem('type', data.type);
				localStorage.setItem('mails', data.mails);
				localStorage.setItem('phone2', data.phone2);
				localStorage.setItem('address', data.address);
				localStorage.setItem('address2', data.address2);
				localStorage.setItem('zip', data.zip);
				localStorage.setItem('city', data.city);
				localStorage.setItem('country', data.country);
				localStorage.setItem('wpid', data.wpid);
				localStorage.setItem('birthdate', data.birthdate);
				localStorage.setItem('job', data.job);
				localStorage.setItem('avatar', data.avatar);
				localStorage.setItem('initials', data.initials);
				localStorage.setItem('comments', data.comments);
				localStorage.setItem('siteSettings', data.siteSettings);
				App.settings.siteSettings = data.siteSettings;
				if(!globals.changeSite) {
					localStorage.setItem('site', data.site);
					localStorage.setItem('logoPath', data.logoPath);
				}
				else data.site = globals.site; // have to set changeSite selectBox value there before refreshGlobals call
				// console.log("checkAccountStatus: "+globals.site);
				globals.adminPass = data.adminPass;
				globals.baseQuery = {id: data.id, type: data.type, pwd: data.pwd, site: data.site, ap: data.adminPass}
				App.refreshGlobals(data);
				setTimeout(function(){
					App.kickThingsOffOnceLoggedIn();
				}, 100);
			}
		}, "json").fail(function(){
			Swal.fire({
				title: 'Problème de vérification de compte',
				html: '<p class="text-danger">Suite à un problème technique nous ne pouvons vérifier votre compte, veuillez réessayer plus tard.<br>Si l\'erreur persiste, veuillez nous contacter.</p>',
				icon: 'error',
				confirmButtonText: 'Ok',
				confirmButtonColor: '#d01b09',
				timer: 2600,
				timerProgressBar: true,
			})
			App.logMeOut();
		});
	},
	logMeIn: function (myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv).closest('.row').css('opacity', 0.6);
		let query = $(myFormDiv).serialize();
		const req = "login";
		query = query + "&req=" + req;
		$.post(globals.serverAddress, query, function(data){
			if(data.pass == "OK") {
				localStorage.setItem('pass', data.pass);
				localStorage.setItem('login', data.login);
				localStorage.setItem('pwd', data.pwd);
				localStorage.setItem('name', data.name);
				localStorage.setItem('firstname', data.firstname);
				localStorage.setItem('phone', data.phone);
				localStorage.setItem('email', data.email);
				localStorage.setItem('id', data.id);
				localStorage.setItem('active', data.active);
				localStorage.setItem('type', data.type);
				localStorage.setItem('mails', data.mails);
				localStorage.setItem('phone2', data.phone2);
				localStorage.setItem('address', data.address);
				localStorage.setItem('address2', data.address2);
				localStorage.setItem('zip', data.zip);
				localStorage.setItem('city', data.city);
				localStorage.setItem('country', data.country);
				localStorage.setItem('wpid', data.wpid);
				localStorage.setItem('birthdate', data.birthdate);
				localStorage.setItem('job', data.job);
				localStorage.setItem('avatar', data.avatar);
				localStorage.setItem('initials', data.initials);
				localStorage.setItem('comments', data.comments);
				if(!globals.changeSite) localStorage.setItem('site', data.site);
				globals.adminPass = data.adminPass;
				setTimeout(function(){
					App.refreshGlobals(data);
				}, 100);
				setTimeout(function(){
					document.location.href='/';
				}, 1000);
			}
			else { // data.active equals 0 anyway
				if(data.badid==0) alert("Ce compte a été désactivé !");
				else alert("Identifiant ou mot de passe erroné !");
			}
		}, "json").always(function() {
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<b><i class="fa fa-unlock"></i> Connexion</b>');
			$(myFormDiv).closest('.row').css('opacity', 1);
		});
	},
	logMeOut: function () {
		localStorage.clear();
		setTimeout(function(){
			document.location.href='/login.html';
			// document.location.href='https://crm.secureboat.fr:8443';
		}, 1000);
	},
	subContact: function (myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		let query = $(myFormDiv).serialize();
		const req = "contact";
		query = query + "&req=" + req;
		let returns = "";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-2x fa-check-circle me-1"></i>Votre message a bien été envoyé.</b></div>';
				Swal.fire({
					title: 'Votre message a bien été envoyé',
					html: '<i class=fa fa-2x fa-paper-plane></i>',
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				setTimeout(function(){
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-2x fa-times-circle me-1"></i>Votre message n\'a pas été envoyé.</b></div>';
		}, "json").always(() => {
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('Envoyer&nbsp;<i class="fa fa-paper-plane"></i>');
			$(myFormDiv+' [data-successfail]').html(returns);
		});
	},
	bindUIActions: async function() {
		// Connected or not
		if(globals.pass == "OK") {
			App.checkAccountStatus();
			// App.kickThingsOffOnceLoggedIn();
		}
		// Is it Mobile device
		if(/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent) || window.innerWidth<992) globals.isMobile = true;
		if(globals.isMobile) {
			$('#myTab').removeClass('nav-tabs').addClass('nav-pills');
			// $('input[name^="date"]').attr('type', 'date');
		}
		$('.expends').on("click", function () {
			$(this).next('div').slideToggle('slow');
			//$.mobile.silentScroll($(this).next('div').offset().top);
		});
		// Managing navigation so that we bindUIActions on the right page...
		let url = window.location.pathname;
		globals.myPage = url.substring(url.lastIndexOf('/')+1);
		sessionStorage.setItem('myPage', globals.myPage);
		if(globals.myPage!='login.html') await App.getSitesListBox();
		// Smooth Scroll to div...
		App.refreshSmoothScroll();
		// Dealing with darkModeSwitch additionnal classes & localStorage
		let darkSwitch = localStorage.getItem('darkSwitch');
		if(darkSwitch=='dark') {
			$('.sidenav').removeClass('sidenav-light').addClass('sidenav-dark');
			$('.sideNavLogo').toggleClass('d-none');
		}
		else $('.sidenav').removeClass('sidenav-dark').addClass('sidenav-light');
		// Defining `ctrl + s` & `ctrl + e` for Windows systems and `command + s` & `command + e` for MacOS ones...
		hotkeys('ctrl+s, command+s, ctrl+e, command+e', function(event, handler) { // handler object contains the keys combination used
			event.preventDefault(); // Prevent the default saving event under WINDOWS systems
			if(globals.currentFormId) {
				globals.currentFormId = (globals.currentFormId.includes('#')) ? globals.currentFormId.replace('#','') : globals.currentFormId;
				const currentFormObject = document.getElementById(globals.currentFormId);
				let isVisible = $(currentFormObject).is(":visible") && !$(currentFormObject).is(":hidden"); // Checks if parents elements are visible
				// const isVisible = currentFormObject.style.display!='none' && !currentFormObject.hasAttribute('hidden'); // Only checks if form is visible
				let validForm = currentFormObject.checkValidity();
				const disabledForm = currentFormObject.querySelector('[type="submit"]').getAttribute('disabled');
				const submitableForm = (disabledForm) ? false : true;
				console.warn(globals.currentFormId, isVisible, validForm, submitableForm);
				if(!submitableForm) return; // Form submit is disabled
				if(isVisible && validForm) {
					currentFormObject.submit();
					console.warn('Stopped saving html page, instead submited #'+globals.currentFormId+' => '+handler.key);
					if(handler.key=='command+s') {
						alert('Enregistrement de la fiche courrante...'); // Prevent the default saving event under MacOS systems
						return false; // Returning false stops the event and prevents default browser events
					}
				}
				else if (isVisible && !validForm) App.addWasValidatedClass('#'+globals.currentFormId);
				else {
					if(globals.previousFormId) {
						globals.previousFormId = (globals.previousFormId.includes('#')) ? globals.previousFormId.replace('#','') : globals.previousFormId;
						const previousFormObject = document.getElementById(globals.previousFormId);
						isVisible = $(previousFormObject).is(":visible") && !$(previousFormObject).is(":hidden");
						validForm = previousFormObject.checkValidity();
						const disabledForm = currentFormObject.querySelector('[type="submit"]').getAttribute('disabled');
						const submitableForm = (disabledForm) ? false : true;
						console.warn(globals.previousFormId, isVisible, validForm, submitableForm);
						if(!submitableForm) return; // Form submit is disabled
						if(isVisible) {
							// Switching previous and current forms as previous is visible...
							const thisFormId = globals.previousFormId;
							globals.previousFormId = globals.currentFormId;
							globals.currentFormId = thisFormId;
							if(validForm) {
								previousFormObject.submit();
								console.warn('Stopped saving html page, instead submited #'+thisFormId+' => '+handler.key);
								if(handler.key=='command+s') {
									alert('Enregistrement de la fiche courrante...'); // Prevent the default saving event under MacOS systems
									return false; // Returning false stops the event and prevents default browser events
								}
							}
							else App.addWasValidatedClass('#'+thisFormId);
						}
						else {
							alert('Je ne sais pas quoi enregister !');
							console.log('HotKeys => No Form to submit !')
						}
					}
					else alert('Je ne sais pas quoi enregister !');
				}
			}
			else alert('Je ne sais pas quoi enregister !');
		});
		hotkeys.filter = function(event){
			return true; // Activate hotkeys everywhere as => By default hotkeys are not enabled for INPUT SELECT & TEXTAREA elements
		}
		// Prevent Bootstrap dialog from blocking focusin => For tinyMCE's dialogs to work well
		document.addEventListener('focusin', (e) => {
			if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
				e.stopImmediatePropagation();
			}
		})
		document.addEventListener("scroll", function (event) {
			if (App.getDocHeight() == App.getScrollXY()[1] + window.innerHeight) {
				//$('.go-up-fixed').fadeOut('slow');
				globals.bottomScrolled=true;
			}
			else {
				globals.bottomScrolled=false;
				if(App.getScrollXY()[1] == 0) {
					$('.go-up-fixed').fadeOut('slow');
				}
				else
					$('.go-up-fixed').fadeIn('slow');
			}
		});
	},
	kickThingsOffOnceLoggedIn: async function() {
		this.displayUserTypesRelated();
		this.buildTinyMceTextArea('tinyMceMailBody') // Index's sendMailModal one
		this.getFormsSelectBoxes(['#sendMailForm'], 'mailController', ['mail_model'])
		if(globals.avatar != '') $("#avatarNavCont").html('<img class="img-fluid" src="'+globals.serverBaseUrl+'docs/avatars/'+globals.avatar+'" />');
		else $("#avatarNavCont").html('<span class="dropdown-user-img text-center fs-2 text-white">'+globals.initials+'</span>');
		$("#userInitialsNav").html(globals.initials);
		$(".profile-links").attr("href", "/users/"+globals.id);
		$('.my-account-name').html(globals.name+' '+globals.firstname);
		$('.my-account-mail').html(globals.email);
		$('#modal_contact #from_name').val(globals.name+' '+globals.firstname);
		$('#modal_contact #from_email').val(globals.email);
		$('#modal_contact #from_phone').val(globals.phone);
		App.getNavNotifications();
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#addProductForm'], 'productsController', ['tax_p', 'unit_p', 'user_p', 'site_p']);
		$("#addProductForm [data-field=tax_p]").attr("data-default", globals.siteSettings?.taxes_s).val(globals.siteSettings?.taxes_s);
	},
	displayUserTypesRelated: function() {
		// Display .adminOnly, .accountantOnly... Objects
		switch (this.settings.type) {
			case 'TheKingOfInvoice':
				$(".adminOnly").show();
				$(".adminOnlyFlex").css('display', 'flex');
				$(".adminHide").hide();
				break;
			case 'manager':
				$(".managerOnly").show();
				$(".managerOnlyFlex").css('display', 'flex');
				$(".managerHide").hide();
				$(".allUsersHide").hide();
				$('.allUsersRemove').remove();
				$('.allUsersReadOnly').attr('readonly', true);
				$('.usersDisabledOptions').hide().prop("disabled", true); // Display none or hide won't work in Safari but disable works
				break;
			case 'accountant':
				$(".accountantOnly").show();
				$(".accountantOnlyFlex").css('display', 'flex');
				$(".accountantHide").hide();
				$(".allUsersHide").hide();
				$('.allUsersRemove').remove();
				$('.allUsersReadOnly').attr('readonly', true);
				$('.usersDisabledOptions').hide().prop("disabled", true); // Display none or hide won't work in Safari but disable works
				break;
			case 'user':
				$(".userOnly").show();
				$(".userOnlyFlex").css('display', 'flex');
				$(".userHide").hide();
				$(".allUsersHide").hide();
				$('.allUsersRemove').remove();
				$('.allUsersReadOnly').attr('readonly', true);
				$('.usersDisabledOptions').hide().prop("disabled", true); // Display none or hide won't work in Safari but disable works
				break;
			case 'worker':
				$(".workerOnly").show();
				$(".workerOnlyFlex").css('display', 'flex');
				$(".workerHide").hide();
				$(".allUsersHide").hide();
				$('.allUsersRemove').remove();
				$('.allUsersReadOnly').attr('readonly', true);
				$('.usersDisabledOptions').hide().prop("disabled", true); // Display none or hide won't work in Safari but disable works
				break;
			case 'meca':
				$(".workerOnly").show();
				$(".workerOnlyFlex").css('display', 'flex');
				$(".workerHide").hide();
				$(".allUsersHide").hide();
				$('.allUsersRemove').remove();
				$('.allUsersReadOnly').attr('readonly', true);
				$('.usersDisabledOptions').hide().prop("disabled", true); // Display none or hide won't work in Safari but disable works
				break;
			case 'docker':
				$(".workerOnly").show();
				$(".workerOnlyFlex").css('display', 'flex');
				$(".workerHide").hide();
				$(".allUsersHide").hide();
				$('.allUsersRemove').remove();
				$('.allUsersReadOnly').attr('readonly', true);
				$('.usersDisabledOptions').hide().prop("disabled", true); // Display none or hide won't work in Safari but disable works
				break;
			case 'tech':
				$(".workerOnly").show();
				$(".workerOnlyFlex").css('display', 'flex');
				$(".workerHide").hide();
				$(".allUsersHide").hide();
				$('.allUsersRemove').remove();
				$('.allUsersReadOnly').attr('readonly', true);
				$('.usersDisabledOptions').hide().prop("disabled", true); // Display none or hide won't work in Safari but disable works
				break;
			default:
				$(".userOnly").show();
				$(".userOnlyFlex").css('display', 'flex');
				$(".userHide").hide();
				$(".allUsersHide").hide();
				$('.allUsersRemove').remove();
				$('.allUsersReadOnly').attr('readonly', true);
				$('.usersDisabledOptions').hide().prop("disabled", true); // Display none or hide won't work in Safari but disable works
		}
		// Managing navigation so that we bindUIActions on the right page...
		let url = window.location.pathname;
		globals.myPage = url.substring(url.lastIndexOf('/')+1);
		sessionStorage.setItem('myPage', globals.myPage);
		// if(globals.isMobile) $('input[name^="date"]').attr('type', 'date');
	},
	getSitesListBox: async function() {
		const req = "navigationController";
		const action = "getSitesListBox";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action;
		await $.post(globals.serverAddress, query, function(data){
			$("#siteGlobal").html(data.snippet); // In sidebar
			$("#addUserForm #site_u").html(data.snippetAddUserForm); // In addUserModal
			globals.optionsTaxes = data.optionsTaxes;
			globals.optionsUnits = data.optionsUnits;
		}, "json").done(function(){
			if(globals.changeSite || globals.changeSite===null) $('#siteGlobal').val(globals.site);
			const logoPath = $('#siteGlobal option:selected').attr('data-logo') // Refresh logo anyway
			if(logoPath) {
				document.querySelectorAll('.sideNavLogo').forEach((elem) => {
					elem.setAttribute('src',logoPath)
				})
				localStorage.setItem('logoPath', logoPath);
			}
			$('.siteName').html($('#siteGlobal option:selected').text()); // Used at least on DashBoards...
			console.log("getSitesListBox: "+globals.changeSite+" - "+globals.site);
		}).always(function(){
			// App.getFormsSelectBoxes(['#addInterventionForm'], 'interventionsController', ['IdMere','ThemeClient','IdClientInter','Intervenant']);
		});
	},
	getFormsSelectBoxes: async function(formsDivArray, myController, inputsArray) {
		let result;
		// Using try to get error handling, in order to have just the promise : const result = await $.post(...) would have done it.
		try {
			result = $.post(globals.serverAddress, {id: globals.id, type: globals.type, site: globals.site, pwd: globals.pwd, ap: globals.adminPass, req: myController, action: 'getFormsSelectBoxes'}, function(data){
				if(formsDivArray.length>0 && inputsArray.length>0) {
					formsDivArray.forEach((formDiv, indexDiv) => {
						inputsArray.forEach((input, index) => {
							$(formDiv+" #"+input).html(data[input]); // Can't use data.input here
							$(formDiv+' [data-field="'+input+'"]').html(data[input]); // Cleaner: Avoids un-unique ids by usings data-field attribute
							// console.log(formDiv+" #"+input+" | "+data[input]);
						});
					});
				}
			}, "json").always(function() {
				App.displayUserTypesRelated(); // We may need it for usersDisabledOptions
			});
			return result;
		} catch (error) {
			console.error(error);
		}
	},
	getNavNotifications: function() {
		const req = "navigationController";
		const action = "getNavNotifications";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&ap=" + globals.adminPass + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action;
		$.post(globals.serverAddress, query, function(data){
			$("#navbarDropdownAlerts").html(data.badge);
			$("#navNotificationsCont").html(data.snippet);
		}, "json").always(function(){
			// Refreshing alerts every now and then...
			setTimeout(function(){
				App.getNavNotifications();
			}, 360000);
		});
	},
	globalSearch: function(thisElement, inPage = false) { // thisElement is either a form object or an input object
		const search = (thisElement.tagName.toLowerCase()=='form') ? thisElement.querySelector('input').value : thisElement.value;
		// window.open('https://www.google.fr/search?q='+search, '_blank');
		const pageLoaded = !inPage;
		if(sessionStorage.getItem('myPage')!='search') document.location.href = '/search/'+search; // Comming from any page but not searchPage
		else this.setSearchPage(search, pageLoaded); // coming from searchPage
	},
	setSearchPage: function(search, pageLoaded = false) {
		if(pageLoaded) {
			$('#globalSearchBar').val(search);
			// Building meta tags list...
			$('head title').text('CRM SecureBoat Recherche '+search);
			$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Recherche');
		}
		else {
			// This will replace the current entry in the browser's history, without reloading
			const nextURL = '/search/'+search;
			const nextTitle = 'CRM SecureBoat Recherche '+search;
			const nextState = { additionalInformation: 'Updated search URL with '+search };
			window.history.replaceState(nextState, nextTitle, nextURL);
		}
		if(search.trim().length>2 && globals.prevSearch!=search) {
			$('#globalSearchBar').attr('onkeyup', '');
			globals.prevSearch = search;
			const myLoader = `<div class="text-center p-5">
                <h1 class="text-xl">Chargement...</h1>
                <i class="fa fa-10x fa-spinner fa-spin-pulse"></i>
            </div>`;
			$("#globalSearchResultsCont").html(myLoader);
			const req = "navigationController";
			const action = "globalSearch";
			const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action + "&search=" + search;
			$.post(globals.serverAddress, query, function(data){
				if(data.ok=='ok') $("#globalSearchResultsCont").html(data.snippet);
				else $("#globalSearchResultsCont").html(`<div class="text-center p-5"><h1 class="text-xl">Aucun résultat</h1><h1 class="text-xl">Peut-être avec eux...</h1><img class="img-fluid" src="${globals.serverBaseUrl}online_assets/undraw_search_engines.svg"></div>`);
				const howManyClients = (data.howManyClients>1) ? `${data.howManyClients} Clients trouvés.` : `${data.howManyClients} Client trouvé.`
				const howManyContacts = (data.howManyContacts>1) ? `${data.howManyContacts} Contacts trouvés.` : `${data.howManyContacts} Contact trouvé.`
				const howManyProducts = (data.howManyProducts>1) ? `${data.howManyProducts} Produits trouvés.` : `${data.howManyProducts} Produit trouvé.`
				const howManyOrders = (data.howManyOrders>1) ? `${data.howManyOrders} Ventes trouvées.` : `${data.howManyOrders} Vente trouvée.`
				const howManyPlannings = (data.howManyPlannings>1) ? `${data.howManyPlannings} Interventions trouvées.` : `${data.howManyPlannings} Intervention trouvée.`
				$("#searchCount").html(`${howManyClients}.<br>${howManyContacts}.<br>${howManyProducts}.<br>${howManyOrders}.<br>${howManyPlannings}.`);
			}, "json").always(function(){
				$('#globalSearchBar').attr('onkeyup', 'App.globalSearch(this, true);');
				const newSearch = $('#globalSearchBar').val();
				if(globals.prevSearch!=newSearch) $('#globalSearchBar').trigger('keyup');
			}).fail(function(jqXHR, textStatus, errorThrown){
				$("#globalSearchResultsCont").html(`<div class="text-center text-danger p-5"><h1 class="text-xl">Erreur :</h1><p class="text-medium">${textStatus}<br>${errorThrown}</p><i class="fa fa-6x fa-circle-xmark"></i></div>`);
			});
		}
		else if(search=='') {
			$("#globalSearchResultsCont").html(`<div class="text-center text-primary p-md-5"><p class="text-large mb-5">Veuillez taper une recherche ci-dessus...</p><i class="fa fa-10x fa-angles-up fa-bounce mt-5 me-2"></i><i class="fa fa-10x fa-keyboard fa-bounce mt-5"></i></div>`);
		}
	},
	changeSite: async function(thisBox, reload = true) {
		globals.site = thisBox.value;
		const logoPath = thisBox.selectedOptions[0].getAttribute('data-logo');
		localStorage.setItem('site', globals.site);
		if(thisBox.value!='0') {
			globals.changeSite = true;
			localStorage.setItem('changeSite', true);
			localStorage.setItem('logoPath', logoPath);
			document.querySelectorAll('.sideNavLogo').forEach((elem) => {
				elem.setAttribute('src',logoPath)
			})
		}
		else {
			globals.changeSite = false;
			localStorage.setItem('changeSite', false);
		}
		if(reload) {
			setTimeout(function() {
				document.location.reload();
			}, 100);
		}
		else {
			document.getElementById('siteGlobal').value = thisBox.value;
			await this.checkAccountStatus() // Need to reload localStorage at least for siteSettings
		}
	},
	setHomePage: function() {
		// Building meta tags list...
		$('head title').text('Accueil - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | DashBoard');
	},
	setDashPage: function() {
		this.getDashBadges('global');
		this.getGraphicalLineStats('global');
		this.getGraphicalBarStats('global')
		this.getGraphicalPieStats('global');
		// this.getDashProgressBars('global');
		const aWeekAgo = new Date(new Date().setDate(new Date().getDate()-7));
		const aMonthAgo = new Date(new Date().setDate(new Date().getDate()-29));
		const litepickerRangePlugin = document.getElementById('litepickerRangePlugin');
		if (litepickerRangePlugin) {
			new Litepicker({
				element: litepickerRangePlugin,
				startDate: aMonthAgo,
				endDate: new Date(),
				singleMode: false,
				numberOfMonths: 2,
				numberOfColumns: 2,
				format: 'DD/MM/YYYY',
				delimiter: ' > ',
				lang: 'fr-FR',
				tooltipText: {
					one: 'jour',
					other: 'jours'
				},
				tooltipNumber: (totalDays) => {
					return totalDays;
				},
				plugins: ['ranges'],
				ranges: {
					customRanges: {
						'Aujourd\'hui': [new Date(), new Date()],
						'Hier': [new Date(new Date().setDate(new Date().getDate()-1)), new Date(new Date().setDate(new Date().getDate()-1))],
						'Derniers 7 Jours': [aWeekAgo, new Date()],
						'Derniers 30 jours': [new Date(new Date().setDate(new Date().getDate()-29)), new Date()],
						'Ce Mois': [new Date(new Date().setDate(1)), new Date()],
						'Dernier Mois': [new Date(new Date().getFullYear(), new Date().getMonth()-1, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)],
						'Cette Année': [new Date(new Date().getFullYear(), 0, 1), new Date()],
						'Année 365': [new Date(new Date().setDate(new Date().getDate()-364)), new Date()],
					},
					// customRangesLabels:["Aujourd'hui","Hier","Derniers 7 Jours","Derniers 30 jours","Ce Mois","Dernier Mois","Cette Année"],
				},
				setup: (picker) => {
					picker.on('selected', (dateStart, dateEnd) => {
						App.refreshDashBoards('global', litepickerRangePlugin);
					});
				},
			});
		}
		// Building meta tags list...
		$('head title').text('DashBoard - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | DashBoard');
	},
	refreshDashBoards: function(dashType, thisInput) {
		const range = $(thisInput).val()
		this.getGraphicalLineStats(dashType, range)
		// this.getGraphicalPieStats(dashType)
		this.getGraphicalBarStats(dashType, range)
		// this.getDashProgressBars(dashType, range)
	},
	getDashBadges: function(dashType) {
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'dashController', action: 'getDashBadges', dash: dashType}, function(data){
			$("#badgeClientsTotal").html(data.Client);
			$("#badgeProspectsTotal").html(data.Prospect);
			$("#badgeFournisseursTotal").html(data.Fournisseur);
			$("#badgeMixtesTotal").html(data.Mixte);
		}, "json").done(function(data) {
			// $('.siteName').html($('#siteGlobal option:selected').text());
		});
	},
	getGraphicalLineStats: function(dashType, myRange) {
		if(globals.myLineChart) globals.myLineChart.destroy();
		$.post(globals.serverAddress, {...globals.baseQuery, range: myRange, req: 'dashController', action: 'getGraphicalLineStats'}, function(data){
			globals.myLineChart = new Chart(document.getElementById("myAreaChart"), {
				type: "line",
				data: {
					labels: data.lineLabels,
					datasets: [{
						label: "Devis créés",
						lineTension: 0.3,
						backgroundColor: "rgba(105, 0, 199, 0.05)",
						borderColor: "rgba(8, 159, 227, 0.6)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(8, 159, 227, 0.6)",
						pointBorderColor: "rgba(8, 159, 227, 0.6)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(8, 159, 227, 0.6)",
						pointHoverBorderColor: "rgba(8, 159, 227, 0.6)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.lineDataQuotationsMade
					}, {
						label: "Devis validés",
						lineTension: 0.3,
						backgroundColor: "rgba(105, 0, 199, 0.1)",
						borderColor: "rgba(8, 159, 227, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(8, 159, 227, 1)",
						pointBorderColor: "rgba(8, 159, 227, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(8, 159, 227, 1)",
						pointHoverBorderColor: "rgba(8, 159, 227, 1)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.lineDataQuotationsValid
					}, {
						label: "Factures émises",
						lineTension: 0.3,
						backgroundColor: "rgba(255, 193, 71, 0.05)",
						borderColor: "rgba(240, 125, 1, 0.6)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(240, 125, 1, 0.6)",
						pointBorderColor: "rgba(240, 125, 1, 0.6)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(240, 125, 1, 0.6)",
						pointHoverBorderColor: "rgba(240, 125, 1, 0.6)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.lineDataInvoicesMade
					}, {
						label: "Factures payées",
						lineTension: 0.3,
						backgroundColor: "rgba(255, 193, 71, 0.1)",
						borderColor: "rgba(240, 125, 1, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(240, 125, 1, 1)",
						pointBorderColor: "rgba(240, 125, 1, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(240, 125, 1, 1)",
						pointHoverBorderColor: "rgba(240, 125, 1, 1)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.lineDataInvoicesPayed
					}]
				},
				options: {
					responsive: true,
					maintainAspectRatio:false,
					legend:{'display':true, position: 'bottom'},
					layout: {
						padding: {
							left: 10,
							right: 25,
							top: 25,
							bottom: 0
						},
						// height: 460,
					},
					scales: {
						x: {
							time: {
								unit: "date"
							},
							gridLines: {
								display: false,
								drawBorder: false
							},
							ticks: {
								maxTicksLimit: 7
							}
						},
						y: {
							ticks: {
								maxTicksLimit: 5,
								padding: 10,
								// Format numbers in the ticks
								callback: function(value, index, values) {
									return App.number_format(value, 2, ',', ' ')+'€';
								}
							},
							gridLines: {
								color: "rgb(234, 236, 244)",
								zeroLineColor: "rgb(234, 236, 244)",
								drawBorder: false,
								borderDash: [2],
								zeroLineBorderDash: [2]
							}
						}
					},
					plugins: {
						tooltip: {
							callbacks: {
								label: function(context) {
									let label = context.dataset.label || '';
									if (context.parsed.y !== null) {
										label += ": " + App.number_format(context.parsed.y, 2, ',', ' ')+'€';
										// label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
									}
									return label;
								}
							}
						}
					}
				}
			});
		}, "json");
	},
	getGraphicalPieStats: function(dashType) {
		if(globals.myPieChart) globals.myPieChart.destroy();
		$.post(globals.serverAddress, {...globals.baseQuery, dash: dashType, req: 'dashController', action: 'getGraphicalPieStats'}, function(data){
			// var ctx = document.getElementById("myPieChart");
			globals.myPieChart = new Chart(document.getElementById("myPieChart"), {
				type: "pie",
				data: {
					labels: data.pieLabels,
					datasets: [{
						label: ' Ventes en €',
						data: data.pieData,
						backgroundColor: [
							"rgba(8, 159, 227, 1)",
							"rgba(240, 125, 1, 1)",
							"rgba(183, 12, 127, 1)",
							"rgba(199, 211, 2, 1)",
							"rgba(208, 27, 9, 1)",
							"rgba(33, 40, 50, 1)",
							"rgba(0, 136, 153, 1)"
						],
						hoverBackgroundColor: [
							"rgba(8, 159, 227, 0.9)",
							"rgba(240, 125, 1, 0.9)",
							"rgba(183, 12, 127, 0.9)",
							"rgba(199, 211, 2, 0.9)",
							"rgba(208, 27, 9, 0.9)",
							"rgba(33, 40, 50, 0.9)",
							"rgba(0, 136, 153, 0.9)"
						],
						hoverBorderColor: "rgba(234, 236, 244, 1)",
						hoverOffset: 6,
					}]
				},
				options: {
					maintainAspectRatio: false,
					legend: {
						display: false
					},
					cutoutPercentage: 80,
				}
			});
		}, "json");
	},
	getGraphicalBarStats: function(dashType, myRange) {
		if(globals.myBarChart) globals.myBarChart.destroy();
		$.post(globals.serverAddress, {...globals.baseQuery, range: myRange, req: 'dashController', action: 'getGraphicalBarStats'}, function(data){
			const ctx1 = document.getElementById("myBarChart");
			globals.myBarChart = new Chart(ctx1, {
				type: "bar",
				data: {
					labels: data.barLabels,
					datasets: [{
						label: "Produits & Services les plus vendus",
						data: data.barData,
						backgroundColor: "rgba(8, 159, 227, 0.2)",
						hoverBackgroundColor: "rgba(8, 159, 227, 0.6)",
						borderColor: "rgba(8, 159, 227, 1)",
						maxBarThickness: 90,
						borderWidth: 2
					}/*, {
						label: "Services vendus",
						data: data.barData,
						backgroundColor: "rgba(240, 125, 1, 0.2)",
						hoverBackgroundColor: "rgba(240, 125, 1, 0.6)",
						borderColor: "rgba(240, 125, 1, 1)",
						maxBarThickness: 90,
						borderWidth: 2
					}*/]
				},
				options: {
					maintainAspectRatio: false,
					layout: {
						padding: {
							left: 10,
							right: 25,
							top: 25,
							bottom: 0
						}
					},
					legend: {
						display: false
					},
					plugins: {
						tooltip: {
							callbacks: {
								label: function(context) {
									let label = context.dataset.label || '';
									if (context.parsed.y !== null) label += ": " + App.number_format(context.parsed.y);
									return label;
								}
							}
						}
					}
				}
			});
		}, "json");
	},
	setUsersListPage: async function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		if(globals.type=='TheKingOfInvoice' && globals.adminPass=='') await App.checkAccountStatus(); // When user's page is directly loaded adminPass may not already be set
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'usersController', action: 'getUsersList'}, function(data){
			$("#userListCont").html(data.snippet);
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableUsersList', 25, true, 12, 'desc', 'CRM SecureBoat Liste des Utilisateurs', [0,3,4,5,6,7,8,9,10,11], [1,2,12], '', '', '', [0,1,2,3,4,5,6,7,8,9,10,11,12], true);
		}).always(function(){
			App.getFormsSelectBoxes(['#addUserForm', '#modUserForm'], 'usersController', ['site_u']);
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
		});
		// Building meta tags list...
		$('head title').text('Utilisateurs - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Utilisateurs');
	},
	setUserPage: async function(userId) {
		if(userId==globals.id || globals.type=='TheKingOfInvoice' || globals.type=='manager') { // If not elevated user or own userId
			$('.profile-user-initials').html(globals.initials);
			if(globals.avatar != '') {
				$('.profile-user-img').attr('src', globals.serverBaseUrl+'docs/avatars/'+globals.avatar);
				$('#profileAvatarLink').attr('href', globals.serverBaseUrl+'docs/avatars/'+globals.avatar);
			}
			let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
				showCounter : true,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				closeText : 'X'
			});
			lightbox.refresh();
			const myFormDiv = '#modUserForm';
			App.clearFormFields(myFormDiv);
			const previousFormId = globals.currentFormId;
			globals.currentFormId = myFormDiv;
			if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
			const generateSelectBoxes = await this.getFormsSelectBoxes([myFormDiv], 'usersController', ['site_u']);
			if(globals.type=='TheKingOfInvoice' && globals.adminPass=='') await App.checkAccountStatus(); // When user's page is directly loaded adminPass may not already be set
			$.post(globals.serverAddress, {...globals.baseQuery, auto_u: userId, req: 'usersController', action: 'fillModUser'}, function(data){
				if(data.ok=="ok") {
					for (const [key, value] of Object.entries(data.users)) {
						$(myFormDiv+' [data-field="'+key+'"]').val(value);
						// $(myFormDiv+' #'+key).val(value);
						let target = $(myFormDiv+' #'+key);
						if(target.is('input[type=checkbox]') && value==1) $(myFormDiv+' #'+key).prop('checked', true); // Dealing with Checkboxes
					}
				}
				else if(data.administrator) {
					$(myFormDiv).closest('div').html('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette ressource !</div><hr><a href="/users/'+globals.id+'" class="btn btn-dark btn-lg btn-block shadow"><i class="fa fa-user-circle me-1"></i>Voir Mon Profil</a>');
				}
				else alert("Suite à un problème technique, je n'ai pas retrouvé cet utilisateur !");
				// If userId is different set the right avatar & initials...
				if(userId!=globals.id) {
					$('.profile-user-initials').html(data.users.init_u);
					$('#userPageTitle').text('Profil utilistateur de '+data.users.init_u);
					if(data.avatar_u != '') {
						$('.profile-user-img').attr('src', globals.serverBaseUrl+'docs/avatars/'+data.avatar_u);
						$('#profileAvatarLink').attr('href', globals.serverBaseUrl+'docs/avatars/'+data.avatar_u);
					}
					else {
						const randomPict = Math.floor(Math.random()*6);
						$('.profile-user-img').attr('src', globals.serverBaseUrl+'docs/avatars/profile-'+randomPict+'.png');
						$('#profileAvatarLink').attr('href', globals.serverBaseUrl+'docs/avatars/profile-'+randomPict+'.png');
					}
				}
				// Building meta tags list...
				$('head title').text(data.users.init_u+' - Utilisateur - CRM SecureBoat');
				$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Utilisateurs');
			}, "json").always(function(){
			});
			if(globals.type=='user') {
				$('.userReadOnly').attr('readonly', true);
				$('.userDisabled').attr('disabled', true);
			}
		}
		else {
			$("#userPageBody").html('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette ressource !</div>');
			$("#userPageBody").append('<hr><a href="/users/'+globals.id+'" class="btn btn-dark btn-lg btn-block shadow"><i class="fa fa-user-circle me-1"></i>Voir Mon Profil</a>');
		}
	},
	fillModUser: function(auto_u, thisBtn, myFormDiv, myFormModal) {
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		App.clearFormFields(myFormDiv);
		$.post(globals.serverAddress, {...globals.baseQuery, auto_u: auto_u, req: 'usersController', action: 'fillModUser'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.users)) {
					$(myFormDiv+' #'+key).val(value);
					// $(myFormDiv+' [data-field="'+key+'"]').val(value);
					let target = $(myFormDiv+' #'+key);
					if(target.is('input[type=checkbox]') && value==1) $(myFormDiv+' #'+key).prop('checked', true); // Dealing with Checkboxes
				}
			}
			else if(data.administrator) {
				$(myFormDiv).closest('div').html('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette ressource !</div>');
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé cet utilisateur !");
			// Activate or deativate buttons...
			if(data.users.active_u==0)
				$('[name=deleter]').removeClass('btn-danger').addClass('btn-success').attr('onclick', 'App.activateUser(\'#modUserForm\', event);').html('<i class="fa fa-thumbs-up me-1"></i>Activer');
			else $('[name=deleter]').removeClass('btn-success').addClass('btn-danger').attr('onclick', 'App.delUser(\'#modUserForm\', event);').html('<i class="fa fa-ban me-1"></i>Désactiver');
		}, "json").always(function(){
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
		});
		if(globals.type!='TheKingOfInvoice') $('.allUsersRemove').remove();
	},
	modUser: function(myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		// let query = $(myFormDiv).serialize();
		const req = "usersController";
		const action = "modUser";
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", req); // req is included in form data here
		request.append("action", action); // action is included in form data here
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("site", globals.site);
		request.append("ap", globals.adminPass);
		const auto_u = $(myFormDiv+' #auto_u').val();
		let returns = "";
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				if(myFormDiv=='#modUserForm') {
					returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Le profil a bien été mis à jour.</b></div>';
					App.setUsersListPage();
					setTimeout(function(){
						App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
						App.clearFormFields(myFormDiv);
					}, 2600);
				}
				if(auto_u==globals.id) { // User on his own profile
					returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Le profil a bien été mis à jour.<br>Attention: Vous allez devoir vous reconnecter !</b></div>';
					Swal.fire({
						title: 'Modification réussie',
						html: 'Le profil a bien été mis à jour.<br>Attention vous allez devoir vous reconnecter !',
						icon: 'success',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
						timer: 2600,
						timerProgressBar: true,
					})
						setTimeout(function(){
						App.logMeOut();
					}, 2600);
				}
				else if (auto_u!=globals.id) { // Power user on someone else's profile
					returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Le profil a bien été mis à jour.</b></div>';
				}
			}
			else
				returns = '<div class="alert alert-danger" role="alert"<i class="fa fa-times-circle me-1"></i>Le profil n\'a pas été modifié suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	addUser: function(myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		const req = "usersController";
		const action = "addUser";
		// let query = $(myFormDiv).serialize();
		// Serializing checkBoxes first for regular checkBoxes, second for Bootstrap's customs (assuming id=name)...
		// let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.name+"="+this.checked;}).get().join("&");
		// let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.id+"="+this.checked;}).get().join("&");
		// query = query + "&" + checkBoxes + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		// query = query + "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", req); // req is included in form data here
		request.append("action", action); // action is included in form data here
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("site", globals.site);
		request.append("ap", globals.adminPass);
		let returns = "";
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Le profil a bien été ajouté aux Utilisateurs.</b></div>';
				App.setUsersListPage();
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				setTimeout(function(){
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else if(data.error=="administrator") {
				returns = data.snippet;
			}
			else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Le profil n\'a pas été ajouté aux Utilisateurs, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	delUser: function(myFormDiv, event) {
		event.preventDefault(); // prevents mod form submission !
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir désactiver ce compte Utilisateur ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir désactiver ce compte Utilisateur ?",
			html: "Cette action est réversible...",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				$(myFormDiv+' [name=deleter]').attr("disabled", true);
				const delId = $(myFormDiv+' #auto_u').val();
				const req = "usersController";
				const action = "delUser";
				let query = "auto_u=" + delId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
				let returns = "";
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Ce compte Utilisateur a bien été désactivé.</b></div>';
						Swal.fire({
							title: 'Action réussie',
							html: returns,
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#1da1f5',
							timer: 2600,
							timerProgressBar: true,
						})
						App.setUsersListPage();
						setTimeout(function(){
							App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
							App.clearFormFields(myFormDiv);
						}, 2600);
					}
					else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Ce compte Utilisateur n\'a pas été désactivé suite à un problème technique.</b></div>';
				}, "json").always(function(data){
					$(myFormDiv+' [name=deleter]').attr("disabled", false);
					$(myFormDiv+' [data-successfail]').html(returns);
				});
			}
		});
	},
	activateUser: function(myFormDiv, event) {
		event.preventDefault(); // prevents mod form submission !
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir activer ce compte Utilisateur ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir désactiver ce compte Utilisateur ?",
			html: "Cette action est réversible...",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				$(myFormDiv+' [name=deleter]').attr("disabled", true);
				const activateId = $(myFormDiv+' #auto_u').val();
				const req = "usersController";
				const action = "activateUser";
				let query = "auto_u=" + activateId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
				let returns = "";
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Ce compte Utilisateur a bien été activé.</b></div>';
						Swal.fire({
							title: 'Action réussie',
							html: returns,
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#1da1f5',
							timer: 2600,
							timerProgressBar: true,
						})
						App.setUsersListPage();
						setTimeout(function(){
							App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
							App.clearFormFields(myFormDiv);
						}, 2600);
					}
					else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Ce compte Utilisateur n\'a pas été activé suite à un problème technique.</b></div>';
				}, "json").always(function(data){
					$(myFormDiv+' [name=deleter]').attr("disabled", false);
					$(myFormDiv+' [data-successfail]').html(returns);
				});
			}
		});
	},
	setOrganizationPage: function() {
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'usersController', action: 'getUsersOrg'}, function(data){
			let snippetOrg = '<div class="row">';
			let line = 1;
			let firstOne = 1;
			data.arrayOrg.forEach(function(user){
				if(globals.site!=0) {
					if(line==1 && firstOne==1) snippetOrg += '<h2>'+user.agency+'</h2>';
				}
				else { // All agencies...
					if(line==1 && firstOne==1) snippetOrg += '<h2>'+user.agency+'</h2>';
					if(line!=user.line) {
						snippetOrg += '<span class="mb-10 mt-4 line shadow-line">&nbsp;</span>';
						snippetOrg += '<h2>'+user.agency+'</h2>';
						line=user.line;
					}
				}
				snippetOrg += '<div class="col-auto d-grid text-center mb-4 mx-auto">';
				const imgLink = (user.avatar_u!='') ? globals.serverBaseUrl+'docs/avatars/'+user.avatar_u : globals.serverBaseUrl+'docs/avatars/profile-'+Math.floor(Math.random()*6)+'.png';
				snippetOrg += (globals.type=='TheKingOfInvoice' || globals.type=='manager') ? '<a href="/users/'+user.auto_u+'">' : '<a href="'+imgLink+'" data-simplelightbox="all">';
				snippetOrg += '<img class="org-user-img shadow-lg mb-2" src="'+imgLink+'" /></a>';
				snippetOrg += '<p class="lead"><a href="tel:'+user.ext+'">'+user.ext+'</a><br>'+user.name_u+'<br>'+user.job_u+'</p>';
				snippetOrg += '</div>';
				firstOne++;
			});
			snippetOrg += '</div>';
			$('#usersOrgCont').html(snippetOrg);
			let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
				showCounter : true,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				closeText : 'X'
			});
			lightbox.refresh();
		}, "json");
		// Building meta tags list...
		$('head title').text('Organisation - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Utilisateurs');
	},
	setClientsListPage: async function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#addClientForm', '#modClientForm'], 'clientsController', ['user_c', 'site_c']);
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, site: globals.site, pwd: globals.pwd, ap: globals.adminPass, req: 'clientsController', action: 'getClientsList'}, function(data){
			if(data.ok=='ok') {
				$("[data-table-view]").html(data.snippet);
				$("[data-kanban-view]").html(data.snippetPhone);
				App.buildDataFilterSearch();
			}
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("#clientListCont").html(noOne);
				$("#clientListMobileCont").html(noOne);
			}
		}, "json").done(function(data) {
			// 3CX Integration => https://crm.secureboat.fr:8443/clients/?phoneNumber=%CallerNumber%&displayName=%CallerDisplayName%
			const phoneNumber = App.urlParam('phoneNumber', document.URL);
			const displayName = App.urlParam('displayName', document.URL);
			// if(phoneNumber) $('#dataTableClientsList_filter').val(phoneNumber);
			if(phoneNumber) App.buildDataTables('#dataTableClientsList', 25, true, 0, 'asc', 'CRM SecureBoat Liste des Clients', [0,1,4,5,6,7], [2,3,8,9], 'Téléphones', 'contains', phoneNumber, [0,1,2,3,4,5,6,7,8,9,10], true);
			else {
				const tableFilter = App.urlParam('filter', document.URL);
				const tableFilterValue = App.urlParam('val', document.URL);
				if(tableFilter) App.buildDataTables('#dataTableClientsList', 25, true, 0, 'asc', 'CRM SecureBoat Liste des Clients', [0,1,4,5,6,7], [2,3,8,9], tableFilter, 'contains', tableFilterValue, [0,1,2,3,4,5,6,7,8,9], true);
				else App.buildDataTables('#dataTableClientsList', 25, true, 0, 'asc', 'CRM SecureBoat Liste des Clients', [0,1,4,5,6,7], [2,3,8,9], '', '', '', [0,1,2,3,4,5,6,7,8,9], true);
			}
		}).always(function(){
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
			// App.getFormsSelectBoxes(['#modClientForm', '#addClientForm'], 'clientsController', ['site_c']);
		});
		this.buildUploadZone()
		const date_start_c = document.querySelectorAll('#date_start_c');
		if(date_start_c.length>0) {
			date_start_c.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		// Building meta tags list...
		$('head title').text('Clients - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Clients');
	},
	setClientPage: async function(idClient) {
		const myFormDiv = '#modClientForm';
		if(globals.type!='TheKingOfInvoice') $('#deleterModClient').closest('.d-grid').addClass('d-none').next('.d-grid').removeClass('col-sm-8');
		let NomClient = '';
		$('[data-client-add-contact]').attr('href', '/contacts/?goto=addContactModal&fields=client_co&idFields='+idClient);
		$('[data-client-add-project]').attr('href', '/sales/projects/?goto=addProjectModal&fields=client_pj&idFields='+idClient);
		$('[data-client-add-quotation]').attr('href', '/sales/quotations/?goto=addQuotationModal&fields=client_o&idFields='+idClient);
		$('[data-client-add-invoice]').attr('href', '/sales/invoices/?goto=addInvoiceModal&fields=client_o&idFields='+idClient);
		$('[data-client-add-buy-invoice]').attr('href', '/buy/invoices/?goto=addBuyInvoiceModal&fields=client_bi&idFields='+idClient);
		$('#uploadsClientForm #idClient').val(idClient);
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#addClientForm', '#modClientForm'], 'clientsController', ['user_c', 'site_c']);
		App.clearFormFields(myFormDiv);
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, idClient: idClient, req: 'clientsController', action: 'fillModClient'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.clients)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
					// $(myFormDiv+' #'+key).val(value);
					let target = $(myFormDiv+' #'+key);
					if(target.is('input[type=checkbox]') && value==1) $(myFormDiv+' #'+key).prop('checked', true); // Dealing with Checkboxes
				}
				NomClient = data.clients.name_c;
				$('#headerClientInfos').text(NomClient);
				$('#getClientContactsListBtn').attr('onclick', `App.getClientContactsList('${data.clients.auto_c}','${data.clients.id_c}',this);`);
				const printClientBtn = '<button class="btn btn-dark btn-icon shadow ms-2 float-end" id="printClientBtn" title="Fichier PDF - Impression" onClick="App.printClient(\''+data.clients.auto_c+'\', this);"><i class="fa fa-2x fa-print"></i></button>';
				$('#modClientTitle').html('#'+data.clients.id_c+printClientBtn);
				App.displayRangeValue(document.querySelector(myFormDiv+' [data-field="discount_c"]'), ' %') // Allows to show discount values badges in modForms
				// Set Click2Call & Click2Mail Buttons...
				$(myFormDiv+' #NumTelContactClientLink').attr('href', 'tel:'+data.clients.phone_c);
				$(myFormDiv+' #EmailContactClientLink').attr('href', 'mailto:'+data.clients.mails_c);
				switch(data.clients.type_c) {
					case 'Client':
						$('[data-client-add-project]').removeClass('d-none');
						$('[data-client-add-quotation]').removeClass('d-none');
						$('[data-client-add-invoice]').removeClass('d-none');
					break;
					case 'Fournisseur':
						$('[data-client-add-buy-invoice]').removeClass('d-none');
					break;
					case 'Prospect':
						$('[data-client-add-project]').removeClass('d-none');
						$('[data-client-add-quotation]').removeClass('d-none');
					break;
					case 'Mixte':
						$('[data-client-add-buy-invoice]').removeClass('d-none');
						$('[data-client-add-project]').removeClass('d-none');
						$('[data-client-add-quotation]').removeClass('d-none');
						$('[data-client-add-invoice]').removeClass('d-none');
					break;
				}
				if(data.clients.active_c==0) {
					alert("ATTENTION CE CLIENT A ÉTÉ SUPPRIMÉ !");
					$(myFormDiv+' [type="submit"]').attr("disabled", true);
				}
				if(data.clients.picture_c != '') {
					$('.page-header-subtitle').html(`<a href="${globals.serverBaseUrl}docs/logos/${data.clients.picture_c}" id="clientLogoLink" data-simplelightbox="all">
						<img class="profile-clients-img rounded-xl shadow-lg" src="${globals.serverBaseUrl}docs/logos/${data.clients.picture_c}" />
					</a>`);
					let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
						showCounter : true,
						history : false,
						captionType : 'data',
						captionsData : 'caption',
						closeText : 'X'
					});
					lightbox.refresh();
				}
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
				// Building meta tags list...
				$('head title').text(NomClient+' - Client - CRM SecureBoat');
				$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Client');
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce client !");
			// else $("#clientPageBody").html('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette ressource !</div>');
		}, "json").always(function(){
			/*
			if(NomClient!='') {
				// Preload Interventions & Uploads lists => If you don't want to do this comment next two lines out and uncomment last 2 lines, you may also comment same lines in each function
				App.getClientsInterventionsList(idClient, NomClient);
				App.getClientsUploadsList(idClient, NomClient);
				$('#interventions-tab').attr('onclick', 'App.getClientsInterventionsList('+idClient+', \''+NomClient+'\')');
				$('#uploads-tab').attr('onclick', 'App.getClientsInterventionsList('+idClient+', \''+NomClient+'\')');
			}
			*/
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		const date_start_c = document.querySelectorAll('[data-field="date_start_c"]');
		if(date_start_c.length>0) {
			date_start_c.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
	},
	fillModClient: function(idClient, thisBtn, myFormDiv, myFormModal) {
		App.clearFormFields(myFormDiv);
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		if(globals.type!='TheKingOfInvoice') $('#deleterModClient').closest('.d-grid').addClass('d-none').next('.d-grid').removeClass('col-sm-8');
		// if(globals.type=='TheKingOfInvoice') $('#deleteClientBtn').attr('onclick', 'App.delClient('+idClient+', this, true);');
		// else $('#deleteClientBtn').remove();
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		let NomClient = '';
		$.post(globals.serverAddress, {...globals.baseQuery, idClient: idClient, req: 'clientsController', action: 'fillModClient'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.clients)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
					// $(myFormDiv+' #'+key).val(value);
					let target = $(myFormDiv+' #'+key);
					if(target.is('input[type=checkbox]') && value==1) $(myFormDiv+' #'+key).prop('checked', true); // Dealing with Checkboxes
				}
				$('#modClientTitle').text(data.clients.name_c);
				$('#getClientContactsListBtn').attr('onclick', `App.getClientContactsList('${data.clients.auto_c}','${data.clients.id_c}',this);`);
				App.displayRangeValue(document.querySelector(myFormDiv+' [data-field="discount_c"]'), ' %') // Allows to show discount values badges in modForms
				// Set Click2Call & Click2Mail Buttons...
				$(myFormDiv+' #NumTelContactClientLink').attr('href', 'tel:'+data.clients.phone_c);
				$(myFormDiv+' #EmailContactClientLink').attr('href', 'mailto:'+data.clients.mails_c);
				if(data.clients.activeClient==0) alert("ATTENTION CE CLIENT A ÉTÉ SUPPRIMÉ !");
				if(data.clients.picture_c != '') {
					$('#clientLogoImg').attr('src', globals.serverBaseUrl+'docs/logos/'+data.clients.picture_c);
					$('#clientLogoLink').attr('href', globals.serverBaseUrl+'docs/logos/'+data.clients.picture_c);
				}
				else {
					const randomPict = Math.floor(Math.random()*6);
					$('#clientLogoImg').attr('src', globals.serverBaseUrl+'docs/avatars/profile-'+randomPict+'.png');
					$('#clientLogoLink').attr('href', globals.serverBaseUrl+'docs/avatars/profile-'+randomPict+'.png');
				}
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
				else {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.remove('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = '';
				}
				$('[data-client-add-contact]').attr('href', '/contacts/?goto=addContactModal&fields=client_co&idFields='+idClient);
				$('[data-client-add]').addClass('d-none')
				switch(data.clients.type_c) {
					case 'Client':
						$('[data-client-add-project]').attr('href', '/sales/projects/?goto=addProjectModal&fields=client_pj&idFields='+idClient).removeClass('d-none');
						$('[data-client-add-quotation]').attr('href', '/sales/quotations/?goto=addQuotationModal&fields=client_o&idFields='+idClient).removeClass('d-none');
						$('[data-client-add-invoice]').attr('href', '/sales/invoices/?goto=addInvoiceModal&fields=client_o&idFields='+idClient).removeClass('d-none');
					break;
					case 'Fournisseur':
						$('[data-client-add-buy-invoice]').attr('href', '/buy/invoices/?goto=addBuyInvoiceModal&fields=client_bi&idFields='+idClient).removeClass('d-none');
					break;
					case 'Prospect':
						$('[data-client-add-project]').attr('href', '/sales/projects/?goto=addProjectModal&fields=client_pj&idFields='+idClient).removeClass('d-none');
						$('[data-client-add-quotation]').attr('href', '/sales/quotations/?goto=addQuotationModal&fields=client_o&idFields='+idClient).removeClass('d-none');
					break;
					case 'Mixte':
						$('[data-client-add-buy-invoice]').attr('href', '/buy/invoices/?goto=addBuyInvoiceModal&fields=client_bi&idFields='+idClient).removeClass('d-none');
						$('[data-client-add-project]').attr('href', '/sales/projects/?goto=addProjectModal&fields=client_pj&idFields='+idClient).removeClass('d-none');
						$('[data-client-add-quotation]').attr('href', '/sales/quotations/?goto=addQuotationModal&fields=client_o&idFields='+idClient).removeClass('d-none');
						$('[data-client-add-invoice]').attr('href', '/sales/invoices/?goto=addInvoiceModal&fields=client_o&idFields='+idClient).removeClass('d-none');
					break;
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce client !");
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
			let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
				showCounter : true,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				closeText : 'X'
			});
			lightbox.refresh();
		});
	},
	getClientsInterventionsList: function(idClient, NomClient) {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, site: globals.site, pwd: globals.pwd, ap: globals.adminPass, idClient: idClient, req: 'clientsController', action: 'getClientsInterventionsList'}, function(data){
			$("#clientsInterventionsListCont").html(data.snippet);
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableClientsInterventionsList', 25, true, 0, 'desc', 'CRM SecureBoat Liste des interventions pour '+NomClient, [0,1,3,4], [2,5,6,7], '', '', '', [0,1,2,3,4,5,6,7], true);
		});
		$('#interventions-tab').attr('onclick', 'App.getClientsInterventionsList('+idClient+', \''+NomClient+'\')');
	},
	getClientsUploadsList: function(idClient, NomClient) {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, site: globals.site, pwd: globals.pwd, ap: globals.adminPass, idClient: idClient, req: 'clientsController', action: 'getClientsUploadsList'}, function(data){
			$("#clientsUploadsListCont").html(data.snippet);
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableClientsUploadsList', 25, true, '', 'desc', 'CRM SecureBoat Liste des documents pour '+NomClient, [0,2,3,4], [1], '', '', '', [0,1,2,3,4], true);
		});
		$('#uploads-tab').attr('onclick', 'App.getClientsUploadsList('+idClient+', \''+NomClient+'\')');
	},
	modClient: function(myFormDiv, fromModal = false) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let returns = '';
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "clientsController");
		request.append("action", "modClient");
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("site", globals.site);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Le tiers a bien été mis à jour.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				if(fromModal) {
					App.setClientsListPage();
					setTimeout(function(){
						App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
						App.clearFormFields(myFormDiv);
					}, 2600);
				}
			}
			else if(data.exist) returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Le tiers n\'a pas été modifié car son identifiant n\'est pas unique.</b></div>';
			else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Le tiers n\'a pas été modifié suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	addClient: function(myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let returns = '';
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "clientsController");
		request.append("action", "addClient");
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("site", globals.site);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Le client a bien été ajouté.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				switch(sessionStorage.getItem('myPage')) {
					case 'clients':
						App.setClientsListPage();
					break;
					case 'buy/invoices':
						App.getFormsSelectBoxes(['#addBuyInvoiceForm', '#modBuyInvoiceForm'], 'buyInvoicesController', ['client_bi']);
					break;
					case 'sales/projects':
						App.getFormsSelectBoxes(['#addProjectForm', '#modProjectForm'], 'projectsController', ['client_pj']);
					break;
					case 'sales/quotations':
						App.getFormsSelectBoxes(['#addQuotationForm', '#modQuotationForm'], 'ordersController', ['client_o']);
					break;
					case 'sales/invoices':
						App.getFormsSelectBoxes(['#addInvoiceForm', '#modInvoiceForm'], 'ordersController', ['client_o']);
					break;
					default:
						App.setClientsListPage();
				}
				setTimeout(function(){
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else if(data.exist) returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Le client n\'a pas été ajoutécar son identifiant n\'est pas unique.</b></div>';
			else if(data.error=='administrator') returns = data.snippet;
			else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Le client n\'a pas été ajouté, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	delClient: function(myFormDiv, thisBtn, event, fromModal = false) {
		event.preventDefault()
		const auto_c = $(myFormDiv+' #auto_c').val()
		const id_c = $(myFormDiv+' #id_c').val()
		$.post(globals.serverAddress, {...globals.baseQuery, auto_c: auto_c, req: 'clientsController', action: 'delClientCheck'}, function(data){
			const confirmText = (data.deletions>0) ? data.snippet+"<br>" : "";
			// const confirmDeletion = confirm(confirmText);
			Swal.fire({
				title: "Êtes-vous certain de vouloir supprimer le client "+id_c+" ?",
				html: confirmText+"Cette action n'est réversible que sur intervention de SNS Solutions.",
				icon: "warning",
				confirmButtonText: "J'en suis sûr !",
				confirmButtonColor: '#1da1f5',
				showDenyButton: true,
				denyButtonText: "Peut-être pas..."
			}).then(response => {
				if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
					const thisBtnHtml = $(thisBtn).html();
					$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
					let query = "auto_c=" + auto_c + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=clientsController&action=delClient";
					$.post(globals.serverAddress, query, function(data){
						if(data.ok=="ok") {
							alert("Le client a bien été supprimé.");
							if(fromModal) {
								App.setClientsListPage();
								$(thisBtn).closest('.modal').find('.btn-close').trigger('click');
							}
							else document.location.href='/clients';
						}
						else alert("Le client n'a pas été supprimé suite à un problème technique.");
					}, "json").always(function(data){
						$(thisBtn).attr("disabled", false).html(thisBtnHtml);
					});
				}
			});
		}, "json").always(function(data){
		});
	},
	delClientFromBtn: function(auto_c, id_c, thisBtn) {
		$.post(globals.serverAddress, {...globals.baseQuery, auto_c: auto_c, req: 'clientsController', action: 'delClientCheck'}, function(data){
			const confirmText = (data.deletions>0) ? data.snippet+"<br>" : "";
			Swal.fire({
				title: "Êtes-vous certain de vouloir supprimer le client "+id_c+" ?",
				html: confirmText+"Cette action n'est réversible que sur intervention de SNS Solutions.",
				icon: "warning",
				confirmButtonText: "J'en suis sûr !",
				confirmButtonColor: '#1da1f5',
				showDenyButton: true,
				denyButtonText: "Peut-être pas..."
			}).then(response => {
				if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
					const thisBtnHtml = $(thisBtn).html()
					$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>');
					const req = "clientsController";
					const action = "delClient";
					let query = "auto_c=" + auto_c + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
					$.post(globals.serverAddress, query, function(data){
						if(data.ok=="ok") {
							App.setClientsListPage();
						}
					}, "json").always(function(data){
						$(thisBtn).attr("disabled", false).html(thisBtnHtml);
					});
				}
			});
		}, "json").always(function(data){
		});
	},
	checkClientId: function(input) {
		const id_c = $(input).val();
		const myFormDiv = $(input).closest('form').attr('id');
		const auto_c = $('#'+myFormDiv+' #auto_c').val();
		const req = "clientsController";
		const action = "checkClientId";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&auto_c=" + auto_c + "&id_c=" + id_c + "&req=" + req + "&action=" + action;
		$.post(globals.serverAddress, query, function(data){
			if(data.ok == 'ko') {
				alert("Cet Identifiant est déjà attribué à un autre client !");
				// input.setCustomValidity("Cet Identifiant est déjà attribué à un autre client !");
				input.classList.add("is-invalid");
			}
			else input.classList.remove("is-invalid");
		}, "json").always(function(){
		});
	},
	contactsPhoneSearch: function() {
		// 3CX Integration => https://crm.secureboat.fr:8443/phone/?phoneNumber=%CallerNumber%&displayName=%CallerDisplayName% | localhost:8080/phone/?phoneNumber=0491030435
		const phoneNumber = App.urlParam('phoneNumber', document.URL);
		const displayName = App.urlParam('displayName', document.URL);
		if(phoneNumber.length>5) {
			const request = new FormData();
			request.append("req", "clientsController"); // req is included in form data here
			request.append("action", "phoneSearch");
			request.append("id", App.settings.id);
			request.append("type", App.settings.type);
			request.append("pwd", App.settings.pwd);
			request.append('phoneNumber', phoneNumber);
			request.append('displayName', displayName);
			fetch(App.settings.serverAddress, {
				method: 'post',
				body: request
			})
			.then(res => {
				return res.json();
			})
			.then(function (data) {
				if(data.ok=='ok') {
					if(data.multiple) document.location.href = `${globals.serverAddress}contacts/?phoneNumber=${phoneNumber}&displayName=${displayName}`;
					else document.location.href = `${globals.serverAddress}contacts/${data.idContact}`;
				}
				else {
					alert(`Aucun contact trouvé pour le ${phoneNumber} (${displayName}) !`);
					document.location.href = `${globals.serverAddress}contacts/`;
				}
			}).catch(function (err) {
				console.warn('Something went wrong.', err);
			});
		}
		else {
			console.warn(`Appel interne => ${phoneNumber} (${displayName}) !`);
			// document.location.href = globals.serverBaseUrl;
		}
	},
	getClientContactsList: function(auto_c, id_c, thisBtn) {
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>');
		$.post(globals.serverAddress, {...globals.baseQuery, auto_c: auto_c, req: 'clientsController', action: 'getClientContactsList'}, function(data){
			if(data.ok=='ok') $("#clientContactsListCont").html(data.snippet);
			else $("#clientContactsListCont").html('<div class="alert alert-secondary h2 text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Ce client #'+id_c+' n\'a pas de Contact assigné !</div>');
		}, "json").done(function(data) {
			if(data.ok=='ok') App.buildDataTables('#dataTableClientsContactsList', 25, true, 3, 'asc', 'Liste des Contacts', [1,2,4,5,6], [0,3,7,8,9], '', '', '', [0,1,2,3,4,5,6,7,8,9], true);
		}).always(function(data) {
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			$('#getClientContactsListModal .modal-title').html('<i class="fa fa-user-friends"></i> Liste des Contacts du Client #'+id_c);
			$('[data-add-contact-from-list]').attr('href', '/contacts/?goto=addContactModal&fields=client_co&idFields='+auto_c);
			let myModal = new bootstrap.Modal(document.getElementById('getClientContactsListModal'));
			myModal.show();
		});
	},
	getClientProjectsList: function(auto_c, id_c, thisBtn) {
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>');
		$.post(globals.serverAddress, {...globals.baseQuery, auto_c: auto_c, req: 'clientsController', action: 'getClientProjectsList'}, function(data){
			if(data.ok=='ok') $("#clientProjectsListCont").html(data.snippet);
			else $("#clientProjectsListCont").html('<div class="alert alert-secondary h2 text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Ce client #'+id_c+' n\'a pas de Projet associé !</div>');
		}, "json").done(function(data) {
			if(data.ok=='ok') App.buildDataTables('#dataTableClientsProjectsList', 25, true, 0, 'desc', 'Liste des Projets', [0,1,3,4,5], [2,6,7], '', '', '', [0,1,2,3,4,5,6,7], true);
		}).always(function(data){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			$('#getClientProjectsListModal .modal-title').html('<i class="fa fa-project-diagram"></i> Liste des Projets du Client #'+id_c);
			$('#getClientProjectsListModal .modal-footer').html('<button class="btn btn-dark btn-block mt-2" onclick="App.linkClientsProjectsList(\''+auto_c+'\')"><i class="fa fa-project-diagram me-1"></i>Associer un projet</button>');
			let myModal = new bootstrap.Modal(document.getElementById('getClientProjectsListModal'));
			myModal.show();
		});
	},
	linkClientsProjectsList: function(auto_c) {
		// If the modal #getClientProjectsListModal is opened we close it
		if($('#getClientProjectsListModal').is(':visible')) $('#getClientProjectsListModal').find('.btn-close').trigger('click');
		const id_c = Number(auto_c)+1000;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_c: auto_c, req: 'clientsController', action: 'linkClientsProjectsList'}, function(data){
			$("#linkClientsProjectsListCont").html(data.snippet);
		}, "json").done(function(data) {
			if(data.ok=='ok') App.buildDataTables('#dataTableLinkClientsProjectsList', 25, true, 3, 'asc', 'Liste des Projets', [0,1,3,4,5], [2,6,7], '', '', '', [0,1,2,3,4,5,6,7], true);
		}).always(function(data){
			$('#linkClientsProjectsModal .modal-title').html('<i class="fa fa-project-diagram"></i> Associer des projets au Client #'+id_c);
			if(!$('#linkClientsProjectsModal').is(':visible')) {
				let myModal = new bootstrap.Modal(document.getElementById('linkClientsProjectsModal'));
				myModal.show();
			}
		});
	},
	linkClientsProjects: function(auto_pj, auto_c, thisBtn) {
		$(thisBtn).attr("disabled", true);
		const req = "clientsController";
		const action = "linkClientsProjects";
		let query = "auto_c=" + auto_c + "&auto_pj=" + auto_pj + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				alert('Le client a bien été associé au projet.');
				App.linkClientsProjectsList(auto_c);
			}
			else
				alert('Le client n\'a pas été associé au projet suite à un problème technique !');
		}, "json").always(function(data){
			$(thisBtn).attr("disabled", false);
		});
	},
	printClient: function(auto_c, thisBtn) {
		const thisBtnHtml = $(thisBtn).html()
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const req = "clientsController";
		const action = "printClient";
		let query = "auto_c=" + auto_c + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action;
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				const a = $("<a>").attr("href", data.pdflink).attr("download", data.pdfname).attr("target", "_blank").appendTo("footer");
				a[0].click();
				a.remove();
				// const link = document.createElement('a');
				// link.href = data.pdflink;
				// link.setAttribute('download', data.pdfname);
				// document.body.appendChild(link);
				// link.click();
			}
			else alert('Suite à un problème technique le fichier n\'a pas été créé !');
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
		});
	},
	setContactsListPage: async function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#addContactForm', '#modContactForm'], 'clientsController', ['client_co']);
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'clientsController', action: 'getContactsList'}, function(data){
			if(data.ok=='ok') {
				$("[data-table-view]").html(data.snippet);
				$("[data-kanban-view]").html(data.snippetPhone);
				App.buildDataFilterSearch();
			}
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("[data-table-view]").html(noOne);
				$("[data-kanban-view]").html(noOne);
			}
		}, "json").done(function(data) {
			// 3CX Integration => https://crm.secureboat.fr:8443/clients/?phoneNumber=%CallerNumber%&displayName=%CallerDisplayName%
			const phoneNumber = App.urlParam('phoneNumber', document.URL);
			const displayName = App.urlParam('displayName', document.URL);
			// if(phoneNumber) $('#dataTableClientsList_filter').val(phoneNumber);
			if(phoneNumber) App.buildDataTables('#dataTableContactsList', 25, true, 0, 'asc', 'Liste des Contacts', [0,4,5,6,7,8], [1,2,3,9,10,11], 'Téléphones', 'contains', phoneNumber, [0,1,2,3,4,5,6,7,8,9,10,11], true);
			else App.buildDataTables('#dataTableContactsList', 25, true, 0, 'asc', 'Liste des Contacts', [0,4,5,6,7,8], [1,2,3,9,10,11], '', '', '', [0,1,2,3,4,5,6,7,8,9,10,11], true);
		}).always(function(){
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		// else $("#clientListCont").html('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette liste !</div>');
		// Building meta tags list...
		$('head title').text('Contacts - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Contacts');
	},
	setContactPage: function(contactId) {
		// First We get client's select box then we fill form with values
		const myFormDiv = '#modContactForm';
		App.clearFormFields(myFormDiv);
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		const req = "clientsController";
		const action = "getFormsSelectBoxes";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action;
		$.post(globals.serverAddress, query, function(data){
			$("#modContactForm #client_co").html(data.snippet);
		}, "json").always(function(){
			$.post(globals.serverAddress, {...globals.baseQuery, auto_co: contactId, req: 'clientsController', action: 'fillModContact'}, function(data2){
				if(data2.ok=="ok") {
					for (const [key, value] of Object.entries(data2.contacts)) {
						$(myFormDiv+' [data-field="'+key+'"]').val(value);
					}
					document.querySelector('a[data-clients-link]').setAttribute('href', '/clients/'+data2.contacts.client_co)
					// Set Click2Call & Click2Mail Buttons...
					$(myFormDiv+' #NumTelContactLink').attr('href', 'tel:'+data2.contacts.phone_co);
					$(myFormDiv+' #NumTel2ContactLink').attr('href', 'tel:'+data2.contacts.phone2_co);
					$(myFormDiv+' #EmailContactLink').attr('href', 'mailto:'+data2.contacts.mails_co);
					if(data2.contacts.picture_co != '') {
						$('.page-header-subtitle').html(`<a href="${globals.serverBaseUrl}docs/contacts/${data2.contacts.picture_co}" id="contactLogoLink" data-simplelightbox="all">
							<img class="profile-clients-img rounded-xl shadow-lg" src="${globals.serverBaseUrl}docs/contacts/${data2.contacts.picture_co}" />
						</a>`);
						let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
							showCounter : true,
							history : false,
							captionType : 'data',
							captionsData : 'caption',
							closeText : 'X'
						});
						lightbox.refresh();
					}
					// Handling Attachments...
					if(data2.snippetUploads!='') {
						const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
						fileListCont.classList.add('alert', 'alert-primary', 'my-1')
						fileListCont.innerHTML = `<ul class="mb-0">${data2.snippetUploads}</ul>`;
					}
				}
				else alert("Suite à un problème technique, je n'ai pas retrouvé ce contact !");
			}, "json");
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		// Building meta tags list...
		$('head title').text('Contacts - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Contacts');
	},
	fillModContact: function(auto_co, thisBtn, myFormDiv, myFormModal) {
		App.clearFormFields(myFormDiv);
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_co: auto_co, req: 'clientsController', action: 'fillModContact'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.contacts)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				document.querySelector('a[data-clients-link]').setAttribute('href', '/clients/'+data.contacts.client_co)
				// Set Click2Call & Click2Mail Buttons...
				$(myFormDiv+' #NumTelContactLink').attr('href', 'tel:'+data.contacts.phone_co);
				$(myFormDiv+' #NumTel2ContactLink').attr('href', 'tel:'+data.contacts.phone2_co);
				$(myFormDiv+' #EmailContactLink').attr('href', 'mailto:'+data.contacts.mails_co);
				if(data.contacts.picture_co != '') {
					$('#contactLogoImg').attr('src', globals.serverBaseUrl+'docs/contacts/'+data.contacts.picture_co);
					$('#contactLogoLink').attr('href', globals.serverBaseUrl+'docs/contacts/'+data.contacts.picture_co);
				}
				else {
					const randomPict = Math.floor(Math.random()*6);
					$('#contactLogoImg').attr('src', globals.serverBaseUrl+'docs/avatars/profile-'+randomPict+'.png');
					$('#contactLogoLink').attr('href', globals.serverBaseUrl+'docs/avatars/profile-'+randomPict+'.png');
				}
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
				else {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.remove('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = '';
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce contact !");
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
			let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
				showCounter : true,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				closeText : 'X'
			});
			lightbox.refresh();
		});
	},
	modContact: function(myFormDiv, fromModal = false) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let returns;
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "clientsController");
		request.append("action", "modContact");
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("site", globals.site);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Le contact a bien été mis à jour.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				if(fromModal) {
					App.setContactsListPage();
					setTimeout(function(){
						App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
						App.clearFormFields(myFormDiv);
					}, 2600);
				}
			}
			else returns = '<div class="alert alert-danger" role="alert"><b>Le contact n\'a pas été modifié suite à un problème technique.</b></div>';
		}, "json").always(function(){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	addContact: function(myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let returns;
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "clientsController");
		request.append("action", "addContact");
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("site", globals.site);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Le profil a bien été ajouté aux contacts.</b></div>';
				App.setContactsListPage();
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				setTimeout(function(){
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else returns = '<div class="alert alert-danger" role="alert"><b>Le profil n\'a pas été ajouté aux contacts, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	delContact: function(myFormDiv, event, fromModal = false) {
		event.preventDefault(); // prevents mod form submission !
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce contact ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir supprimer ce contact ?",
			html: "Cette action n'est réversible que sur intervention de SNS Solutions.",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				$(myFormDiv+' [name=deleter]').attr("disabled", true);
				const delId = $(myFormDiv+' #auto_co').val();
				const req = "clientsController";
				const action = "delContact";
				let query = "auto_co=" + delId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
				let returns = "";
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						returns = '<div class="alert alert-success" role="alert"><b>Ce Contact a bien été supprimé.</b></div>';
						Swal.fire({
							title: 'Action réussie',
							html: returns,
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#1da1f5',
							timer: 2600,
							timerProgressBar: true,
						})
						if(fromModal) {
							App.setContactsListPage();
							setTimeout(function(){
								App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
								App.clearFormFields(myFormDiv);
							}, 2600);
						}
						else {
							setTimeout(function(){
								document.location.href = '/contacts/';
							}, 2600);
						}
					}
					else returns = '<div class="alert alert-danger" role="alert"><b>Ce Contact n\'a pas été supprimé suite à un problème technique.</b></div>';
				}, "json").always(function(data){
					$(myFormDiv+' [name=deleter]').attr("disabled", false);
					$(myFormDiv+' [data-successfail]').html(returns);
				});
			}
		});
	},
	delContactFromBtn: function(auto_co, id_c, thisBtn) {
		Swal.fire({
			title: "Êtes-vous certain de vouloir supprimer ce contact (Client "+id_c+") ?",
			html: "Cette action n'est réversible que sur intervention de SNS Solutions.",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const thisBtnHtml = $(thisBtn).html()
				$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>');
				const req = "clientsController";
				const action = "delContact";
				let query = "auto_co=" + auto_co + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						App.setContactsListPage();
					}
				}, "json").always(function(data){
					$(thisBtn).attr("disabled", false).html(thisBtnHtml);
				});
			}
		});
	},
	setProjectsListPage: async function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#addProjectForm', '#modProjectForm'], 'projectsController', ['client_pj', 'user_pj', 'site_pj']);
		$('#addProjectForm [data-field=site_pj]').val(globals.site).attr('data-default', globals.site)
		$('#addProjectForm [data-field=user_pj]').val(globals.id).attr('data-default', globals.id)
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'projectsController', action: 'getProjectsList'}, function(data){
			if(data.ok=='ok') {
				$("[data-table-view]").html(data.snippet);
				$("[data-kanban-view]").html(data.snippetPhone);
				App.buildDataFilterSearch();
			}
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("[data-table-view]").html(noOne);
				$("[data-kanban-view]").html(noOne);
			}
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableProjectsList', 25, true, 0, 'desc', 'Liste des Projets', [0,1,3,4,5], [2,6,7,8], '', '', '', [0,1,2,3,4,5,6,7,8], true);
		}).always(function() {
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		const date_start_pj = document.querySelectorAll('[data-field="date_start_pj"]');
		if(date_start_pj.length>0) {
			date_start_pj.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					elementEnd: thisItem.closest('.row').querySelector('[data-field="date_end_pj"]'),
					lang: 'fr-FR',
					format: 'DD/MM/YYYY',
					singleMode: false,
					numberOfMonths: 2,
					numberOfColumns: 2,
					tooltipText: {
						one: 'jour',
						other: 'jours'
					},
					tooltipNumber: (totalDays) => {
						return totalDays;
					},
				});
			});
		}
		const date_end_pj = document.querySelectorAll('[data-field="date_end_pj"]');
		if(date_end_pj.length>0) {
			date_end_pj.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY',
				});
			});
		}
		// else $("#clientListCont").html('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette liste !</div>');
		this.popFormFromUrlParam();
		// Building meta tags list...
		$('head title').text('Projets - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Projets');
	},
	setProjectPage: async function(projectId) {
		// First We get client's select box then we fill form with values
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#modProjectForm'], 'projectsController', ['client_pj', 'user_pj', 'site_pj']);
		const myFormDiv = '#modProjectForm';
		App.clearFormFields(myFormDiv);
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_pj: projectId, req: 'projectsController', action: 'fillModProject'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.projects)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				document.querySelector('a[data-clients-link]').setAttribute('href', '/clients/'+data.projects.client_pj)
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce projet !");
		}, "json").always(() => {
			document.querySelector('button[data-projects-related]').setAttribute('onclick', 'App.getProjectsRelatedList('+projectId+', this)')
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		const date_start_pj = document.querySelector(myFormDiv+' [data-field="date_start_pj"]');
		const date_end_pj = document.querySelector(myFormDiv+' [data-field="date_end_pj"]');
		if(date_start_pj && date_end_pj) {
			new Litepicker({
				element: date_start_pj,
				elementEnd: date_end_pj,
				lang: 'fr-FR',
				format: 'DD/MM/YYYY',
				singleMode: false,
				numberOfMonths: 2,
				numberOfColumns: 2,
				tooltipText: {
					one: 'jour',
					other: 'jours'
				},
				tooltipNumber: (totalDays) => {
					return totalDays;
				}
			});
		}
		if(date_end_pj) {
			new Litepicker({
				element: date_end_pj,
				lang: 'fr-FR',
				format: 'DD/MM/YYYY'
			});
		}
		// Building meta tags list...
		$('head title').text('Projets - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Projets');
	},
	fillModProject: function(auto_pj, thisBtn, myFormDiv, myFormModal) {
		App.clearFormFields(myFormDiv);
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_pj: auto_pj, req: 'projectsController', action: 'fillModProject'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.projects)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				document.querySelector(myFormDiv+' a[data-clients-link]').setAttribute('href', '/clients/'+data.projects.client_pj)
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
				else {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.remove('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = '';
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce projet !");
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			document.querySelector('button[data-projects-related]').setAttribute('onclick', 'App.getProjectsRelatedList('+auto_pj+', this)')
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
			let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
				showCounter : true,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				closeText : 'X'
			});
			lightbox.refresh();
		});
	},
	modProject: function(myFormDiv, fromModal = false, popQuotation = false) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		const client_pj = document.querySelector(myFormDiv+' [data-field="client_pj"]').value;
		let returns;
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "projectsController");
		request.append("action", "modProject");
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("site", globals.site);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Le projet a bien été mis à jour.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				if(fromModal) {
					App.setProjectsListPage();
					setTimeout(function(){
						App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
						App.clearFormFields(myFormDiv);
					}, 2600);
				}
				if(popQuotation) window.location.href = `/sales/quotations?goto=addQuotationModal&fields=client_o,project_o&idFields=${client_pj},${data.auto_pj}`
			}
			else returns = '<div class="alert alert-danger" role="alert"><b>Le projet n\'a pas été modifié suite à un problème technique.</b></div>';
		}, "json").always(function(){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	addProject: function(myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let returns;
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "projectsController");
		request.append("action", "addProject");
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("site", globals.site);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Le projet a bien été créé.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				// In case We come from popFormFromUrlParam We have to clean URL before refreshing
				const goTo = App.urlParam('goto', document.URL);
				if(goTo) window.history.pushState({}, document.title, window.location.href.split("?")[0]);
				App.setProjectsListPage();
				setTimeout(function(){
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else returns = '<div class="alert alert-danger" role="alert"><b>Le projet n\'a pas été créé suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	delProject: function(myFormDiv, event, fromModal = false) {
		event.preventDefault(); // prevents mod form submission !
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce projet ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir supprimer ce projet ?",
			html: "Cette action n'est réversible que sur intervention de SNS Solutions.",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const thisBtnHtml = $(myFormDiv+' [name=deleter]').html();
				$(myFormDiv+' [name=deleter]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i> Chargement');
				const delId = $(myFormDiv+' #auto_pj').val();
				let query = "auto_pj=" + delId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=projectsController&action=delProject";
				let returns = "";
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						returns = '<div class="alert alert-success" role="alert"><b>Ce Projet a bien été supprimé.</b></div>';
						Swal.fire({
							title: 'Action réussie',
							html: returns,
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#1da1f5',
							timer: 2600,
							timerProgressBar: true,
						})
						if(fromModal) {
							App.setProjectsListPage();
							setTimeout(function(){
								App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
								App.clearFormFields(myFormDiv);
							}, 2600);
						}
						else {
							setTimeout(function(){
								document.location.href = '/sales/projects/';
							}, 2600);
						}
					}
					else returns = '<div class="alert alert-danger" role="alert"><b>Ce Projet n\'a pas été supprimé suite à un problème technique.</b></div>';
				}, "json").always(function(data){
					$(myFormDiv+' [name=deleter]').attr("disabled", false).html(thisBtnHtml);
					$(myFormDiv+' [data-successfail]').html(returns);
				});
			}
		});
	},
	getProjectsRelatedList: function(auto_pj, thisBtn) {
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>');
		$.post(globals.serverAddress, {...globals.baseQuery, auto_pj: auto_pj, req: 'projectsController', action: 'getProjectsRelatedList'}, function(data){
			if(data.ok=='ok') $("#projectsRelatedListCont").html(data.snippet);
			else $("#projectsRelatedListCont").html('<div class="alert alert-warning h2 text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Ce Projet n\'a pas donné suite à une vente !</div>');
		}, "json").done(function(data) {
			if(data.ok=='ok') App.buildDataTables('#dataTableProjectsRelatedList', 25, true, 3, 'asc', 'Liste des Ventes associés', [0,2,3,4,5], [1,6,7,8], '', '', '', [0,1,2,3,4,5,6,7,8], true);
		}).always(function(data) {
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			let myModal = new bootstrap.Modal(document.getElementById('getProjectsRelatedListModal'));
			myModal.show();
		});
	},
	addQuotationFromProject: function(thisBtn, event) {
		event.preventDefault()
		const thisForm = thisBtn.closest('form')
		const myFormDiv = '#'+thisForm.getAttribute('id')
		// Making sure we set & record valid_pj=1 as it is used to build projects selectBox in quotation forms
		thisForm.querySelector('[data-field="valid_pj"]').value = 1
		this.modProject(myFormDiv, false, true)
	},
	setQuotationsListPage: async function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#addQuotationForm', '#modQuotationForm'], 'ordersController', ['client_o', 'project_o', 'salesman_o', 'products_o', 'scenes_o', 'user_o', 'site_o']);
		$('#addQuotationForm [data-field=site_o]').val(globals.site).attr('data-default', globals.site)
		$('#addQuotationForm [data-field=user_o]').val(globals.id).attr('data-default', globals.id)
		$('#addQuotationForm [data-field=salesman_o]').val(App.settings.id).attr('data-default', App.settings.id)
		$('#addQuotationForm [data-field=payment_method]').val(this.settings.siteSettings?.payment_method_ss).attr('data-default', this.settings.siteSettings?.payment_method_ss)
		$('#addQuotationForm [data-field=payment_conditions]').val(this.settings.siteSettings?.payment_conditions_ss).attr('data-default', this.settings.siteSettings?.payment_conditions_ss)
		if(this.settings.siteSettings) {
			const dateEnd = this.getFutureDate(this.settings.siteSettings.date_valid_ss)
			$('#addQuotationForm [data-field=date_quotation_end]').val(dateEnd).attr('data-default', dateEnd);
		}
		// $('#sendMailForm [data-field=body_m]').val(this.settings.siteSettings?.body_m_quotation)
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'ordersController', action: 'getQuotationsList'}, function(data){
			if(data.ok=='ok') {
				$("[data-table-view]").html(data.snippet);
				$("[data-kanban-view]").html(data.snippetPhone);
				App.buildDataFilterSearch();
			}
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("[data-table-view]").html(noOne);
				$("[data-kanban-view]").html(noOne);
			}
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableQuotationsList', 25, true, 0, 'desc', 'Liste des Devis', [0,2,3,4,5,10], [1,6,7,8,9], '', '', '', [0,1,2,3,4,5,6,7,8,9,10], true);
		}).always(function() {
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		const orderLinesConts = document.querySelectorAll('[data-order-lines]')
		orderLinesConts.forEach((elem) => {
			const myGragable = new Sortable(elem, {
				animation: 150,
				handle: '.handle',
				swapThreshold: 0.9, // 0 to 1 => width of the swap zone on elements
				ghostClass: 'blue-background-class'
			});
		})
		const date_quotation_end = document.querySelectorAll('[data-field="date_quotation_end"]');
		if(date_quotation_end.length>0) {
			date_quotation_end.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		const date_confirmation_o = document.querySelectorAll('[data-field="date_confirmation_o"]');
		if(date_confirmation_o.length>0) {
			date_confirmation_o.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		this.popFormFromUrlParam()
		const duplicate = App.urlParam('duplicate', document.URL);
		if(duplicate) { // '/sales/quotations?goto=addQuotationModa&duplicate=1&fields=auto_o&idFields='+auto_o
			// const fields = App.urlParam('fields', document.URL)?.split(',');
			const idFields = App.urlParam('idFields', document.URL)?.split(',');
			const orderId = (Array.isArray(idFields)) ? idFields[0] : idFields
			App.duplicateOrder(orderId, false, '#addQuotationForm', 'addQuotationModal')
		}
		// else $("#clientListCont").html('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette liste !</div>');
		// Building meta tags list...
		$('head title').text('Devis - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Devis');
	},
	setQuotationPage: async function(orderId) {
		// First We get client's select box then we fill form with values
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#modQuotationForm'], 'ordersController', ['client_o', 'project_o', 'salesman_o', 'products_o', 'scenes_o', 'user_o', 'site_o']);
		const myFormDiv = '#modQuotationForm';
		App.emptyOrderForms(myFormDiv);
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_o: orderId, req: 'ordersController', action: 'fillModOrder'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.orders)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				data.orderLines.forEach( async (item, index) => {
					console.log(index, item.auto_ol, item.name_ol, item.desc_ol)
					if(item.product_ol==0) await App.addLineToOrder(null, null, item.auto_ol, item.desc_ol, myFormDiv) // Categories & empty lines
					else await App.addProductToOrder(null, null, item.product_ol, item.reference_p, item.name_ol, item.type_p, item.howmany_ol, item.price_ol, item.cost_ol, item.discount_ol, item.desc_ol, item.unit_ol+';'+item.name_uom+';'+item.multiply_uom, item.tax_ol+';'+item.name_tax+';'+item.multiply_tax, item.payed_ol, item.invoiced_ol, data.orders.payed_o, data.orders.invoiced_o, item.auto_ol, myFormDiv)
				})
				document.querySelectorAll(`${myFormDiv} .handle`).forEach((item) => {
					item.addEventListener('click', (event) =>{
						event.preventDefault()
					})
				})
				document.querySelector('#modQuotationFormHeader').innerHTML = '#'+data.orders.name_quotation
				document.querySelectorAll('a[data-clients-link]').forEach((item) => {
					item.setAttribute('href', '/clients/'+data.orders.client_o)
				})
				document.querySelector(myFormDiv+' a[data-projects-link]').setAttribute('href', '/sales/projects/'+data.orders.project_o)
				document.querySelector('a[data-docs-link]').setAttribute('href', globals.serverBaseUrl+'docs/quotation/'+data.orders.order_doc_quotation)
				if(data.orders.num_invoice>0) {
					$(myFormDiv+' [data-successfail]').html('<div class="alert alert-danger" role="alert"><b>La facturation a été lancée pour ce devis !\r\nCelui-ci n\'est donc plus éditable.</b></div>')
					Swal.fire({
						title: 'Attention',
						html: 'La facturation a été lancée pour ce devis !<br>Celui-ci n\'est donc plus éditable.',
						icon: 'warning',
						confirmButtonText: 'Ok',
						confirmButtonColor: 'red',
						timer: 2600,
						timerProgressBar: true,
					})
					$(`${myFormDiv} input, ${myFormDiv} select, ${myFormDiv} button, ${myFormDiv} textarea`).not('#addInvoiceFromQuotation, #addPlanningFromQuotation').attr('disabled', true);
					$(`${myFormDiv} button[data-bs-toggle=collapse]`).attr('disabled', false); // ReEnable order lines toggle buttons
				}
				else {
					const orderLinesCont = document.querySelector('[data-order-lines]')
					new Sortable(orderLinesCont, {
						animation: 150,
						handle: '.handle',
						swapThreshold: 0.9, // 0 to 1 => width of the swap zone on elements
						ghostClass: 'blue-background-class'
					})
				}
				if(data.orders.valid_o==1) {
					if(data.orders.num_invoice>0) {
						if(data.orders.multiple_o) $('a[data-order-add-invoice]').attr('href', `/sales/invoices/?filter=Devis&val=${data.orders.name_quotation}`).attr('title', 'Voir la facturation de ce Devis').removeClass('d-none').html('<i class="fa fa-2x fa-file-invoice-dollar"></i>');
						else $('a[data-order-add-invoice]').attr('href', `/sales/invoices/${data.orders.auto_o}`).attr('title', 'Voir la facturation de ce Devis').removeClass('d-none').html('<i class="fa fa-2x fa-file-invoice-dollar"></i>');
					}
					else $('a[data-order-add-invoice]').attr('href', `/sales/invoices?goto=addInvoiceModal&fields=client_o,order_i&idFields=${data.orders.client_o},${data.orders.auto_o}`).removeClass('d-none');
					if(data.orders.active_pl==1) $('a[data-order-add-planning]').attr('href', `goto=modPlanningModal&fields=order_pl&idFields=${data.orders.auto_o}`).attr('title', 'Voir l\'intervention Plannifiée').removeClass('d-none').html('<i class="fa fa-2x fa-calendar-check"></i>');
					else $('a[data-order-add-planning]').attr('href', `/planning?goto=addPlanningModal&fields=client_pl,order_pl&idFields=${data.orders.client_o},${data.orders.auto_o}`).removeClass('d-none');
					document.querySelector('button[data-quotation-deleter]').classList.add('d-none');
					document.querySelector('button[data-quotation-preview]').classList.remove('d-none');
				}
				else {
					document.querySelector('a[data-order-add-invoice]').classList.add('d-none');
					document.querySelector('a[data-order-add-planning]').classList.add('d-none');
					document.querySelector('button[data-quotation-deleter]').classList.remove('d-none');
					document.querySelector('button[data-quotation-preview]').classList.add('d-none');
				}
				document.querySelector('button[data-order-duplicate]').setAttribute('onclick', `App.duplicateOrder(${orderId}, true, '#addQuotationForm', 'addQuotationModal')`);
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce Devis !");
		}, "json").always(function(){
			$(myFormDiv+' [data-field="products_o"]').val(0); // Empty products choice field
			$(myFormDiv+' [data-field="scenes_o"]').val(0); // Empty scenes choice field
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		const date_quotation_end = document.getElementById('modQuotationForm').querySelectorAll('[data-field="date_quotation_end"]');
		if(date_quotation_end.length>0) {
			date_quotation_end.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		const date_confirmation_o = document.querySelectorAll('[data-field="date_confirmation_o"]');
		if(date_confirmation_o.length>0) {
			date_confirmation_o.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		// Building meta tags list...
		$('head title').text('Devis - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Devis');
	},
	fillModOrder: async function(auto_o, thisBtn, myFormDiv, myFormModal) {
		App.emptyOrderForms(myFormDiv);
		// const myFormId = (myFormDiv.includes('#')) ? myFormDiv.replace('#','') : myFormDiv;
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_o: auto_o, req: 'ordersController', action: 'fillModOrder'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.orders)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				data.orderLines.forEach( async (item, index) => {
					console.log(index, item.auto_ol, item.name_ol, item.desc_ol)
					if(item.product_ol==0) await App.addLineToOrder(null, null, item.auto_ol, item.desc_ol, myFormDiv) // Categories & empty lines
					else await App.addProductToOrder(null, null, item.product_ol, item.reference_p, item.name_ol, item.type_p, item.howmany_ol, item.price_ol, item.cost_ol, item.discount_ol, item.desc_ol, item.unit_ol+';'+item.name_uom+';'+item.multiply_uom, item.tax_ol+';'+item.name_tax+';'+item.multiply_tax, item.payed_ol, item.invoiced_ol, data.orders.payed_o, data.orders.invoiced_o, item.auto_ol, myFormDiv)
				})
				document.querySelectorAll(`${myFormDiv} .handle`).forEach((item) => {
					item.addEventListener('click', (event) =>{
						event.preventDefault()
					})
				})
				const headerId = (myFormDiv=='#modQuotationForm') ? data.orders.name_quotation : data.orders.name_invoice
				document.querySelector(myFormDiv+'Header').innerHTML = '#'+headerId
				document.querySelector(myFormDiv+' a[data-clients-link]').setAttribute('href', '/clients/'+data.orders.client_o)
				document.querySelector(myFormDiv+' a[data-projects-link]').setAttribute('href', '/sales/projects/'+data.orders.project_o)
				document.querySelector('a[data-docs-link]').setAttribute('href', globals.serverBaseUrl+'docs/quotation/'+data.orders.order_doc_quotation)
				if(data.orders.num_invoice>0) {
					$(myFormDiv+' [data-successfail]').html('<div class="alert alert-danger" role="alert"><b>La facturation a été lancée pour ce devis !\r\nCelui-ci n\'est donc plus éditable.</b></div>')
					Swal.fire({
						title: 'Attention',
						html: 'La facturation a été lancée pour ce devis !<br>Celui-ci n\'est donc plus éditable.',
						icon: 'warning',
						confirmButtonText: 'Ok',
						confirmButtonColor: 'red',
						timer: 2600,
						timerProgressBar: true,
					})
					$(`${myFormDiv} input, ${myFormDiv} select, ${myFormDiv} button, ${myFormDiv} textarea`).not('#addInvoiceFromQuotation, #addPlanningFromQuotation').attr('disabled', true);
					$(`${myFormDiv} button[data-bs-toggle=collapse]`).attr('disabled', false); // ReEnable order lines toggle buttons
				}
				else $(`${myFormDiv} input, ${myFormDiv} select, ${myFormDiv} button, ${myFormDiv} textarea`).not('[data-order-lines] input, [data-order-invoices] input').attr('disabled', false);
				if(data.orders.valid_o==1) {
					if(data.orders.num_invoice>0) {
						if(data.orders.multiple_o) $('a[data-order-add-invoice]').attr('href', `/sales/invoices/?filter=Devis&val=${data.orders.name_quotation}`).attr('title', 'Voir la facturation de ce Devis').removeClass('d-none').html('<i class="fa fa-2x fa-file-invoice-dollar"></i>');
						else $('a[data-order-add-invoice]').attr('href', `/sales/invoices/${data.orders.auto_o}`).attr('title', 'Voir la facturation de ce Devis').removeClass('d-none').html('<i class="fa fa-2x fa-file-invoice-dollar"></i>');
					}
					else $('a[data-order-add-invoice]').attr('href', `/sales/invoices?goto=addInvoiceModal&fields=client_o,order_i&idFields=${data.orders.client_o},${data.orders.auto_o}`).removeClass('d-none');
					if(data.orders.active_pl==1) $('a[data-order-add-planning]').attr('href', `goto=modPlanningModal&fields=order_pl&idFields=${data.orders.auto_o}`).attr('title', 'Voir l\'intervention Plannifiée').removeClass('d-none').html('<i class="fa fa-2x fa-calendar-check"></i>');
					else $('a[data-order-add-planning]').attr('href', `/planning?goto=addPlanningModal&fields=client_pl,order_pl&idFields=${data.orders.client_o},${data.orders.auto_o}`).removeClass('d-none');
					document.querySelector('button[data-quotation-deleter]').classList.add('d-none');
					document.querySelector('button[data-quotation-preview]').classList.remove('d-none');
				}
				else {
					document.querySelector('a[data-order-add-invoice]').classList.add('d-none');
					document.querySelector('a[data-order-add-planning]').classList.add('d-none');
					document.querySelector('button[data-quotation-deleter]').classList.remove('d-none');
					document.querySelector('button[data-quotation-preview]').classList.add('d-none');
				}
				document.querySelector('button[data-order-duplicate]').setAttribute('onclick', `App.duplicateOrder(${auto_o}, false, '#addQuotationForm', 'addQuotationModal')`);
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
				else {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.remove('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = '';
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce Devis !");
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			$(myFormDiv+' [data-field="products_o"]').val(0); // Empty products choice field
			$(myFormDiv+' [data-field="scenes_o"]').val(0); // Empty scenes choice field
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
		});
	},
	duplicateOrder: async function(auto_o, redirect, myFormDiv, myFormModal) {
		if(redirect) {
			document.location.href='/sales/quotations?goto=addQuotationModa&duplicate=1&fields=auto_o&idFields='+auto_o;
			return;
		}
		else this.closeModal('modQuotationModal');
		App.emptyOrderForms(myFormDiv);
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_o: auto_o, req: 'ordersController', action: 'fillModOrder'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.orders)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				data.orderLines.forEach( async (item, index) => {
					console.log(index, item.auto_ol, item.name_ol, item.desc_ol)
					if(item.product_ol==0) await App.addLineToOrder(null, null, item.auto_ol, item.desc_ol, myFormDiv) // Categories & empty lines
					else await App.addProductToOrder(null, null, item.product_ol, item.reference_p, item.name_ol, item.type_p, item.howmany_ol, item.price_ol, item.cost_ol, item.discount_ol, item.desc_ol, item.unit_ol+';'+item.name_uom+';'+item.multiply_uom, item.tax_ol+';'+item.name_tax+';'+item.multiply_tax, item.payed_ol, item.invoiced_ol, data.orders.payed_o, data.orders.invoiced_o, item.auto_ol, myFormDiv)
				})
				document.querySelectorAll(`${myFormDiv} .handle`).forEach((item) => {
					item.addEventListener('click', (event) =>{
						event.preventDefault()
					})
				})
				document.querySelector(myFormDiv+' a[data-clients-link]').setAttribute('href', '/clients/'+data.orders.client_o)
				document.querySelector(myFormDiv+' a[data-projects-link]').setAttribute('href', '/sales/projects/'+data.orders.project_o)
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
				else {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.remove('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = '';
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce Devis !");
		}, "json").always(function(){
			$(myFormDiv+' [data-field="products_o"]').val(0); // Empty products choice field
			$(myFormDiv+' [data-field="scenes_o"]').val(0); // Empty scenes choice field
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
		});
	},
	addProductToOrder: async (thisBtn, event, auto_p, reference_p, name_p, type_p, howmany_p, price_p, cost_p, discount_p, desc_p, unitData, taxData, payed_ol, invoiced_ol, payed_o, invoiced_o, auto_ol, thisFormId) => {
		if(event) event.preventDefault()
		// thisBtn is either a button in a popup modal or a select in the form
		const thisForm = (thisFormId) ? document.querySelector(thisFormId) : thisBtn.closest('form')
		const idProduct = (auto_p) ? auto_p : thisBtn.value
		const refProduct = (reference_p) ? reference_p : thisBtn.selectedOptions[0].dataset.reference
		const nameProduct = (name_p || name_p=='') ? name_p : thisBtn.selectedOptions[0].dataset.name
		const typeProduct = (type_p) ? type_p : thisBtn.selectedOptions[0].dataset.type
		const howmanyProduct = (howmany_p) ? howmany_p : 1
		const priceProduct = (price_p) ? price_p : thisBtn.selectedOptions[0].dataset.price
		const costProduct = (cost_p || cost_p==0) ? cost_p : thisBtn.selectedOptions[0].dataset.cost
		let discountProduct = (discount_p || discount_p==0) ? discount_p : thisBtn.selectedOptions[0].dataset.discount
		const descProduct = (desc_p || desc_p=='') ? desc_p.replaceAll('<br>', '\r\n') : thisBtn.selectedOptions[0].dataset.desc
		const unitDataProduct = (unitData || unitData=='') ? unitData.split(';') : thisBtn.selectedOptions[0].dataset.unit?.split(';')
		const taxDataProduct = (taxData || taxData=='') ? taxData.split(';') : thisBtn.selectedOptions[0].dataset.tax?.split(';')
		const payedLine = (payed_ol) ? payed_ol : 0
		const invoicedLine = (invoiced_ol) ? invoiced_ol : 0
		const payedOrder = (payed_o) ? payed_o : 0
		const invoicedOrder = (invoiced_o) ? invoiced_o : 0
		const idLine = (auto_ol) ? auto_ol : 0
		const myFormId = thisForm.getAttribute('id')
		const orderId = thisForm.querySelector('[data-order-id]').value
		const invoicing = (orderId!=0 && myFormId!='addQuotationForm') ? true : false // orderId!=0 because orphan invoices don't get to set partial payments
		const invoicingEdit = (myFormId=='addInvoiceForm') ? true : false // Invoicing field is not the same then Quotation => invoiced // Invoice => invoice
		const invoiceLabel = (invoicingEdit) ? 'Facturer' : 'Facturé'
		const invoice = (invoicingEdit) ? 100-Number(invoicedLine) : Number(invoicedLine)
		const maxInvoice = (invoicingEdit) ? 100-Number(invoicedLine) : 100
		const quotationOrModDisabled = (myFormId.includes('mod')) ? 'disabled' : ''
		if(auto_p) { // Maybe we came from "orderAddProductsListModal"
			App.closeModal('orderAddProductsListModal')
			const productSelect = thisForm.querySelector('[data-field="products_o"]')
			if(productSelect) productSelect.value = auto_p
		}
		else thisBtn.value = 0; // Just reset the select box
		const clientDiscount = thisForm.querySelector('[data-field="client_o"]').selectedOptions[0]?.dataset.catalogDiscount
		if(!clientDiscount) {
			Swal.fire({
				title: 'Attention',
				html: '<b class="text-danger">Vous devez choisir un client avant d\'ajouter des lignes à la commande.</b>',
				icon: 'error',
				confirmButtonText: 'Ok',
				confirmButtonColor: 'red',
				timer: 2600,
				timerProgressBar: true,
			})
			return;
		}
		// const productClientPrice = (idLine==0) ? Number(priceProduct)*((100-Number(clientDiscount))/100) : priceProduct // Only apply client's discount once !
		discountProduct = (idLine==0) ? clientDiscount : discountProduct
		const uniqCollapseId = new Date().getTime() + '_' + idLine + '_' + idProduct
		const orderProductsCont = thisForm.querySelector('[data-order-lines]')
		orderProductsCont.classList.add('list-group', 'list-group-numbered', 'my-2', 'px-2')
		// Next is similar to jQuery $(orderProductsCont).append(`...`)
		// <a href="/products/'.$row['auto_p'].'" class="stretched-link"><i class="fa fa-shop text-white-50"></i></a>
		orderProductsCont.insertAdjacentHTML('beforeend',`
			<li class="list-group-item list-group-item-action list-group-item-primary d-flex justify-content-between align-items-start" data-order-line>
				<div class="container-fluid">
					<div class="row">
						<div class="col-lg-3 col-xl-3 text-break">
							<div class="fw-bold">
								<a href="/products/${idProduct}" target="_blank">${refProduct}</a>
							</div>
							<div class="form-group">
								<input class="form-control" name="name_ol[]" data-field="name_ol" type="text" placeholder="Nom du produit" value="${nameProduct}" data-mod-invoice-disabled/>
							</div>
							<b>${typeProduct}</b>
							<input type="hidden" name="auto_ol[]" data-field="auto_ol" value="${idLine}" data-order-id-line>
							<input type="hidden" name="product_ol[]" data-field="product_ol" value="${idProduct}" data-order-product>
						</div>
						<div class="col-lg-9 col-xl-9">
							<div class="row align-items-center g-2">
								<div class="form-group col-sm-6 col-lg-3">
									<label class="small mb-1">Quantité</label>
									<div class="input-group">
										<input class="form-control" name="howmany_ol[]" data-field="howmany_ol" type="number" step="0.01" value="${howmanyProduct}" placeholder="Quantité" onchange="App.calculateOderTotals(this);" onkeyup="App.calculateOderTotals(this);" data-order-quantities data-mod-invoice-disabled/>
									</div>
								</div>
								<div class="form-group col-sm-6 col-lg-3">
									<label class="small mb-1">P.U HT</label>
									<button type="button" class="btn btn-secondary btn-icon btn-xs" data-bs-toggle="popover" data-bs-title="P.U HT" data-bs-content="Prix Unitaire HT" tabindex="-1"><i class="fa fa-question"></i></button>
									<div class="input-group">
										<input class="form-control" name="price_ol[]" data-field="price_ol" type="number" step="0.01" value="${priceProduct}" placeholder="Prix Unitaire HT" onchange="App.calculateOderTotals(this);" onkeyup="App.calculateOderTotals(this);" data-order-prices data-mod-invoice-disabled/>
										<span class="input-group-text"><i class="fa fa-euro"></i></span>
									</div>
								</div>
								<div class="form-group col-sm-6 col-lg-3">
									<label class="small mb-1">Unité</label>
									<div class="input-group">
										<!-- <span class="input-group-text"><i class="fa fa-ruler-combined"></i></span> -->
										<select class="form-select" name="unit_ol[]" id="unit_ol_${idProduct}" data-field="unit_ol" value="${unitDataProduct[0]}" data-order-units data-mod-invoice-disabled>
											<option value="${unitDataProduct[0]}"  data-multiplyUom="${unitDataProduct[2]}" selected>${unitDataProduct[1]}</option>
											${globals.optionsUnits}
										</select>
									</div>
								</div>
								<div class="form-group col-sm-6 col-lg-3">
									<label class="small mb-1">Total HT</label>
									<div class="input-group">
										<input class="form-control" name="amount_ol[]" data-field="amount_ol" type="number" step="0.01" value="" placeholder="Total HT" data-order-amounts readonly/>
										<span class="input-group-text"><i class="fa fa-euro"></i></span>
									</div>
									<input type="hidden" name="amount_tax_incl_ol[]" data-field="amount_tax_incl_ol" value="0" data-order-amounts-tax>
								</div>
							</div>
							<div class="row align-items-center g-2 collapse" id="details_${uniqCollapseId}">
								<div class="form-group col-sm-6 col-lg-3">
									<label class="small mb-1">TVA</label>
									<div class="input-group">
										<!-- <span class="input-group-text"><i class="fa fa-percent"></i></span> -->
										<select class="form-select" name="tax_ol[]" id="tax_ol_${idProduct}" data-field="tax_ol" value="${taxDataProduct[0]}" onchange="App.calculateOderTotals(this);" onkeyup="App.calculateOderTotals(this);" data-order-taxes data-mod-invoice-disabled>
											<option value="${taxDataProduct[0]}" data-multiply="${taxDataProduct[2]}" selected>${taxDataProduct[1]}</option>
											${globals.optionsTaxes}
										</select>
									</div>
								</div>
								<div class="form-group col-sm-6 col-lg-3">
									<label class="small mb-1">C.R.U HT</label>
									<button type="button" class="btn btn-secondary btn-icon btn-xs" data-bs-toggle="popover" data-bs-title="C.R.U HT" data-bs-content="Coût de Revient Unitaire HT" tabindex="-1"><i class="fa fa-question"></i></button>
									<div class="input-group">
										<input class="form-control" name="cost_ol[]" data-field="cost_ol" type="number" step="0.01" min="0" value="${costProduct}" placeholder="Coût de Revient Unitaire" onchange="App.calculateOderTotals(this);" onkeyup="App.calculateOderTotals(this);" data-order-costs data-mod-invoice-disabled/>
										<span class="input-group-text"><i class="fa fa-euro"></i></span>
									</div>
								</div>
								<div class="form-group col-sm-6 col-lg-3">
									<label class="small mb-1""><i class="fa fa-tags me-1"></i>Remise</label>
									<span class="badge bg-primary rounded-pill ms-2 fs-6" data-display-range-value></span>
									<input type="range" class="form-range" name="discount_ol[]" data-field="discount_ol" value="${discountProduct}" min="0" max="100" step="1" onchange="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" oninput="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" data-order-discounts data-mod-invoice-disabled>
								</div>
								<div class="form-group col-sm-6 col-lg-3">
									<label class="small mb-1">Marge</label>
									<span class="badge bg-success rounded-pill ms-2 fs-6" data-display-margins-value></span>
									<div class="input-group">
										<input class="form-control" name="margin_ol[]" data-field="margin_ol" type="number" step="0.01" value="" placeholder="Marge" data-order-margins readonly/>
										<span class="input-group-text"><i class="fa fa-euro"></i></span>
									</div>
								</div>
								<div class="form-group col-12">
									<label class="small mb-1">Description</label>
									<div class="input-group">
										<span class="input-group-text"><i class="fa fa-2x fa-clipboard-list"></i></span>
										<textarea class="form-control form-control-lg" rows="2" name="desc_ol[]" placeholder="Description" value="${descProduct}" data-mod-invoice-disabled>${descProduct}</textarea>
									</div>
								</div>
								<div class="form-group col-sm-6 col-lg-3 d-none" data-invoice-only>
									<label class="small mb-1""><i class="fa fa-tags me-1"></i>Payé</label>
									<span class="badge bg-primary rounded-pill ms-2 fs-6" data-display-range-value></span>
									<input type="range" class="form-range" name="payed_ol[]" data-field="payed_ol" value="${payedLine}" min="0" max="100" step="0.000001" onchange="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" oninput="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" data-order-payeds disabled>
								</div>
								<div class="form-group col-sm-6 col-lg-3 d-none" data-invoice-only>
									<label class="small mb-1">Montant TTC</label>
									<div class="input-group">
										<input class="form-control" name="payed_num_ol[]" data-field="payed_num_ol" type="number" step="0.01" min="0" value="" placeholder="Payé" data-order-payeds-num readonly/>
										<span class="input-group-text"><i class="fa fa-euro"></i></span>
									</div>
								</div>
								<div class="form-group col-sm-6 col-lg-3 d-none" data-invoice-only>
									<label class="small mb-1""><i class="fa fa-tags me-1"></i>${invoiceLabel}</label>
									<span class="badge bg-primary rounded-pill ms-2 fs-6" data-display-range-value></span>
									<input type="number" class="form-control" name="invoiced_ol[]" data-field="invoiced_ol" value="${invoice}" min="0" max="${maxInvoice}" step="0.000001" onchange="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" oninput="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" data-order-invoiceds ${quotationOrModDisabled}>
									<!-- <input type="range" class="form-range" name="invoiced_ol[]" data-field="invoiced_ol" value="${invoice}" min="0" max="${maxInvoice}" step="0.01" onchange="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" oninput="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" data-order-invoiceds ${quotationOrModDisabled}> -->
								</div>
								<div class="form-group col-sm-6 col-lg-3 d-none" data-invoice-only>
									<label class="small mb-1">Montant TTC</label>
									<div class="input-group">
										<input class="form-control" name="invoiced_num_ol[]" data-field="invoiced_num_ol" type="number" step="0.01" min="0" value="" placeholder="Facturé" data-order-invoiceds-num readonly/>
										<span class="input-group-text"><i class="fa fa-euro"></i></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="d-flex flex-column g-1">
					<button class="badge btn btn-danger btn-icon btn-sm shadow" onclick="App.delLineFromOrder(this, event, ${idLine})" data-mod-invoice-none>
						<i class="fa fa-2x fa-times"></i>
					</button>
					<button class="handle badge btn btn-dark btn-icon btn-sm shadow mt-1" title="Maintenez puis glisser à la position souhaitée.">
						<i class="fa fa-2x fa-arrows-up-down"></i>
					</button>
					<button class="badge btn btn-primary btn-icon btn-sm shadow mt-1" data-bs-toggle="collapse" data-bs-target="#details_${uniqCollapseId}" aria-expanded="false" aria-controls="details_${uniqCollapseId}" onclick="App.expendOrderLine(this, event)">
						<i class="fa fa-2x fa-angles-down"></i>
					</button>
				</div>
			</li>
		`);
		await App.orderAddGlobalSettings(thisForm, payedOrder, invoicedOrder)
		orderProductsCont.querySelectorAll('[data-order-discounts]').forEach((item, index) => {
			App.displayRangeValue(item, ' %') // Allows to show ranges value badges in modForms
		})
		App.enablePopovers()
		if(invoicing) {
			thisForm.querySelectorAll('[data-invoice-only]').forEach((item, index) => {
				item.classList.remove('d-none')
			})
			orderProductsCont.querySelectorAll('[data-order-payeds], [data-order-invoiceds]').forEach((item, index) => {
				App.displayRangeValue(item, ' %', 2) // Allows to show ranges value badges in modForms
			})
		}
		thisForm.querySelectorAll('[data-order-invoiced], [data-order-payed]').forEach((item, index) => {
			App.displayRangeValue(item, ' %', 2)
		})
		App.calculateOderTotals()
		const invoiceFromQuotation = (myFormId=='addInvoiceForm' && orderId!=0) ? true : false
		if(invoiceFromQuotation || myFormId=='modInvoiceForm') {
			thisForm.querySelectorAll('[data-mod-invoice-disabled]').forEach((item, index) => {
				item.setAttribute('disabled', 'true')
			})
			thisForm.querySelectorAll('[data-mod-invoice-none]').forEach((item, index) => {
				item.classList.add('d-none')
			})
		}
	},
	addLineToOrder: async (thisBtn, event, auto_ol, desc_ol, thisFormId) => {
		if(event) event.preventDefault()
		const idLine = (auto_ol) ? auto_ol : 0
		const descLine = (desc_ol) ? desc_ol : ''
		// thisBtn is either a button in a popup modal or a select in the form
		const thisForm = (thisFormId) ? document.querySelector(thisFormId) : thisBtn.closest('form')
		const orderProductsCont = thisForm.querySelector('[data-order-lines]')
		orderProductsCont.classList.add('list-group', 'list-group-numbered', 'my-2', 'px-2')
		// Next is similar to jQuery $(orderProductsCont).append(`...`)
		orderProductsCont.insertAdjacentHTML('beforeend',`
			<li class="list-group-item list-group-item-action list-group-item-primary d-flex justify-content-between align-items-start" data-order-line>
				<div class="container-fluid">
					<div class="row">
						<div class="col-lg-3 col-xl-3 text-break">
							<div class="fw-bold">Catégorie</div>
							Créer une ligne de catégorie ou un séparateur
							<input type="hidden" name="auto_ol[]" data-field="auto_ol" value="${idLine}" data-order-id-line>
							<input type="hidden" name="product_ol[]" data-field="product_ol" value="0" data-order-product-none>
							<input type="hidden" name="name_ol[]" data-field="name_ol" value="">
						</div>
						<div class="col-lg-9 col-xl-9">
							<div class="row align-items-center g-2">
								<input type="hidden" name="howmany_ol[]" data-field="howmany_ol" value="0" data-order-quantities>
								<input type="hidden" name="price_ol[]" data-field="price_ol" value="0" data-order-prices>
								<input type="hidden" name="unit_ol[]" data-field="unit_ol" value="0" data-order-units>
								<input type="hidden" name="amount_ol[]" data-field="amount_ol" value="0" data-order-amounts>
								<input type="hidden" name="amount_tax_incl_ol[]" data-field="amount_tax_incl_ol" value="0" data-order-amounts-tax>
								<input type="hidden" name="cost_ol[]" data-field="cost_ol" value="0" data-order-costs>
								<span class="d-none" data-display-range-value></span>
								<input type="hidden" name="discount_ol[]" data-field="discount_ol" value="0" data-order-discounts>
								<span class="d-none" data-display-range-value></span>
								<input type="hidden" name="margin_ol[]" data-field="margin_ol" value="0" data-order-margins>
								<span class="d-none" data-display-range-value></span>
								<input type="hidden" name="payed_ol[]" data-field="payed_ol" value="0" data-order-payeds>
								<input type="hidden" name="payed_num_ol[]" data-field="payed_num_ol" value="0" data-order-payeds-num>
								<span class="d-none" data-display-range-value></span>
								<input type="hidden" name="invoiced_ol[]" data-field="invoiced_ol" value="0" data-order-invoiceds>
								<input type="hidden" name="invoiced_num_ol[]" data-field="invoiced_num_ol" value="0" data-order-invoiceds-num>
								<div class="d-none">
									<select name="tax_ol[]" data-field="tax_ol" value="" data-order-taxes>
										<option value="0" data-multiply="1" selected></option>
										${globals.optionsTaxes}
									</select>
									<span class="badge bg-primary rounded-pill ms-2 fs-6" data-display-range-value></span>
									<span class="badge bg-success rounded-pill ms-2 fs-6" data-display-margins-value></span>
								</div>
								<div class="form-group col-12">
									<label class="small mb-1" for="label_quotation">Libellé</label>
									<div class="input-group">
										<span class="input-group-text"><i class="fa fa-xmarks-lines"></i></span>
										<input class="form-control form-control-lg" name="desc_ol[]" placeholder="Description" value="${descLine}" data-mod-invoice-disabled/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="d-flex flex-column g-1" data-mod-invoice-none>
					<button class="badge btn btn-danger btn-icon btn-sm shadow" onclick="App.delLineFromOrder(this, event, ${idLine})">
						<i class="fa fa-2x fa-times"></i>
					</button>
					<button class="handle badge btn btn-dark btn-icon btn-sm shadow mt-1" title="Maintenez puis glisser à la position souhaitée.">
						<i class="fa fa-2x fa-arrows-up-down"></i>
					</button>
				</div>
			</li>
		`);
	},
	delLineFromOrder: function(thisBtn, event, idLine) {
		event.preventDefault()
		const thisForm = thisBtn.closest('form')
		const thisFormId = thisForm.getAttribute('id')
		if(idLine && thisFormId!='addInvoiceForm') { // We're in Edit Mode so We have to delete this Order's Line in DB except when creating invoice
			const thisBtnHtml = $(thisBtn).html()
			$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
			$.post(globals.serverAddress, {...globals.baseQuery, auto_ol: idLine, req: 'ordersController', action: 'delLineFromOrder'}, function(data){
				if(data.ok=='ok') {
					thisBtn.closest('li').remove()
					App.calculateOderTotals()
					App.orderAddGlobalSettings(thisForm)
					Swal.fire({
						title: 'Attention',
						html: '<b class="text-danger">N\'oubliez pas d\'enregister le Devis afin de le sauvegarder et de générer le nouveau document !</b>',
						icon: 'error',
						confirmButtonText: 'Ok',
						confirmButtonColor: 'red',
						timer: 1600,
						timerProgressBar: true,
					})
				}
				else alert("Suite à une erreur la ligne n'a pas été supprimée !")
			}, "json").always(function(){
				$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			});
		}
		else { // In Creation Mode We just remove & calculate
			thisBtn.closest('li').remove()
			this.calculateOderTotals()
			this.orderAddGlobalSettings(thisForm)
		}
	},
	orderAddProductsList: (thisBtn, event) => {
		event.preventDefault();
		const thisBtnHtml = $(thisBtn).html()
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const thisFormId = '#'+thisBtn.closest('form').getAttribute('id')
		$.post(globals.serverAddress, {...globals.baseQuery, formId: thisFormId, req: 'ordersController', action: 'orderAddProductsList'}, function(data){
			$('#orderAddProductsListCont').html(data.snippet);
		}, "json").done(function() {
			App.buildDataTables('#dataTableProductsList', 25, true, 0, 'asc', 'Liste des Produits', [0,1,4,6], [2,3,5], '', '', '', [0,1,2,3,4,5,6], true);
		}).always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			let myModal = new bootstrap.Modal(document.getElementById('orderAddProductsListModal'));
			myModal.show();
		});
	},
	addSceneToOrder: (thisBtn, event, auto_sc, thisFormId) => {
		event.preventDefault()
		// thisBtn is either a button in a popup modal or a select in the form
		const idScene = (auto_sc) ? auto_sc : thisBtn.value
		const thisForm = (thisFormId) ? document.getElementById(thisFormId) : thisBtn.closest('form')
		const myFormId = thisForm.getAttribute('id')
		const orderId = thisForm.querySelector('[data-order-id]').value
		const invoicing = (orderId!=0 && myFormId!='addQuotationForm') ? true : false // orderId!=0 because orphan invoices don't get to set partial payments
		if(auto_sc) {
			App.closeModal('orderAddScenesListModal') // Coming from "orderAddScenesListModal"
			thisForm.querySelector('[data-field="scenes_o"]').value = auto_sc
		}
		else thisBtn.value = 0; // Just reset the select box
		const clientDiscount = thisForm.querySelector('[data-field="client_o"]').selectedOptions[0]?.dataset.catalogDiscount
		if(!clientDiscount) {
			Swal.fire({
				title: 'Attention',
				html: '<b class="text-danger">Vous devez choisir un client avant d\'ajouter des lignes à la commande.</b>',
				icon: 'error',
				confirmButtonText: 'Ok',
				confirmButtonColor: 'red',
				timer: 2600,
				timerProgressBar: true,
			})
			return;
		}
		$.post(globals.serverAddress, {...globals.baseQuery, auto_sc: idScene, req: 'scenesController', action: 'fillModScene'}, function(data){
			if(data.ok=="ok") {
				// for (const [key, value] of Object.entries(data.scenes)) {
				// 	console.log(key, value)
				// }
				const orderProductsCont = thisForm.querySelector('[data-order-lines]')
				orderProductsCont.classList.add('list-group', 'list-group-numbered', 'my-2', 'px-2')
				// orderProductsCont.innerHTML = ''
				const timestamp = new Date().getTime()
				for (const [key, product] of Object.entries(data.scenesProducts)) {
					// Next is similar to jQuery $(orderProductsCont).append(`...`)
					const uniqCollapseId = timestamp+key
					const discountProduct = (clientDiscount) ? clientDiscount : discountProduct
					// const productClientPrice = (clientDiscount) ? Number(product.price_scp)*(100-Number(clientDiscount)) : product.price_scp
					const productOrLine = (product.product_scp!=0) ? 'product' : 'line'
					if(productOrLine=='product') {
						orderProductsCont.insertAdjacentHTML('beforeend',`
							<li class="list-group-item list-group-item-action list-group-item-primary d-flex justify-content-between align-items-start" data-order-line>
								<div class="container-fluid">
									<div class="row">
										<div class="col-lg-3 col-xl-3 text-break">
											<div class="fw-bold">
												<a href="/products/${product.product_scp}" target="_blank">${product.reference_p}</a>
											</div>
											<div class="form-group">
												<input class="form-control" name="name_ol[]" data-field="name_ol" type="text" placeholder="Nom du produit" value="${product.name_p}" data-mod-invoice-disabled/>
											</div>
											<b>${product.type_p}</b>
											<input type="hidden" name="auto_ol[]" data-field="auto_ol" value="0" data-order-id-line>
											<input type="hidden" name="product_ol[]" data-field="product_ol" value="${product.product_scp}" data-order-product>
										</div>
										<div class="col-lg-9 col-xl-9">
											<div class="row align-items-center g-2">
												<div class="form-group col-sm-6 col-lg-3">
													<label class="small mb-1">Quantité</label>
													<div class="input-group">
														<input class="form-control" name="howmany_ol[] data-field="howmany_ol" type="number" step="0.01" value="${product.quantity_scp}" placeholder="Quantité" onchange="App.calculateOderTotals(this);" onkeyup="App.calculateOderTotals(this);" data-order-quantities/>
													</div>
												</div>
												<div class="form-group col-sm-6 col-lg-3">
													<label class="small mb-1">P.U HT</label>
													<button type="button" class="btn btn-secondary btn-icon btn-xs" data-bs-toggle="popover" data-bs-title="P.U HT" data-bs-content="Prix Unitaire HT" tabindex="-1"><i class="fa fa-question"></i></button>
													<div class="input-group">
														<input class="form-control" name="price_ol[]" data-field="price_ol" type="number" step="0.01" value="${product.price_scp}" onchange="App.calculateOderTotals(this);" onkeyup="App.calculateOderTotals(this);" placeholder="Prix Unitaire HT" data-order-prices/>
														<span class="input-group-text"><i class="fa fa-euro"></i></span>
													</div>
												</div>
												<div class="form-group col-sm-6 col-lg-3">
													<label class="small mb-1">Unité</label>
													<div class="input-group">
														<!-- <span class="input-group-text"><i class="fa fa-ruler-combined"></i></span> -->
														<select class="form-select" name="unit_ol[]" id="unit_ol_${key}" data-field="unit_ol" value="${product.unit_p}" data-order-units>
															<option value="${product.unit_p}" data-multiplyUom="${product.multiply_uom}" selected>${product.name_uom}</option>
															${globals.optionsUnits}
														</select>
													</div>
												</div>
												<div class="form-group col-sm-6 col-lg-3">
													<label class="small mb-1">Total HT</label>
													<div class="input-group">
														<input class="form-control" name="amount_ol[]" data-field="amount_ol" type="number" step="0.01" value="" placeholder="Total HT" data-order-amounts readonly/>
														<span class="input-group-text"><i class="fa fa-euro"></i></span>
													</div>
													<input type="hidden" name="amount_tax_incl_ol[]" data-field="amount_tax_incl_ol" value="0" data-order-amounts-tax>
												</div>
											</div>
											<div class="row align-items-center g-2 collapse" id="details_${uniqCollapseId}">
												<div class="form-group col-sm-6 col-lg-3">
													<label class="small mb-1">TVA</label>
													<div class="input-group">
														<!-- <span class="input-group-text"><i class="fa fa-percent"></i></span> -->
														<select class="form-select" name="tax_ol[]" id="tax_ol_${key}" data-field="tax_ol" value="${product.tax_p}" onchange="App.calculateOderTotals(this);" onkeyup="App.calculateOderTotals(this);" data-order-taxes>
															<option value="${product.tax_p}" data-multiply="${product.multiply_tax}" selected>${product.name_tax}</option>
															${globals.optionsTaxes}
														</select>
													</div>
												</div>
												<div class="form-group col-sm-6 col-lg-3">
													<label class="small mb-1">C.R.U HT</label>
													<button type="button" class="btn btn-secondary btn-icon btn-xs" data-bs-toggle="popover" data-bs-title="C.R.U HT" data-bs-content="Coût de Revient Unitaire HT" tabindex="-1"><i class="fa fa-question"></i></button>
													<div class="input-group">
														<input class="form-control" name="cost_ol[]" data-field="cost_ol" type="number" step="0.01" min="0" value="${product.cost_p}" placeholder="Coût de Revient Unitaire HT" onchange="App.calculateOderTotals(this);" onkeyup="App.calculateOderTotals(this);" data-order-costs/>
														<span class="input-group-text"><i class="fa fa-euro"></i></span>
													</div>
												</div>
												<div class="form-group col-sm-6 col-lg-3">
													<label class="small mb-1""><i class="fa fa-tags me-1"></i>Remise</label>
													<span class="badge bg-primary rounded-pill ms-2 fs-6" data-display-range-value></span>
													<input type="range" class="form-range" name="discount_ol[]" data-field="discount_ol" value="${discountProduct}" min="0" max="100" step="1" onchange="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" oninput="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" data-order-discounts>
												</div>
												<div class="form-group col-sm-6 col-lg-3">
													<label class="small mb-1">Marge</label>
													<span class="badge bg-success rounded-pill ms-2 fs-6" data-display-margins-value></span>
													<div class="input-group">
														<input class="form-control" name="margin_ol[]" data-field="margin_ol" type="number" step="0.01" value="" placeholder="Marge" data-order-margins readonly/>
														<span class="input-group-text"><i class="fa fa-euro"></i></span>
													</div>
												</div>
												<div class="form-group col-12">
													<label class="small mb-1">Description</label>
													<div class="input-group">
														<span class="input-group-text"><i class="fa fa-2x fa-clipboard-list"></i></span>
														<textarea class="form-control form-control-lg" rows="2" name="desc_ol[]" placeholder="Description" value="${product.description_p}">${product.description_p}</textarea>
													</div>
												</div>
												<div class="form-group col-sm-6 col-lg-3 d-none" data-invoice-only>
													<label class="small mb-1""><i class="fa fa-circle-dollar-to-slot me-1"></i>Payé</label>
													<span class="badge bg-primary rounded-pill ms-2 fs-6" data-display-range-value></span>
													<input type="range" class="form-range" name="payed_ol[]" data-field="payed_ol" value="0" min="0" max="100" step="0.000001" onchange="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" oninput="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" data-order-payeds disabled>
												</div>
												<div class="form-group col-sm-6 col-lg-3 d-none" data-invoice-only>
													<label class="small mb-1">Montant TTC</label>
													<div class="input-group">
														<input class="form-control" name="payed_num_ol[]" data-field="payed_num_ol" type="number" step="0.01" min="0" value="" placeholder="Payé" data-order-payeds-num readonly/>
														<span class="input-group-text"><i class="fa fa-euro"></i></span>
													</div>
												</div>
												<div class="form-group col-sm-6 col-lg-3 d-none" data-invoice-only>
													<label class="small mb-1""><i class="fa fa-magnifying-glass-dollar me-1"></i>Facturer</label>
													<span class="badge bg-primary rounded-pill ms-2 fs-6" data-display-range-value></span>
													<input type="range" class="form-range" name="invoiced_ol[]" data-field="invoiced_ol" value="0" min="0" max="100" step="0.000001" onchange="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" oninput="App.displayRangeValue(this, ' %');App.calculateOderTotals(this);" data-order-invoiceds>
												</div>
												<div class="form-group col-sm-6 col-lg-3 d-none" data-invoice-only>
													<label class="small mb-1">Montant TTC</label>
													<div class="input-group">
														<input class="form-control" name="invoiced_num_ol[]" data-field="invoiced_num_ol" type="number" step="0.01" min="0" value="" placeholder="Facturé" data-order-invoiceds-num readonly/>
														<span class="input-group-text"><i class="fa fa-euro"></i></span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="d-flex flex-column g-1">
									<button class="badge btn btn-danger btn-icon btn-sm shadow" onclick="App.delLineFromOrder(this, event)">
										<i class="fa fa-2x fa-times"></i>
									</button>
									<button class="handle badge btn btn-dark btn-icon btn-sm shadow mt-1" title="Maintenez puis glisser à la position souhaitée.">
										<i class="fa fa-2x fa-arrows-up-down"></i>
									</button>
									<button class="badge btn btn-primary btn-icon btn-sm shadow mt-1" data-bs-toggle="collapse" data-bs-target="#details_${uniqCollapseId}" aria-expanded="false" aria-controls="details_${uniqCollapseId}" onclick="App.expendOrderLine(this, event)">
										<i class="fa fa-2x fa-angles-down"></i>
									</button>
								</div>
							</li>
						`);
					}
					else App.addLineToOrder(null, null, null, product.category_scp, '#'+myFormId)
				}
				App.orderAddGlobalSettings(thisForm)
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce Scénario !");
		}, "json").always(function(){
			App.calculateOderTotals()
			App.enablePopovers()
			if(invoicing) {
				thisForm.querySelectorAll('[data-invoice-only]').forEach((item, index) => {
					item.classList.remove('d-none')
				})
			}
			thisForm.querySelectorAll('.handle').forEach((item) => {
				item.addEventListener('click', (event) =>{
					event.preventDefault()
				})
			})
		});
	},
	orderAddScenesList: (thisBtn, event) => {
		event.preventDefault();
		const thisBtnHtml = $(thisBtn).html()
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const thisFormId = thisBtn.closest('form').getAttribute('id')
		$.post(globals.serverAddress, {...globals.baseQuery, formId: thisFormId, req: 'ordersController', action: 'orderAddScenesList'}, function(data){
			$('#orderAddScenesListCont').html(data.snippet);
		}, "json").done(function() {
			App.buildDataTables('#dataTableScenesList', 25, true, 0, 'asc', 'Liste des Produits', [0,1,2,4], [3], '', '', '', [0,1,2,3,4], true);
		}).always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			let myModal = new bootstrap.Modal(document.getElementById('orderAddScenesListModal'));
			myModal.show();
		});
	},
	expendOrderLine: (thisBtn, event) => {
		event.preventDefault();
		if(thisBtn.getAttribute('aria-expanded')=='true') thisBtn.innerHTML = '<i class="fa fa-2x fa-angles-up"></i>';
		else  thisBtn.innerHTML = '<i class="fa fa-2x fa-angles-down"></i>';
	},
	orderAddGlobalSettings: async (thisForm, payed_o, invoiced_o) => {
		const anyLines = thisForm.querySelectorAll('[data-order-product]') // or [data-order-line] to get howmany lines including cats & empty
		const isGlobals = thisForm.querySelector('[data-order-global-line]')
		const orderGlobalsCont = thisForm.querySelector('[data-order-globals]')
		const invoiceGlobalsCont = thisForm.querySelector('[data-order-invoices]')
		const myFormId = thisForm.getAttribute('id')
		const orderId = thisForm.querySelector('[data-order-id]').value
		const invoicing = (myFormId!='addQuotationForm' && orderId!=0) ? true : false // Invoicing fields don't show up at quotation creation
		const payed = (payed_o) ? payed_o : 0
		let invoiced = (invoiced_o) ? invoiced_o : 0
		const clientDiscount = thisForm.querySelector('[data-field="client_o"]').selectedOptions[0]?.dataset.catalogDiscount
		const globalDiscount = (clientDiscount) ? clientDiscount : 0
		if(anyLines && anyLines.length>1 && isGlobals==null && myFormId!='modInvoiceForm') {
			orderGlobalsCont.classList.add('list-group', 'my-2', 'px-2')
			orderGlobalsCont.innerHTML = `
				<li class="list-group-item list-group-item-action list-group-item-warning d-flex justify-content-between align-items-start" data-order-global-line>
					<div class="container-fluid">
						<div class="row">
							<div class="col-lg-3 col-xl-3 text-break">
								<div class="fw-bold">Réglages Globaux</div>
								Ajustable ligne par ligne avec <i class="fa fa-angles-down"></i>
							</div>
							<div class="col-lg-9 col-xl-9">
								<div class="row align-items-center g-2">
									<div class="form-group col-md-6 col-lg-4">
										<label class="small mb-1">TVA</label>
										<div class="input-group">
											<!-- <span class="input-group-text"><i class="fa fa-percent"></i></span> -->
											<select class="form-select" name="tax_global" id="tax_global" data-field="tax_ol" onchange="App.calculateOderTotals(this);" onkeyup="App.calculateOderTotals(this);" data-mod-invoice-disabled data-order-global-taxes>
												<option value="" data-multiply="1" selected></option>
												${globals.optionsTaxes}
											</select>
										</div>
									</div>
									<div class="form-group col-md-6 col-lg-8">
										<label class="small mb-1""><i class="fa fa-tags me-1"></i>Remise</label>
										<span class="badge bg-primary rounded-pill ms-2 fs-6" data-display-range-value></span>
										<input type="range" class="form-range" name="discount_global" data-field="discount_global" value="${globalDiscount}" min="0" max="100" step="1" onchange="App.displayRangeValue(this, ' %');App.calculateOderTotals(this, true, 0);" oninput="App.displayRangeValue(this, ' %', 0);App.calculateOderTotals(this, true);" data-mod-invoice-disabled data-order-global-discounts>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--
					<button class="badge btn btn-danger btn-icon btn-sm shadow" onclick="App.delLineFromOrder(this, event)">
						<i class="fa fa-2x fa-times"></i>
					</button>
					-->
				</li>
			`;
			App.displayRangeValue(orderGlobalsCont.querySelector('[data-order-global-discounts]'), ' %', 0)
		}
		else if(anyLines.length<=1 && isGlobals) orderGlobalsCont.innerHTML = ''; // Seems like someone removed all lines
		const isInvoices = thisForm.querySelector('[data-order-invoice-line]')
		const invoicingEdit = (myFormId=='addInvoiceForm') ? true : false // Invoicing field is not the same then Quotation => invoiced // Invoice => invoice
		const invoiceLabel = (invoicingEdit) ? ['Facturer', 'Montant à facturer'] : ['Facturé', 'Montant facturé']
		const invoice = (invoicingEdit) ? Number(100-invoiced).toFixed(6) : invoiced
		const maxInvoice = (invoicingEdit) ? Number(100-invoiced).toFixed(6) : 100
		const quotationOrModDisabled = (myFormId.includes('mod')) ? 'disabled' : ''
		if(invoicing && anyLines.length>0 && isInvoices==null) {
			invoiceGlobalsCont.classList.add('list-group', 'my-2', 'px-2')
			invoiceGlobalsCont.innerHTML = `
				<li class="list-group-item list-group-item-action list-group-item-success d-flex justify-content-between align-items-start" data-order-invoice-line>
					<div class="container-fluid">
						<div class="row align-items-center g-2">
							<div class="form-group col-sm-6 col-lg-3 d-none" data-invoice-only>
								<label class="mb-1""><i class="fa fa-circle-dollar-to-slot me-1"></i>Payé</label>
								<span class="badge bg-primary rounded-pill ms-2 fs-6" data-display-range-value></span>
								<input type="range" class="form-range" name="payed_o" data-field="payed_o" value="${payed}" min="0" max="100" step="0.000001" onchange="App.displayRangeValue(this, ' %');App.calculateOderTotals(this, false, true);" oninput="App.displayRangeValue(this, ' %');App.calculateOderTotals(this, false, true);" data-order-payed disabled>
							</div>
							<div class="form-group col-sm-6 col-lg-3 d-none" data-invoice-only>
								<label class="mb-1">Montant payé TTC</label>
								<div class="input-group">
									<input class="form-control" name="payed_num_o" data-field="payed_num_o" type="number" step="0.000001" min="0" value="" placeholder="Payé" data-order-payed-num readonly/>
									<span class="input-group-text"><i class="fa fa-euro"></i></span>
								</div>
							</div>
							<div class="form-group col-sm-6 col-lg-3 d-none" data-invoice-only>
								<label class="mb-1""><i class="fa fa-magnifying-glass-dollar me-1"></i>${invoiceLabel[0]}</label>
								<span class="badge bg-primary rounded-pill ms-2 fs-6" data-display-range-value></span>
								<input type="number" class="form-control" name="invoiced_o" data-field="invoiced_o" value="${invoice}" min="0" max="${maxInvoice}" step="0.000001" onchange="App.displayRangeValue(this, ' %');App.calculateOderTotals(this, false, true);" oninput="App.displayRangeValue(this, ' %');App.calculateOderTotals(this, false, true);" data-order-invoiced ${quotationOrModDisabled}>
								<!-- <input type="range" class="form-range" name="invoiced_o" data-field="invoiced_o" value="${invoice}" min="0" max="${maxInvoice}" step="0.01" onchange="App.displayRangeValue(this, ' %');App.calculateOderTotals(this, false, true);" oninput="App.displayRangeValue(this, ' %');App.calculateOderTotals(this, false, true);" data-order-invoiced ${quotationOrModDisabled}> -->
							</div>
							<div class="form-group col-sm-6 col-lg-3 d-none" data-invoice-only>
								<label class="mb-1">${invoiceLabel[1]} TTC</label>
								<div class="input-group">
									<input class="form-control" name="invoiced_num_o" data-field="invoiced_num_o" type="number" step="0.01" min="0" value="" placeholder="Facturé" onchange="App.calculateOderTotals(this, false, false, true);" oninput="App.calculateOderTotals(this, false, false, true);" data-order-invoiced-num ${quotationOrModDisabled}/>
									<span class="input-group-text"><i class="fa fa-euro"></i></span>
								</div>
							</div>
						</div>
					</div>
				</li>
			`;
			thisForm.querySelectorAll('[data-invoice-only]').forEach((item, index) => {
				item.classList.remove('d-none')
			})
		}
		else if(anyLines.length<1 && isInvoices) invoiceGlobalsCont.innerHTML = ''; // Seems like someone removed all lines
		else if(orderId==0) { // Orphan Invoice => We bill it all anyway...
			invoiceGlobalsCont.innerHTML = `
				<span class="d-none" data-display-range-value></span>
				<input type="hidden" name="payed_o" data-field="payed_o" value="${payed}" data-order-payed>
				<input type="hidden" name="payed_num_o" data-field="payed_num_o" value="0" data-order-payed-num>
				<span class="d-none" data-display-range-value></span>
				<input type="hidden" name="invoiced_o" data-field="invoiced_o" value="${invoice}" data-order-invoiced>
				<input type="hidden" name="invoiced_num_o" data-field="invoiced_num_o" value="0" data-order-invoiced-num>
			`;
		}
	},
	calculateOderTotals: (thisBtn, fromGlobalRanges = false, fromGlobalInvoice = false, fromGlobalInvoiceNum = false) => {
		const currentFormId = (globals.currentFormId.includes('#')) ? globals.currentFormId.replace('#','') : globals.currentFormId
		const myForm = (thisBtn) ? thisBtn.closest('form') : document.getElementById(currentFormId)
		if(thisBtn?.max) { // Limit Number inputs to their max if set...
			if(Number(thisBtn?.value)>Number(thisBtn?.max)) {
				thisBtn.value = thisBtn.max;
				App.displayRangeValue(thisBtn, ' %');
			}
		}
		const quantityInputs = myForm.querySelectorAll('[data-order-quantities]')
		const priceInputs = myForm.querySelectorAll('[data-order-prices]')
		const taxInputs = myForm.querySelectorAll('[data-order-taxes]')
		const costInputs = myForm.querySelectorAll('[data-order-costs]')
		const discountInputs = myForm.querySelectorAll('[data-order-discounts]')
		const marginInputs = myForm.querySelectorAll('[data-order-margins]')
		const marginBadges = myForm.querySelectorAll('[data-display-margins-value]')
		const amountInputs = myForm.querySelectorAll('[data-order-amounts]')
		const amountTaxInputs = myForm.querySelectorAll('[data-order-amounts-tax]')
		const payedInputs = myForm.querySelectorAll('[data-order-payeds]')
		const invoicedInputs = myForm.querySelectorAll('[data-order-invoiceds]')
		const payedNumInputs = myForm.querySelectorAll('[data-order-payeds-num]')
		const invoicedNumInputs = myForm.querySelectorAll('[data-order-invoiceds-num]')
		const discountGlobalInput = myForm.querySelector('[data-order-global-discounts]')
		const taxGlobalInput = myForm.querySelector('[data-order-global-taxes]')
		const discountGlobal = (discountGlobalInput) ? discountGlobalInput.value : 0;
		const taxGlobal = (taxGlobalInput) ? taxGlobalInput.value : '';
		const payedGlobalInput = myForm.querySelector('[data-order-payed]') // In Globals so not always there
		const invoiceGlobalInput = myForm.querySelector('[data-order-invoiced]') // In Globals so not always there
		const payedGlobal = (payedGlobalInput) ? payedGlobalInput.value : 0;
		const invoicedGlobal = (invoiceGlobalInput) ? invoiceGlobalInput.value : 0;
		// SecureBoat Custom !!!
		const invoicedGlobalNumInput = myForm.querySelector('[data-order-invoiced-num]') // In Invoices so not always there
		const invoicedGlobalNum = (invoicedGlobalNumInput) ? invoicedGlobalNumInput.value : 0;
		if(fromGlobalRanges) {
			discountInputs.forEach((item, index) => {
				item.value = discountGlobal // Warning changes discounts in invoice mode => fromGlobalInvoice
				App.displayRangeValue(item, ' %') // Allows to show discount values badges in modForms
				payedInputs[index].value = payedGlobal
				App.displayRangeValue(payedInputs[index], ' %')
				invoicedInputs[index].value = invoicedGlobal
				App.displayRangeValue(invoicedInputs[index], ' %', 2)
			})
		}
		if(fromGlobalInvoice) {
			invoicedInputs.forEach((item, index) => {
				item.value = invoicedGlobal
				App.displayRangeValue(item, ' %', 2) // Allows to show discount values badges in modForms
				payedInputs[index].value = payedGlobal
				App.displayRangeValue(payedInputs[index], ' %')
			})
		}
		if(fromGlobalInvoiceNum) {
			const total_tax_incl_i = myForm.querySelector('[data-total-tax-incl]').value
			const invoicedGlobalPercent = invoicedGlobalNum*100/total_tax_incl_i
			// invoiceGlobalInput.value = invoicedGlobalPercent.toFixed(6); // Done below..
			invoicedInputs.forEach((item, index) => {
				item.value = invoicedGlobalPercent.toFixed(6)
				App.displayRangeValue(item, ' %', 2) // Allows to show discount values badges in modForms
				payedInputs[index].value = payedGlobal
				App.displayRangeValue(payedInputs[index], ' %')
			})
		}
		if(taxGlobal!=0) {
			taxInputs.forEach((item, index) => {
				item.value = taxGlobal
			})
		}
		let total = 0
		let totalTaxIncl = 0
		let totalMargin = 0
		let totalDiscount = 0
		let totalPayed = 0
		let totalInvoiced = 0
		let totalNoDisc = 0
		quantityInputs.forEach((item, index) => {
			const quantity = item.value
			const price = priceInputs[index].value
			const tax = taxInputs[index].selectedOptions[0].dataset.multiply
			const cost = costInputs[index].value
			const discount = discountInputs[index].value
			const discountMultiplier = 1-discount/100
			const totalLineNoDisc = quantity * price
			const totalLine = quantity * price * discountMultiplier
			const totalTaxInclLine = totalLine * tax
			const costLine = (quantity>=0) ? quantity * cost : (0-quantity) * cost // We must do this to keep costLine>=0 even if quantity is negative
			const marginLine = totalLine-costLine
			let marginLineRate = (marginLine>=0) ? marginLine*100/totalLine : (0-marginLine)*100/totalLine
			if(totalLine==0) marginLineRate = 100;
			const discountLine = quantity * price * discount/100
			amountInputs[index].value = totalLine.toFixed(2)
			amountTaxInputs[index].value = totalTaxInclLine.toFixed(2)
			marginInputs[index].value = marginLine.toFixed(2)
			marginBadges[index].innerHTML = marginLineRate.toFixed(2) + '%'
			if(marginLineRate<App.settings.siteSettings?.alert_marge) $(marginBadges[index]).removeClass(['bg-success', 'bg-warning']).addClass('bg-danger')
			else if(marginLineRate>=App.settings.siteSettings?.alert_marge && marginLineRate<App.settings.siteSettings?.warning_marge) $(marginBadges[index]).removeClass(['bg-success', 'bg-danger']).addClass('bg-warning')
			else $(marginBadges[index]).removeClass(['bg-warning', 'bg-danger']).addClass('bg-success')
			const payedLine = totalTaxInclLine * payedInputs[index].value/100
			payedNumInputs[index].value = payedLine.toFixed(2)
			const invoicedLine = totalTaxInclLine * invoicedInputs[index].value/100
			invoicedNumInputs[index].value = invoicedLine.toFixed(2)
			total += totalLine
			totalTaxIncl += totalTaxInclLine
			totalMargin += marginLine
			totalDiscount += discountLine
			totalPayed += payedLine
			totalInvoiced += invoicedLine
			totalNoDisc += totalLineNoDisc
			console.warn(index, quantity, price, tax, totalLine, totalTaxInclLine, discountLine, marginLine, marginLineRate)
		})
		let totalTax = totalTaxIncl-total
		myForm.querySelector('[data-total]').value = total.toFixed(2)
		myForm.querySelector('[data-total-tax]').value = totalTax.toFixed(2)
		myForm.querySelector('[data-total-tax-incl]').value = totalTaxIncl.toFixed(2)
		myForm.querySelector('[data-total-discount]').value = totalDiscount.toFixed(2)
		myForm.querySelector('[data-total-margin]').value = totalMargin.toFixed(2)
		const payedGlobalNumInput = myForm.querySelector('[data-order-payed-num]') // In Invoices so not always there
		if(payedGlobalNumInput) myForm.querySelector('[data-order-payed-num]').value = totalPayed.toFixed(2);
		// SecureBoat Custom !!!
		// const invoicedGlobalNumInput = myForm.querySelector('[data-order-invoiced-num]') // In Invoices so not always there
		if(invoicedGlobalNumInput && !fromGlobalInvoiceNum) myForm.querySelector('[data-order-invoiced-num]').value = totalInvoiced.toFixed(2);
		const totalDiscountPercentage = totalDiscount*100/totalNoDisc
		const totalInvoicedPercentage = totalInvoiced*100/totalTaxIncl
		if(discountGlobalInput) {
			discountGlobalInput.value = totalDiscountPercentage.toFixed(2);
			App.displayRangeValue(discountGlobalInput, ' %')
		}
		if(invoiceGlobalInput) {
			invoiceGlobalInput.value = totalInvoicedPercentage.toFixed(6);
			App.displayRangeValue(invoiceGlobalInput, ' %', 2)
		}
		const marginTotalRate = (totalMargin>=0) ? totalMargin*100/total : (0-totalMargin)*100/total
		const marginTotalBadge = myForm.querySelector('[data-display-margin-total-value]')
		marginTotalBadge.innerHTML = marginTotalRate.toFixed(2) + '%'
		if(marginTotalRate<10) $(marginTotalBadge).removeClass(['bg-success', 'bg-warning']).addClass('bg-danger')
		else if(marginTotalRate>=10 && marginTotalRate<20) $(marginTotalBadge).removeClass(['bg-success', 'bg-danger']).addClass('bg-warning')
		else $(marginTotalBadge).removeClass(['bg-warning', 'bg-danger']).addClass('bg-success')
	},
	modQuotation: function(myFormDiv, fromModal = false, popSomething = false, what2Pop = null) {
		if(document.querySelector(myFormDiv+' [data-field="total_o"]').value<0) {
			Swal.fire({
				title: 'Echec',
				html: '<h4>Vous ne pouvez pas créer un devis dont le total est inférieur à zéro !</h4>',
				icon: 'error',
				confirmButtonText: "Ok",
				confirmButtonColor: 'red',
				timer: 2600,
				timerProgressBar: true,
			})
			return;
		}
		const anyLines = document.querySelectorAll(myFormDiv+' [data-order-product]')
		if(anyLines && anyLines.length>0) {
			$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
			$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
			let returns;
			let request = new FormData($(myFormDiv)[0]);
			request.append("req", "ordersController");
			request.append("action", "modQuotation");
			request.append("id", globals.id);
			request.append("pwd", globals.pwd);
			request.append("type", globals.type);
			request.append("site", $(myFormDiv+' [data-field="site_o"]').val());
			request.append("type_doc", "quotation");
			$.ajax({
				url: globals.serverAddress,
				type: 'POST',
				data: request,
				dataType: "json",
				cache: false,
				contentType: false,
				processData: false
			}).done(function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Le Devis a bien été mis à jour.</b></div>';
					Swal.fire({
						title: 'Action réussie',
						html: returns,
						icon: 'success',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
						timer: 2600,
						timerProgressBar: true,
					})
					if(fromModal) {
						App.setQuotationsListPage();
						setTimeout(function(){
							App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
							App.emptyOrderForms(myFormDiv);
						}, 2600);
					}
					else {
						document.querySelector('a[data-clients-link]').setAttribute('href', '/clients/'+data.sentData.client_o)
						document.querySelector('a[data-docs-link]').setAttribute('href', data.pdfLink)
					}
					if(popSomething) window.location.href = `${what2Pop}&idFields=${data.sentData.client_o},${data.auto_o}` // what2Pop=`/planning?goto=addPlanningModal&fields=client_pl,order_pl`
					else {
						setTimeout(function(){
							Swal.fire({
								title: "Envoyer ce devis ?",
								html: "Voulez-vous envoyer ce devis ?<br>Les adresses mails du client seront définies en destinataires par défaut et les adresses de ses contacts en copie.<br>Vous pourrez modifier tout cela...",
								icon: "question",
								confirmButtonText: "Envoyer",
								confirmButtonColor: '#1da1f5',
								showDenyButton: true,
								denyButtonText: "Pas maintenant"
							}).then(response => {
								if(response.isConfirmed && !response.isDenied && !response.isDismissed) {
									App.popSendMail('quotation', data.auto_o, data.pdfPath+data.pdfName, data.clientMails, data.contactMails, data.sentData.label_quotation+' #'+data.sentData.name_quotation, {subject: 'subject_m_quotation', body: 'body_m_quotation'}, data.sentData.client_o)
								}
							})
						}, 2600);
					}
				}
				else returns = '<div class="alert alert-danger" role="alert"><b>Le Devis n\'a pas été modifié suite à un problème technique.</b></div>';
			}, "json").always(function(){
				$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
				$(myFormDiv+' [data-successfail]').html(returns);
				$(myFormDiv+' :disabled').attr("disabled", true);
			});
		}
		else {
			Swal.fire({
				title: 'Echec',
				html: '<h4>Veuillez ajouter des produits ou services à votre devis !</h4>',
				icon: 'error',
				confirmButtonText: "Ok",
				confirmButtonColor: 'red',
				timer: 2600,
				timerProgressBar: true,
			})
		}
	},
	addQuotation: function(myFormDiv) {
		if(globals.site==0) {
			App.popCompanyChoiceModal(`App.addQuotation('${myFormDiv}');`);
			return;
		}
		this.closeModal('companyChoiceModal') // In case we get back from popCompanyChoiceModal
		if(document.querySelector(myFormDiv+' [data-field="total_o"]').value<0) {
			Swal.fire({
				title: 'Echec',
				html: '<h4>Vous ne pouvez pas créer un devis dont le total est inférieur à zéro !</h4>',
				icon: 'error',
				confirmButtonText: "Ok",
				confirmButtonColor: 'red',
				timer: 2600,
				timerProgressBar: true,
			})
			return;
		}
		const anyLines = document.querySelectorAll(myFormDiv+' [data-order-product]')
		if(anyLines && anyLines.length>0) {
			$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
			$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
			let returns;
			let request = new FormData($(myFormDiv)[0]);
			request.append("req", "ordersController");
			request.append("action", "addQuotation");
			request.append("id", globals.id);
			request.append("pwd", globals.pwd);
			request.append("type", globals.type);
			request.append("site", globals.site);
			request.append("type_doc", "quotation");
			$.ajax({
				url: globals.serverAddress,
				type: 'POST',
				data: request,
				dataType: "json",
				cache: false,
				contentType: false,
				processData: false
			}).done(function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Ce devis a bien été créé.</b></div>';
					Swal.fire({
						title: 'Action réussie',
						html: returns,
						icon: 'success',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
						timer: 2600,
						timerProgressBar: true,
					})
					// In case We come from popFormFromUrlParam We have to clean URL before refreshing
					const goTo = App.urlParam('goto', document.URL);
					if(goTo) window.history.pushState({}, document.title, window.location.href.split("?")[0]);
					App.setQuotationsListPage();
					setTimeout(function(){
						App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
						App.emptyOrderForms(myFormDiv);
						Swal.fire({
							title: "Envoyer ce devis ?",
							html: "Voulez-vous envoyer ce devis ?<br>Les adresses mails du client seront définies en destinataires par défaut et les adresses de ses contacts en copie.<br>Vous pourrez modifier tout cela...",
							icon: "question",
							confirmButtonText: "Envoyer",
							confirmButtonColor: '#1da1f5',
							showDenyButton: true,
							denyButtonText: "Pas maintenant"
						}).then(response => {
							if(response.isConfirmed && !response.isDenied && !response.isDismissed) {
								App.popSendMail('quotation', data.auto_o, data.pdfPath+data.pdfName, data.clientMails, data.contactMails, data.sentData.label_quotation+' #'+data.name_quotation, {subject: 'subject_m_quotation', body: 'body_m_quotation'}, data.sentData.client_o)
							}
						})
					}, 2600);
				}
				else returns = '<div class="alert alert-danger" role="alert"><b>Le devis n\'a pas été créé suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
				$(myFormDiv+' [data-successfail]').html(returns);
				$(myFormDiv+' :disabled').attr("disabled", true);
			});
		}
		else {
			Swal.fire({
				title: 'Echec',
				html: '<h4>Veuillez ajouter des produits ou services à votre devis !</h4>',
				icon: 'error',
				confirmButtonText: "Ok",
				confirmButtonColor: 'red',
				timer: 2600,
				timerProgressBar: true,
			})
		}
	},
	delQuotation: function(myFormDiv, event, fromModal = false) {
		event.preventDefault(); // prevents mod form submission !
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce devis ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir supprimer ce devis ?",
			html: "Cette action n'est réversible que sur intervention de SNS Solutions.",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const thisBtnHtml = $(myFormDiv+' [name=deleter]').html();
				$(myFormDiv+' [name=deleter]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i> Chargement');
				const delId = $(myFormDiv+' #auto_o').val();
				const req = "ordersController";
				const action = "delQuotation";
				let query = "auto_o=" + delId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
				let returns = "";
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						returns = '<div class="alert alert-success" role="alert"><b>Ce devis a bien été supprimé.</b></div>';
						if(fromModal) {
							Swal.fire({
								title: 'Action réussie',
								html: returns,
								icon: 'success',
								confirmButtonText: 'Ok',
								confirmButtonColor: '#1da1f5',
								timer: 2600,
								timerProgressBar: true,
							})
							App.setQuotationsListPage();
							setTimeout(function(){
								App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
								App.emptyOrderForms(myFormDiv);
							}, 2600);
						}
						else {
							setTimeout(function(){
								document.location.href = '/sales/quotations/';
							}, 2600);
						}
					}
					else returns = '<div class="alert alert-danger" role="alert"><b>Ce devis n\'a pas été supprimé suite à un problème technique.</b></div>';
				}, "json").always(function(data){
					$(myFormDiv+' [name=deleter]').attr("disabled", false).html(thisBtnHtml);
					$(myFormDiv+' [data-successfail]').html(returns);
				});
			}
		});
	},
	printOrderPreview: function(myFormDiv, thisBtn , event) {
		event.preventDefault();
		if(globals.site==0) {
			App.popCompanyChoiceModal(`App.printOrderPreview('${myFormDiv}');`);
			return;
		}
		this.closeModal('companyChoiceModal') // In case we get back from popCompanyChoiceModal
		const anyLines = document.querySelectorAll(myFormDiv+' [data-order-product]')
		if(anyLines && anyLines.length>0) {
			$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Chargement');
			$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
			let returns;
			const typeDoc = (myFormDiv.includes('Quotation')) ? 'quotation' : 'invoice';
			let request = new FormData($(myFormDiv)[0]);
			request.append("req", "ordersController");
			request.append("action", "printOrderPreview");
			request.append("id", globals.id);
			request.append("pwd", globals.pwd);
			request.append("type", globals.type);
			request.append("site", globals.site);
			request.append("type_doc", typeDoc);
			$.ajax({
				url: globals.serverAddress,
				type: 'POST',
				data: request,
				dataType: "json",
				cache: false,
				contentType: false,
				processData: false
			}).done(function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Cet aperçu a bien été créé.</b><hr><a href="'+data.pdfLink+'" target="_blank" class="btn btn-danger btn-lg"><i class="fa fa-2x fa-file-pdf me-1"></i>Consulter le fichier</a></div>';
					Swal.fire({
						title: 'Action réussie',
						html: returns,
						icon: 'success',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
					})
				}
				else returns = '<div class="alert alert-danger" role="alert"><b>L\'aperçu n\'a pas été créé suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(thisBtn).attr("disabled", false).html('<i class="fa fa-file-pdf me-1"></i>Aperçu');
				// $(myFormDiv+' [data-successfail]').html(returns);
				$(myFormDiv+' :disabled').attr("disabled", true);
			});
		}
		else alert("Veuillez ajouter des produits ou services à votre devis !")
	},
	addPlanningFromQuotation: function(thisBtn, event) {
		event.preventDefault()
		const thisForm = thisBtn.closest('form')
		const myFormDiv = '#'+thisForm.getAttribute('id')
		const validQuotation = thisForm.querySelector('[data-field="valid_o"]') // Making sure we set & record valid_o=1
		validQuotation.value = 1
		App.setNowDate(validQuotation, 'date_confirmation_o')
		this.modQuotation(myFormDiv, false, true, '/planning?goto=addPlanningModal&fields=client_pl,order_pl')
	},
	addInvoiceFromQuotation: function(thisBtn, event) {
		event.preventDefault()
		const thisForm = thisBtn.closest('form')
		const myFormDiv = '#'+thisForm.getAttribute('id')
		const validQuotation = thisForm.querySelector('[data-field="valid_o"]') // Making sure we set & record valid_o=1
		validQuotation.value = 1
		App.setNowDate(validQuotation, 'date_confirmation_o')
		this.modQuotation(myFormDiv, false, true, '/sales/invoices?goto=addInvoiceModal&fields=client_o,order_i')
	},
	setInvoicesListPage: async function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#addInvoiceForm', '#modInvoiceForm'], 'ordersController', ['client_o', 'order_i', 'products_o', 'scenes_o', 'user_o', 'site_o']);
		$('#addInvoiceForm [data-field=site_o]').val(globals.site).attr('data-default', globals.site)
		$('#addInvoiceForm [data-field=user_o]').val(globals.id).attr('data-default', globals.id)
		$('#addInvoiceForm [data-field=payment_method_i]').val(this.settings.siteSettings?.payment_method_ss).attr('data-default', this.settings.siteSettings?.payment_method_ss)
		$('#addInvoiceForm [data-field=payment_conditions_i]').val(this.settings.siteSettings?.payment_conditions_ss).attr('data-default', this.settings.siteSettings?.payment_conditions_ss)
		const deafaultDateStart = App.makeNiceDateTime(new Date()).split(' ')[0]
		$('#addInvoiceForm [data-field=date_start_i]').val(deafaultDateStart).attr('data-default', deafaultDateStart)
		const paymentDelay = document.querySelector('#addInvoiceForm [data-field=payment_conditions_i]').selectedOptions[0].dataset.dateAdd
		const deafaultDateEnd = App.getFutureDate(paymentDelay).split(' ')[0]
		$('#addInvoiceForm [data-field=date_end_i]').val(deafaultDateEnd).attr('data-default', deafaultDateEnd)
		// if(this.settings.siteSettings) $('#addInvoiceForm [data-field=date_quotation_end]').val(this.getFutureDate(this.settings.siteSettings?.date_valid_ss))
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'ordersController', action: 'getInvoicesList'}, function(data){
			if(data.ok=='ok') {
				$("[data-table-view]").html(data.snippet);
				$("[data-kanban-view]").html(data.snippetPhone);
				App.buildDataFilterSearch();
			}
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("[data-table-view]").html(noOne);
				$("[data-kanban-view]").html(noOne);
			}
		}, "json").done(function(data) {
			const tableFilter = App.urlParam('filter', document.URL);
			const tableFilterValue = App.urlParam('val', document.URL);
			if(tableFilter) App.buildDataTables('#dataTableInvoicesList', 25, true, 0, 'desc', 'Liste des Factures', [0,2,3,4,5,6], [1,7,8,9], tableFilter, 'contains', tableFilterValue, [0,1,2,3,4,5,6,7,8,9], true);
			else App.buildDataTables('#dataTableInvoicesList', 25, true, 0, 'desc', 'Liste des Factures', [0,2,3,4,5,6], [1,7,8,9], '', '', '', [0,1,2,3,4,5,6,7,8,9], true);
		}).always(function() {
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
		});
		const orderLinesCont = document.querySelector('#addInvoiceForm [data-order-lines]')
		new Sortable(orderLinesCont, { // No Drag & Drop for addInvoiceForm.
			animation: 150,
			handle: '.handle',
			swapThreshold: 0.9, // 0 to 1 => width of the swap zone on elements
			ghostClass: 'blue-background-class'
		})
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		const date_end_i = document.querySelectorAll('[data-field="date_end_i"]');
		if(date_end_i.length>0) {
			date_end_i.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		const date_start_i = document.querySelectorAll('[data-field="date_start_i"]');
		if(date_start_i.length>0) {
			date_start_i.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		const cron_date_end_o = document.querySelectorAll('[data-field="cron_date_end_o"]');
		if(cron_date_end_o.length>0) {
			cron_date_end_o.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		this.popFormFromUrlParam()
		// Building meta tags list...
		$('head title').text('Factures - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Factures');
	},
	setInvoicePage: async function(invoiceId) {
		// First We get client's select box then we fill form with values
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#modInvoiceForm'], 'ordersController', ['client_o', 'order_i', 'products_o', 'scenes_o', 'user_o', 'site_o']);
		const myFormDiv = '#modInvoiceForm';
		App.emptyOrderForms(myFormDiv);
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_i: invoiceId, req: 'ordersController', action: 'fillModOrder'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.orders)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				for (const [key, value] of Object.entries(data.invoices)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				data.orderLines.forEach( async (item, index) => {
					console.log(index, item.auto_ol, item.name_ol, item.desc_ol)
					if(item.product_ol==0) await App.addLineToOrder(null, null, item.auto_ol, item.desc_ol, myFormDiv) // Categories & empty lines
					else await App.addProductToOrder(null, null, item.product_ol, item.reference_p, item.name_ol, item.type_p, item.howmany_ol, item.price_ol, item.cost_ol, item.discount_ol, item.desc_ol, item.unit_ol+';'+item.name_uom+';'+item.multiply_uom, item.tax_ol+';'+item.name_tax+';'+item.multiply_tax, item.payed_ol, item.invoiced_il, data.orders.payed_o, data.orders.invoiced_o, item.auto_ol, myFormDiv)
				})
				document.querySelectorAll(`${myFormDiv} .handle`).forEach((item) => {
					item.addEventListener('click', (event) =>{
						event.preventDefault()
					})
				})
				document.querySelector('#modInvoiceFormHeader').innerHTML = '#'+data.orders.name_invoice
				document.querySelectorAll('a[data-clients-link]').forEach((item) => {
					item.setAttribute('href', '/clients/'+data.orders.client_o)
				})
				document.querySelector('a[data-docs-link]').setAttribute('href', globals.serverBaseUrl+'docs/invoice/'+data.invoices.name_od)
				if(data.orders.name_quotation!='') $('a[data-quotations-link]').attr('href', `/sales/quotations/${data.orders.auto_o}`).attr('title', 'Voir le Devis').removeClass('d-none');
				else document.querySelector('a[data-quotations-link]').classList.add('d-none');
				if(data.orders.active_pl==1) $('a[data-planning-link]').attr('href', `/events/${data.orders.auto_pl}`).attr('title', 'Voir l\'intervention Plannifiée').removeClass('d-none').html('<i class="fa fa-2x fa-calendar-check"></i>');
				else if(data.orders.valid_o==1) $('a[data-planning-link]').attr('href', `/planning?goto=addPlanningModal&fields=client_pl,order_pl&idFields=${data.orders.client_o},${data.orders.auto_o}`).removeClass('d-none');
				else document.querySelector('a[data-planning-link]').classList.add('d-none');
				if(data.orders.name_delivery!='') $('a[data-delivery-link]').attr('href', `/deliveries/${data.orders.auto_o}`).attr('title', 'Voir le bon de livraison').removeClass('d-none').html('<i class="fa fa-2x fa-calendar-check"></i>');
				else if(data.orders.valid_o==1) $('a[data-delivery-link]').attr('href', `/deliveries?goto=addDeliveryModal&fields=auto_o,client_o,project_o&idFields=${data.orders.auto_o},${data.orders.client_o},${data.orders.project_o}`).removeClass('d-none');
				else document.querySelector('a[data-delivery-link]').classList.add('d-none');
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
				if(data.invoices.refund_i==1) $('[name="senderPayBackInvoice"]').attr('disabled', true); // This is PayBack Invoice
				else $('[name="senderPayBackInvoice"]').attr('disabled', false);
				if(data.orders.cron_o!='') document.getElementById('modCronCollapse').classList.add('show');
				else document.getElementById('modCronCollapse').classList.remove('show');
				/*
				if(data.orders.payed_o>0) {
					$(myFormDiv+' [data-successfail]').html('<div class="alert alert-danger" role="alert"><b>La facture a été payée (au moins en partie).\r\nCelle-ci n\'est donc plus éditable.</b></div>')
					$(myFormDiv+' [type=submit]').attr('disabled', true);
				}
				*/
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé cette Facture !");
		}, "json").always(function(){
			$(myFormDiv+' [data-field="products_o"]').val(0); // Empty products choice field
			$(myFormDiv+' [data-field="scenes_o"]').val(0); // Empty scenes choice field
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		const cron_date_end_o = document.querySelectorAll('[data-field="cron_date_end_o"]');
		if(cron_date_end_o.length>0) {
			cron_date_end_o.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		/*
		const date_end_i = document.querySelectorAll('[data-field="date_end_i"]');
		if(date_end_i.length>0) {
			date_end_i.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		const date_start_i = document.querySelectorAll('[data-field="date_start_i"]');
		if(date_start_i.length>0) {
			date_start_i.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		*/
		// Building meta tags list...
		$('head title').text('Factures - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Factures');
	},
	fillModInvoice: async function(auto_i, thisBtn, myFormDiv, myFormModal) {
		App.emptyOrderForms(myFormDiv);
		// const myFormId = (myFormDiv.includes('#')) ? myFormDiv.replace('#','') : myFormDiv;
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_i: auto_i, req: 'ordersController', action: 'fillModOrder'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.orders)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				for (const [key, value] of Object.entries(data.invoices)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				data.orderLines.forEach( async (item, index) => {
					console.log(index, item.auto_ol, item.name_ol, item.desc_ol)
					if(item.product_ol==0) await App.addLineToOrder(null, null, item.auto_ol, item.desc_ol, myFormDiv) // Categories & empty lines
					else await App.addProductToOrder(null, null, item.product_ol, item.reference_p, item.name_ol, item.type_p, item.howmany_ol, item.price_ol, item.cost_ol, item.discount_ol, item.desc_ol, item.unit_ol+';'+item.name_uom+';'+item.multiply_uom, item.tax_ol+';'+item.name_tax+';'+item.multiply_tax, item.payed_ol, item.invoiced_il, data.orders.payed_o, data.orders.invoiced_o, item.auto_ol, myFormDiv)
				})
				document.querySelectorAll(`${myFormDiv} .handle`).forEach((item) => {
					item.addEventListener('click', (event) =>{
						event.preventDefault()
					})
				})
				document.querySelector(myFormDiv+'Header').innerHTML = '#'+data.invoices.name_i
				document.querySelector(myFormDiv+' a[data-clients-link]').setAttribute('href', '/clients/'+data.orders.client_o)
				document.querySelector(myFormDiv+' a[data-quotations-link]').setAttribute('href', '/sales/quotations/'+data.orders.auto_o)
				// document.querySelector(myFormDiv+' a[data-projects-link]').setAttribute('href', '/sales/projects/'+data.orders.project_o)
				document.querySelector('a[data-docs-link]').setAttribute('href', globals.serverBaseUrl+'docs/invoice/'+data.invoices.name_od)
				if(data.invoices.refund_i==1) $('[name="senderPayBackInvoice"]').attr('disabled', true); // This is PayBack Invoice
				else $('[name="senderPayBackInvoice"]').attr('disabled', false);
				if(data.orders.cron_o!='') document.getElementById('modCronCollapse').classList.add('show');
				else document.getElementById('modCronCollapse').classList.remove('show');
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
				else {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.remove('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = '';
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé cette Facture !");
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			$(myFormDiv+' [data-mod-invoice-disabled]').attr('disabled')
			$(myFormDiv+' [data-mod-invoice-none]').addClass('d-none')
			$(myFormDiv+' [data-field="products_o"]').val(0); // Empty products choice field
			$(myFormDiv+' [data-field="scenes_o"]').val(0); // Empty scenes choice field
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
		});
	},
	updateInvoiceForm: async (thisBtn) => {
		const thisForm = thisBtn.closest('form')
		const orderId = thisBtn.value
		const myFormDiv = '#'+thisForm.getAttribute('id')
		App.emptyOrderForms(myFormDiv, false)
		$.post(globals.serverAddress, {...globals.baseQuery, auto_o: orderId, req: 'ordersController', action: 'fillModOrder'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.orders)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				for (const [key, value] of Object.entries(data.invoices)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				// document.querySelector(`${myFormDiv} [data-field=order_i]`).value = orderId // The value may have just been blanked right above
				data.orderLines.forEach( async (item, index) => {
					console.log(index, item.auto_ol, item.name_ol, item.desc_ol)
					if(item.product_ol==0) await App.addLineToOrder(null, null, item.auto_ol, item.desc_ol, myFormDiv) // Categories & empty lines
					else await App.addProductToOrder(null, null, item.product_ol, item.reference_p, item.name_ol, item.type_p, item.howmany_ol, item.price_ol, item.cost_ol, item.discount_ol, item.desc_ol, item.unit_ol+';'+item.name_uom+';'+item.multiply_uom, item.tax_ol+';'+item.name_tax+';'+item.multiply_tax, item.payed_ol, item.invoiced_ol, data.orders.payed_o, data.orders.invoiced_o, item.auto_ol, myFormDiv)
				})
				thisForm.querySelectorAll('.handle').forEach((item) => {
					item.addEventListener('click', (event) =>{
						event.preventDefault()
					})
				})
				document.querySelector('#addInvoiceFormHeader').innerHTML = 'sur le devis #'+data.orders.name_quotation
				document.querySelector(myFormDiv+' a[data-clients-link]').setAttribute('href', '/clients/'+data.orders.client_o)
				document.querySelector(myFormDiv+' a[data-quotations-link]').setAttribute('href', '/sales/quotations/'+data.orders.auto_o)
				// We have to check if order has already been invoiced...
				if(data.orders.invoiced_o==100) {
					Swal.fire({
						title: 'Commande déjà facturée !',
						html: 'Cette commande a déjà été entièrement facturée.',
						icon: 'error',
						confirmButtonText: "Ok",
						confirmButtonColor: 'red',
						timer: 2600,
						timerProgressBar: true,
					}).then(() => {
						App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
						App.emptyOrderForms(myFormDiv);
					})
				}
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
				else {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.remove('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = '';
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé cette Facture !");
		}, "json").always(function(){
			$(myFormDiv+' [data-field="products_o"]').val(0); // Empty products choice field
			$(myFormDiv+' [data-field="scenes_o"]').val(0); // Empty scenes choice field
		});
	},
	modInvoice: function(myFormDiv, fromModal = false, popPlanning = false) {
		const anyLines = document.querySelectorAll(myFormDiv+' [data-order-product]')
		if(anyLines && anyLines.length>0) {
			$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
			$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
			let returns;
			let request = new FormData($(myFormDiv)[0]);
			request.append("req", "ordersController");
			request.append("action", "modInvoice");
			request.append("id", globals.id);
			request.append("pwd", globals.pwd);
			request.append("type", globals.type);
			request.append("site", $(myFormDiv+' [data-field="site_o"]').val());
			request.append("type_doc", "invoice");
			$.ajax({
				url: globals.serverAddress,
				type: 'POST',
				data: request,
				dataType: "json",
				cache: false,
				contentType: false,
				processData: false
			}).done(function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>La Facture a bien été mise à jour.</b></div>';
					Swal.fire({
						title: 'Action réussie',
						html: returns,
						icon: 'success',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
						timer: 2600,
						timerProgressBar: true,
					})
					if(fromModal) {
						App.setInvoicesListPage();
						setTimeout(function(){
							App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
							App.emptyOrderForms(myFormDiv);
						}, 2600);
					}
					else {
						document.querySelector('a[data-clients-link]').setAttribute('href', '/clients/'+data.sentData.client_o)
						document.querySelector('a[data-docs-link]').setAttribute('href', data.pdfLink)
					}
					if(popPlanning) window.location.href = `/planning?goto=addPlanningModal&fields=client_pl,order_pl&idFields=${data.sentData.client_o},${data.auto_o}`
					else {
						setTimeout(function(){
							Swal.fire({
								title: "Envoyer cette facture ?",
								html: "Voulez-vous envoyer cette facture ?<br>Les adresses mails du client seront définies en destinataires par défaut et les adresses de ses contacts en copie.<br>Vous pourrez modifier tout cela...",
								icon: "question",
								confirmButtonText: "Envoyer",
								confirmButtonColor: '#1da1f5',
								showDenyButton: true,
								denyButtonText: "Pas maintenant"
							}).then(response => {
								if(response.isConfirmed && !response.isDenied && !response.isDismissed) {
									App.popSendMail('invoice', data.auto_i, data.pdfPath+data.pdfName, data.clientMails, data.contactMails, '#'+data.name_invoice+' - '+data.sentData.label_i, {subject: 'subject_m_invoice', body: 'body_m_invoice'}, data.sentData.client_o)
								}
							})
						}, 2600);
					}
				}
				else returns = '<div class="alert alert-danger" role="alert"><b>La Facture n\'a pas été modifiée suite à un problème technique.</b></div>';
			}, "json").always(function(){
				$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
				$(myFormDiv+' [data-successfail]').html(returns);
				$(myFormDiv+' :disabled').attr("disabled", true);
			});
		}
		else alert("Veuillez ajouter des produits ou services à votre facture !")
	},
	addInvoice: async function(myFormDiv) {
		if(globals.site==0) {
			App.popCompanyChoiceModal(`App.addInvoice('${myFormDiv}');`);
			return;
		}
		this.closeModal('companyChoiceModal') // In case we get back from popCompanyChoiceModal
		if(document.querySelector(myFormDiv+' [data-field="total_i"]').value<0) {
			let hereWeGo = false
			await Swal.fire({
				title: 'Attention',
				html: '<h4>Vous désirez créer une facture dont le total est négatif, celle-ci sera donc traitée comme un avoir.</h4>',
				icon: 'warning',
				confirmButtonText: "Je confirme",
				confirmButtonColor: '#1da1f5',
				showDenyButton: true,
				denyButtonText: "Revenir"
			}).then(response => {
				console.warn(response.isConfirmed, response.isDenied, response.isDismissed)
				if(response.isDenied || response.isDismissed) hereWeGo = false;
				else hereWeGo = true;
			})
			if(!hereWeGo) return;
		}
		const anyLines = document.querySelectorAll(myFormDiv+' [data-order-product]')
		if(anyLines && anyLines.length>0) {
			// Check for Empty and NaN values in invoiced_ol[] and set them to 0... (Empty value passes even if min="0" unless field is required)
			const invoicedOls = document.querySelectorAll(myFormDiv+' [data-field="invoiced_ol"]')
			invoicedOls.forEach((elem) => {
				if(isNaN(parseFloat(elem.value))) elem.value = 0;
			})
			$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
			$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
			let returns;
			let request = new FormData($(myFormDiv)[0]);
			request.append("req", "ordersController");
			request.append("action", "addInvoice");
			request.append("id", globals.id);
			request.append("pwd", globals.pwd);
			request.append("type", globals.type);
			request.append("site", globals.site);
			request.append("type_doc", "invoice");
			$.ajax({
				url: globals.serverAddress,
				type: 'POST',
				data: request,
				dataType: "json",
				cache: false,
				contentType: false,
				processData: false
			}).done(function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Cette facture a bien été créée.</b></div>';
					Swal.fire({
						title: 'Action réussie',
						html: returns,
						icon: 'success',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
						timer: 2600,
						timerProgressBar: true,
					})
					// In case We come from popFormFromUrlParam We have to clean URL before refreshing
					const goTo = App.urlParam('goto', document.URL);
					if(goTo) window.history.pushState({}, document.title, window.location.href.split("?")[0]);
					setTimeout(function(){
						App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
						App.emptyOrderForms(myFormDiv);
						App.setInvoicesListPage();
						Swal.fire({
							title: "Envoyer cette facture ?",
							html: "Voulez-vous envoyer cette facture ?<br>Les adresses mails du client seront définies en destinataires par défaut et les adresses de ses contacts en copie.<br>Vous pourrez modifier tout cela...",
							icon: "question",
							confirmButtonText: "Envoyer",
							confirmButtonColor: '#1da1f5',
							showDenyButton: true,
							denyButtonText: "Pas maintenant"
						}).then(response => {
							if(response.isConfirmed && !response.isDenied && !response.isDismissed) {
								App.popSendMail('invoice', data.auto_i, data.pdfPath+data.pdfName, data.clientMails, data.contactMails, '#'+data.name_invoice+' - '+data.sentData.label_i, {subject: 'subject_m_invoice', body: 'body_m_invoice'}, data.sentData.client_o)
							}
						})
					}, 2600);
				}
				else returns = '<div class="alert alert-danger" role="alert"><b>Cette Facture n\'a pas été créée suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
				$(myFormDiv+' [data-successfail]').html(returns);
				$(myFormDiv+' :disabled').attr("disabled", true);
			});
		}
		else {
			Swal.fire({
				title: 'Echec',
				html: '<h4>Veuillez ajouter des produits ou services à votre facture !</h4>',
				icon: 'error',
				confirmButtonText: "Ok",
				confirmButtonColor: 'red',
				timer: 2600,
				timerProgressBar: true,
			})
		}
	},
	createInvoicePayBack: function(myFormDiv, thisBtn, event, fromModal = false) {
		if(event) event.preventDefault(); // prevents mod form submission !
		Swal.fire({
			title: "Créer un avoir sur cette facture",
			html: "Êtes-vous certain de vouloir supprimer cette facture ?<br>Cette action n'est réversible que sur intervention de SNS Solutions.",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const thisBtnHtml = $(thisBtn).html();
				$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i> Chargement');
				const delId = $(myFormDiv+' #auto_i').val();
				const req = "ordersController";
				const action = "createInvoicePayBack";
				let query = `auto_i=${delId}&id=${globals.id}&type=${globals.type}&site=${globals.site}&pwd=${globals.pwd}&ap=${globals.adminPass}&type_doc=invoice&req=${req}&action=${action}`;
				let returns = "";
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						returns = '<div class="alert alert-success" role="alert"><b>L\'avoir a bien été créé sur la facture.</b></div>';
						Swal.fire({
							title: 'Action réussie',
							html: returns,
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#1da1f5',
							timer: 2600,
							timerProgressBar: true,
						})
						if(fromModal) {
							App.setInvoicesListPage();
							setTimeout(function(){
								App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
								App.emptyOrderForms(myFormDiv);
								Swal.fire({
									title: "Envoyer cette facture ?",
									html: "Voulez-vous envoyer cette facture ?<br>Les adresses mails du client seront définies en destinataires par défaut et les adresses de ses contacts en copie.<br>Vous pourrez modifier tout cela...",
									icon: "question",
									confirmButtonText: "Envoyer",
									confirmButtonColor: '#1da1f5',
									showDenyButton: true,
									denyButtonText: "Pas maintenant"
								}).then(response => {
									if(response.isConfirmed && !response.isDenied && !response.isDismissed) {
										App.popSendMail('invoice', data.auto_i, data.pdfPath+data.pdfName, data.clientMails, data.contactMails, '#'+data.name_invoice+' - '+data.label_i, {subject: 'subject_m_invoice', body: 'body_m_invoice'}, data.client_i)
									}
								})
							}, 2600);
						}
						else {
							setTimeout(function(){
								Swal.fire({
									title: "Envoyer cette avoir ?",
									html: "Voulez-vous envoyer cette avoir ?<br>Les adresses mails du client seront définies en destinataires par défaut et les adresses de ses contacts en copie.<br>Vous pourrez modifier tout cela...",
									icon: "question",
									confirmButtonText: "Envoyer",
									confirmButtonColor: '#1da1f5',
									showDenyButton: true,
									denyButtonText: "Pas maintenant"
								}).then(response => {
									if(response.isConfirmed && !response.isDenied && !response.isDismissed) {
										App.popSendMail('invoice', data.auto_i, data.pdfPath+data.pdfName, data.clientMails, data.contactMails, '#'+data.name_invoice+' - '+data.label_i, {subject: 'subject_m_invoice', body: 'body_m_invoice'}, data.client_i)
									}
								})
								// document.location.href = '/sales/invoices/';
							}, 2600);
						}
					}
					else returns = '<div class="alert alert-danger" role="alert"><b>L\'avoir n\'a pas été créé suite à un problème technique.</b></div>';
				}, "json").always(function(data){
					$(thisBtn).attr("disabled", false).html(thisBtnHtml);
					$(myFormDiv+' [data-successfail]').html(returns);
				});
			}
		});
	},
	emptyOrderForms: (myFormDiv, clearAll = true) => {
		if(clearAll) App.clearFormFields(myFormDiv);
		const thisForm = document.querySelector(myFormDiv)
		thisForm.querySelector('[data-order-lines]').innerHTML = '';
		thisForm.querySelector('[data-order-globals]').innerHTML = '';
		thisForm.querySelector('[data-order-invoices]').innerHTML = '';
		thisForm.querySelector('[data-display-margin-total-value]').innerHTML = '';
		thisForm.querySelectorAll('[data-mod-invoice-disabled]').forEach((item, index) => {
			item.removeAttribute('disabled')
		})
		thisForm.querySelectorAll('[data-mod-invoice-none]').forEach((item, index) => {
			item.classList.remove('d-none')
		})
		$(myFormDiv+' select option').show().attr('disabled',false); // Get back hidden or disabled Selects Options
		if(document.querySelector(myFormDiv+'Header')) document.querySelector(myFormDiv+'Header').innerHTML = ''
	},
	setSalesFollowUpListPage: function(thisBtn) {
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'ordersController', action: 'getSalesFollowUpList'}, function(data){
			if(data.ok=='ok') {
				$("[data-table-view]").html(data.snippet);
				$("[data-kanban-view]").html(data.snippetPhone);
				App.buildDataFilterSearch();
			}
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("[data-table-view]").html(noOne);
				$("[data-kanban-view]").html(noOne);
			}
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableSalesFollowUpList', 25, true, 0, 'desc', 'Listing de suivi des Factures', [0,2,3,4,5,6], [1,7,8,9,10], '', '', '', [0,1,2,3,4,5,6,7,8,9,10], true);
		}).always(function() {
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		const date_end_i = document.querySelectorAll('[data-field="date_end_i"]');
		if(date_end_i) {
			date_end_i.value = new Date()
			new Litepicker({
				element: date_end_i,
				lang: 'fr-FR',
				startDate: new Date(),
				format: 'DD/MM/YYYY'
			});
		}
		this.popFormFromUrlParam()
		// Building meta tags list...
		$('head title').text('Suivi réglements Factures - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Suivi réglements Factures');
	},
	payInvoice: (idInvoice, thisBtn) => {
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		Swal.fire({
			title: "Déclarer le paiement de cette facture ?",
			html: "Êtes-vous certain de vouloir déclarer ce paiement ?",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const req = "ordersController";
				const action = "payInvoice";
				let query = "auto_i=" + idInvoice + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						Swal.fire({
							title: 'Action réussie',
							html: '<div class="alert alert-success" role="alert"><b>Cette facture a été déclarée comme payée.</b></div>',
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#1da1f5',
							timer: 2600,
							timerProgressBar: true,
						})
						App.setSalesFollowUpListPage();
					}
					else {
						Swal.fire({
							title: 'L\'action a échouée',
							html: '<b>Cette facture n\'a pas été déclarée comme payée suite à un problème technique.</b><br><a href="#modal_contact" data-bs-toggle="modal" title="SNS SOLUTIONS">Déclarer un incident au support</a>',
							icon: 'error',
							confirmButtonText: "Ok",
							confirmButtonColor: 'red',
							timer: 2600,
							timerProgressBar: true,
						})
					}
				}, "json").always(function(data){
					$(thisBtn).attr("disabled", false).html(thisBtnHtml);
				});
			}
			else $(thisBtn).attr("disabled", false).html(thisBtnHtml);
		});
	},
	cancelPayInvoice: (idInvoice, thisBtn) => {
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		Swal.fire({
			title: "Annuler le paiement de cette facture ?",
			html: "Êtes-vous certain de vouloir annuler ce paiement ?",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const req = "ordersController";
				const action = "cancelPayInvoice";
				let query = "auto_i=" + idInvoice + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						Swal.fire({
							title: 'Action réussie',
							html: '<div class="alert alert-success" role="alert"><b>Le paiement de cette facture a été annulé.</b></div>',
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#1da1f5',
							timer: 2600,
							timerProgressBar: true,
						})
						App.setSalesFollowUpListPage();
					}
					else {
						Swal.fire({
							title: 'L\'action a échouée',
							html: '<b>Cette facture n\'a pas été déclarée comme impayée suite à un problème technique.</b><br><a href="#modal_contact" data-bs-toggle="modal" title="SNS SOLUTIONS">Déclarer un incident au support</a>',
							icon: 'error',
							confirmButtonText: "Ok",
							confirmButtonColor: 'red',
							timer: 2600,
							timerProgressBar: true,
						})
					}
				}, "json").always(function(data){
					$(thisBtn).attr("disabled", false).html(thisBtnHtml);
				});
			}
			else $(thisBtn).attr("disabled", false).html(thisBtnHtml);
		});
	},
	setProductsListPage: async function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#addProductForm', '#modProductForm'], 'productsController', ['tax_p', 'unit_p', 'user_p', 'site_p']);
		$('#addProductForm [data-field=site_p]').val(globals.site).attr('data-default', globals.site)
		$('#addProductForm [data-field=user_p]').val(globals.id).attr('data-default', globals.id)
		const defaultTax = globals.siteSettings?.taxes_s || 1
		$('#addProductForm [data-field=tax_p]').val(defaultTax).attr("data-default", defaultTax)
		if(this.settings.type=='user') {
			$('#addProductForm button[type=submit]').attr('disabled', true);
			$('#modProductForm button[type=submit]').attr('disabled', true);
		}
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'productsController', action: 'getProductsList'}, function(data){
			if(data.ok=='ok') {
				$("[data-table-view]").html(data.snippet);
				$("[data-kanban-view]").html(data.snippetPhone);
				App.buildDataFilterSearch();
			}
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("[data-table-view]").html(noOne);
				$("[data-kanban-view]").html(noOne);
			}
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableProductsList', 25, true, 0, 'asc', 'Liste des Produits', [0,1,4,5,6,8], [2,3,7,9,10], '', '', '', [0,1,2,3,4,5,6,7,8,9,10], true);
		}).always(function() {
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		// else $("#productListCont").html('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette liste !</div>');
		// Building meta tags list...
		$('head title').text('Produits - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Produits');
	},
	setProductPage: async function(productId) {
		// First We get product's select box then we fill form with values
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#modProductForm'], 'productsController', ['tax_p', 'unit_p', 'user_p', 'site_p']);
		const myFormDiv = '#modProductForm';
		App.clearFormFields(myFormDiv);
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		if(this.settings.type=='user') $('#modProductForm button[type=submit]').attr('disabled', true);
		$.post(globals.serverAddress, {...globals.baseQuery, auto_p: productId, req: 'productsController', action: 'fillModProduct'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.products)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				if(data.products.picture_p != '') {
					$('.page-header-subtitle').html(`<a href="${globals.serverBaseUrl}docs/products/${data.products.picture_p}" id="productPictLink" data-simplelightbox="all">
						<img class="profile-clients-img rounded-xl shadow-lg" src="${globals.serverBaseUrl}docs/products/${data.products.picture_p}" />
					</a>`);
					document.querySelector(myFormDiv+' [data-delete-product-picure]').innerHTML = `<button onClick="App.delProductPicture('${data.products.auto_p}', '${data.products.picture_p}', this, event);" class="btn btn-danger btn-xs btn-icon ms-1" title="Supprimer la photo"><i class="fa fa-2x fa-times mr-0"></i></button>`
					let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
						showCounter : true,
						history : false,
						captionType : 'data',
						captionsData : 'caption',
						closeText : 'X'
					});
					lightbox.refresh();
				}
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce Produits !");
		}, "json");
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		// Building meta tags list...
		$('head title').text('Produits - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Produits');
	},
	fillModProduct: function(auto_p, thisBtn, myFormDiv, myFormModal) {
		App.clearFormFields(myFormDiv);
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_p: auto_p, req: 'productsController', action: 'fillModProduct'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.products)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
				else {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.remove('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = '';
				}
				if(data.products.picture_p!='') {
					$('#productLogoImg').attr('src', globals.serverBaseUrl+'docs/products/'+data.products.picture_p);
					$('#productLogoLink').attr('href', globals.serverBaseUrl+'docs/products/'+data.products.picture_p);
					document.querySelector(myFormDiv+' [data-delete-product-picure]').innerHTML = `<button onClick="App.delProductPicture('${data.products.auto_p}', '${data.products.picture_p}', this, event);" class="btn btn-danger btn-xs btn-icon ms-1" title="Supprimer la photo"><i class="fa fa-2x fa-times mr-0"></i></button>`
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce Produit !");
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
			let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
				showCounter : true,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				closeText : 'X'
			});
			lightbox.refresh();
		});
	},
	checkProductId: function(input) {
		const reference_p = input.value;
		const myFormDiv = input.closest('form').getAttribute('id');
		console.log(myFormDiv);
		const auto_p = document.querySelector('#'+myFormDiv+' [data-field="auto_p"]').value;
		const req = "productsController";
		const action = "checkProductId";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&auto_p=" + auto_p + "&reference_p=" + reference_p + "&req=" + req + "&action=" + action;
		$.post(globals.serverAddress, query, function(data){
			if(data.ok == 'ko') {
				Swal.fire({
					title: 'Echec',
					html: '<h4>Cette Référence est déjà attribuée à un autre produit !</h4>',
					icon: 'error',
					confirmButtonText: "Ok",
					confirmButtonColor: 'red',
					timer: 2600,
					timerProgressBar: true,
				})
				input.classList.add("is-invalid");
			}
			else input.classList.remove("is-invalid");
		}, "json").always(function(){
		});
	},
	refreshPriceTax: (thisBtn) => {
		const myForm = thisBtn.closest('form')
		const priceInput = myForm.querySelector('[data-field="price_p"]')
		const priceTaxInput = myForm.querySelector('[data-field="price_tax_p"]')
		let price = priceInput.value
		let priceTax = priceTaxInput.value
		const tax = myForm.querySelector('[data-field="tax_p"]').selectedOptions[0].dataset.multiply
		const myField = thisBtn.getAttribute('data-field')
		switch (myField) {
			case 'price_p':
				priceTax = price * tax
				priceTaxInput.value = priceTax.toFixed(2)
			break;
			case 'price_tax_p':
				price = priceTax / tax
				priceInput.value = price.toFixed(2)
			break;
			case 'tax_p':
				priceTax = price * tax
				priceTaxInput.value = priceTax.toFixed(2)
			break;
		}
		console.warn(tax, price)
	},
	modProduct: function(myFormDiv, fromModal = false, popPlanning = false) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let returns;
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "productsController");
		request.append("action", "modProduct");
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("ap", globals.adminPass);
		request.append("site", globals.site);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Le Produit a bien été mis à jour.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				if(fromModal) {
					App.setProductsListPage();
					setTimeout(function(){
						App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
						App.clearFormFields(myFormDiv);
					}, 2600);
				}
			}
			else if(data.exist) {
				returns = '<div class="alert alert-danger" role="alert"><b>Le Produit n\'a pas été modifié car sa référence n\'est pas unique.</b></div>';
				Swal.fire({
					title: 'Echec',
					html: returns,
					icon: 'error',
					confirmButtonText: "Ok",
					confirmButtonColor: 'red',
					timer: 2600,
					timerProgressBar: true,
				})
			}
			else returns = '<div class="alert alert-danger" role="alert"><b>Le Produit n\'a pas été modifié suite à un problème technique.</b></div>';
		}, "json").always(function(){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	addProduct: function(myFormDiv) {
		if(globals.site==0) {
			App.popCompanyChoiceModal(`App.addProduct('${myFormDiv}');`);
			return;
		}
		this.closeModal('companyChoiceModal') // In case we get back from popCompanyChoiceModal
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let returns;
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "productsController");
		request.append("action", "addProduct");
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("ap", globals.adminPass);
		request.append("site", globals.site);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Ce Produit a bien été créé.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				switch(sessionStorage.getItem('myPage')) {
					case 'products':
						App.setProductsListPage();
					break;
					case 'sales/quotations':
						App.getFormsSelectBoxes(['#addQuotationForm', '#modQuotationForm'], 'ordersController', ['products_o']);
					break;
					case 'sales/invoices':
						App.getFormsSelectBoxes(['#addInvoiceForm', '#modInvoiceForm'], 'ordersController', ['products_o']);
					break;
					default:
						App.setProductsListPage();
				}
				setTimeout(function(){
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else if(data.exist) {
				returns = '<div class="alert alert-danger" role="alert"><b>Le Produit n\'a pas été créé car sa référence n\'est pas unique.</b></div>';
				Swal.fire({
					title: 'Echec',
					html: returns,
					icon: 'error',
					confirmButtonText: "Ok",
					confirmButtonColor: 'red',
					timer: 2600,
					timerProgressBar: true,
				})
			}
			else returns = '<div class="alert alert-danger" role="alert"><b>Le Produit n\'a pas été créé suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	delProduct: function(myFormDiv, event, fromModal = false) {
		event.preventDefault(); // prevents mod form submission !
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce produit ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir supprimer ce produit ?",
			html: "Cette action n'est réversible que sur intervention de SNS Solutions.",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const thisBtnHtml = $(myFormDiv+' [name=deleter]').html();
				$(myFormDiv+' [name=deleter]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i> Chargement');
				const delId = $(myFormDiv+' #auto_p').val();
				const req = "productsController";
				const action = "delProduct";
				let query = "auto_p=" + delId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
				let returns = "";
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						returns = '<div class="alert alert-success" role="alert"><b>Ce Produit a bien été supprimé.</b></div>';
						Swal.fire({
							title: 'Action réussie',
							html: returns,
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#1da1f5',
							timer: 2600,
							timerProgressBar: true,
						})
						if(fromModal) {
							App.setProductsListPage();
							setTimeout(function(){
								App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
								App.clearFormFields(myFormDiv);
							}, 2600);
						}
						else {
							setTimeout(function(){
								document.location.href = '/products/';
							}, 2600);
						}
					}
					else returns = '<div class="alert alert-danger" role="alert"><b>Ce Produit n\'a pas été supprimé suite à un problème technique.</b></div>';
				}, "json").always(function(data){
					$(myFormDiv+' [name=deleter]').attr("disabled", false).html(thisBtnHtml);
					$(myFormDiv+' [data-successfail]').html(returns);
				});
			}
		});
	},
	delProductPicture: function(productId, productPicture, thisBtn, event) {
		event.preventDefault(); // prevents mod form submission !
		Swal.fire({
			title: "Suppression image",
			html: "Êtes-vous certain de vouloir supprimer la photo principale de ce produit ?",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const myFormDiv = '#'+thisBtn.closest('form').getAttribute('id');
				const thisBtnHtml = $(thisBtn).html();
				$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>');
				const req = "productsController";
				const action = "delProductPicture";
				let query = "auto_p=" + productId + "&picture_p=" + productPicture + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
				let returns = "";
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						returns = '<div class="alert alert-success" role="alert"><b>La photo de ce Produit a bien été supprimée.</b></div>';
						Swal.fire({
							title: 'Action réussie',
							html: returns,
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#1da1f5',
							timer: 2600,
							timerProgressBar: true,
						})
						document.querySelector('[data-product-pict-preview]').innerHTML = `
							<a href="${globals.serverBaseUrl}online_assets/undraw_photos_re_pvh3.png" id="productLogoLink" data-simplelightbox="all">
								<img class="img-fluid rounded-xl shadow" id="productLogoImg" src="${globals.serverBaseUrl}online_assets/undraw_photos_re_pvh3.svg" />
							</a>
						`
					}
					else returns = '<div class="alert alert-danger" role="alert"><b>La photo de ce Produit n\'a pas été supprimée suite à un problème technique.</b></div>';
				}, "json").always(function(data){
					$(thisBtn).attr("disabled", false).html(thisBtnHtml);
					$(myFormDiv+' [data-successfail]').html(returns);
				});
			}
		});
	},
	getProductSalesList: function (auto_p, reference_p, thisBtn) {
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>');
		$.post(globals.serverAddress, {...globals.baseQuery, auto_p: auto_p, req: 'productsController', action: 'getProductSalesList'}, function(data){
			if(data.ok=='ok') $("#productSalesListCont").html(data.snippet);
			else $("#productSalesListCont").html('<div class="alert alert-secondary h2 text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Ce produit #'+reference_p+' n\'a pas été vendu !</div>');
		}, "json").done(function(data) {
			if(data.ok=='ok') App.buildDataTables('#dataTableClientsContactsList', 25, true, 3, 'asc', 'Liste des Ventes du Produit #'+reference_p, [0,1,3,4,5], [2,6,7,8], '', '', '', [0,1,2,3,4,5,6,7,8], true);
		}).always(function(data) {
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			$('#getProductSalesListModal .modal-title').html('<i class="fa fa-user-friends"></i> Liste des Ventes du Produit #'+reference_p);
			let myModal = new bootstrap.Modal(document.getElementById('getProductSalesListModal'));
			myModal.show();
		});
	},
	setScenesListPage: async function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#addSceneForm', '#modSceneForm'], 'scenesController', ['products_sc', 'user_sc', 'site_sc']);
		$('#addSceneForm [data-field=site_sc]').val(globals.site).attr('data-default', globals.site)
		$('#addSceneForm [data-field=user_sc]').val(globals.id).attr('data-default', globals.id)
		if(this.settings.type=='user') {
			$('#addSceneForm button[type=submit]').attr('disabled', true);
			$('#modSceneForm button[type=submit]').attr('disabled', true);
		}
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'scenesController', action: 'getScenesList'}, function(data){
			if(data.ok=='ok') {
				$("[data-table-view]").html(data.snippet);
				$("[data-kanban-view]").html(data.snippetPhone);
				App.buildDataFilterSearch();
			}
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("[data-table-view]").html(noOne);
				$("[data-kanban-view]").html(noOne);
			}
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableScenesList', 25, true, 1, 'asc', 'Liste des Scénarios', [0,1,2,4], [3], '', '', '', [0,1,2,3,4], true);
		}).always(function() {
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
			const sceneProductsConts = document.querySelectorAll('[data-scene-products]')
			sceneProductsConts.forEach((elem) => {
				const myGragable = new Sortable(elem, {
					animation: 150,
					handle: '.handle',
					swapThreshold: 0.9, // 0 to 1 => width of the swap zone on elements
					ghostClass: 'blue-background-class'
				});
			})
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		// else $("#productListCont").html('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette liste !</div>');
		// Building meta tags list...
		$('head title').text('Scénarios - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Scénarios');
	},
	fillModScene: function(auto_sc, thisBtn, myFormDiv, myFormModal) {
		App.clearFormFields(myFormDiv);
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		if(this.settings.type=='user') $('#modSceneForm button[type=submit]').attr('disabled', true);
		const sceneProductsCont = document.querySelector(myFormDiv+' [data-scene-products]')
		sceneProductsCont.classList.add('list-group', 'list-group-numbered', 'my-2', 'px-2')
		sceneProductsCont.innerHTML = ''
		$.post(globals.serverAddress, {...globals.baseQuery, auto_sc: auto_sc, req: 'scenesController', action: 'fillModScene'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.scenes)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				for (const [key, product] of Object.entries(data.scenesProducts)) {
					if(product.product_scp!=0) { // It's a Product
						sceneProductsCont.insertAdjacentHTML('beforeend',`
							<li class="list-group-item list-group-item-action list-group-item-primary d-flex justify-content-between align-items-start">
								<input type="hidden" name="auto_scp[]" data-field="auto_scp" value="${product.auto_scp}" data-scene-id-line>
								<div class="row g-0 px-2 w-100">
									<div class="fw-bold">${product.reference_p}</div>
									${product.name_p}<br><b>${product.type_p}</b>
									<input type="hidden" name="product_scp[]" value="${product.product_scp}" data-scene-product>
									<div class="form-group col-md-6">
										<div class="input-group">
											<span class="input-group-text">Quantité</span>
											<input class="form-control" name="quantity_scp[]" type="number" step="0.01" min="0" value="${product.quantity_scp}" placeholder="Quantité" data-scene-quantity"/>
										</div>
									</div>
									<div class="form-group col-md-6">
										<div class="input-group">
											<span class="input-group-text">Prix Unitaire HT</span>
											<input class="form-control" name="price_scp[]" type="number" step="0.01" min="0" value="${product.price_scp}" placeholder="Prix Unitaire HT" data-scene-price"/>
										</div>
									</div>
									<input type="hidden" name="category_scp[]" value="${product.category_scp}" data-scene-category>
								</div>
								<div class="d-flex flex-column g-1">
									<button class="badge btn btn-danger btn-icon btn-sm shadow" onclick="App.delProductFromScene(this, event)">
										<i class="fa fa-2x fa-times"></i>
									</button>
									<button class="handle badge btn btn-dark btn-icon btn-sm shadow mt-1" title="Maintenez puis glisser à la position souhaitée.">
										<i class="fa fa-2x fa-arrows-up-down"></i>
									</button>
								</div>
							</li>
						`);
					}
					else { // It's a category line
						sceneProductsCont.insertAdjacentHTML('beforeend',`
							<li class="list-group-item list-group-item-action list-group-item-primary d-flex justify-content-between align-items-start">
								<input type="hidden" name="auto_scp[]" data-field="auto_scp" value="${product.auto_scp}" data-scene-id-line>
								<div class="col mx-2">
									<input type="hidden" name="product_scp[]" value="0" data-scene-product-none>
									<input type="hidden" name="quantity_scp[]" value="1" data-scene-quantity"/>
									<input type="hidden" name="price_scp[]" value="0" data-scene-price"/>
									<input type="text" class="form-control" name="category_scp[]" value="${product.category_scp}" data-scene-category>
								</div>
								<div class="d-flex flex-column g-1">
									<button class="badge btn btn-danger btn-icon btn-sm shadow" onclick="App.delProductFromScene(this, event)">
										<i class="fa fa-2x fa-times"></i>
									</button>
									<button class="handle badge btn btn-dark btn-icon btn-sm shadow mt-1" title="Maintenez puis glisser à la position souhaitée.">
										<i class="fa fa-2x fa-arrows-up-down"></i>
									</button>
								</div>
							</li>
						`);
					}
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce Scénario !");
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
			let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
				showCounter : true,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				closeText : 'X'
			});
			lightbox.refresh();
		});
	},
	addProduct2Scene: (thisBtn, event, auto_p, reference_p, name_p, price_p, type_p, thisFormId) => {
		event.preventDefault()
		// thisBtn is either a button in a popup modal or a select in the form
		const thisForm = (thisFormId) ? document.getElementById(thisFormId) : thisBtn.closest('form')
		if(auto_p) {
			App.closeModal('sceneAddProductsListModal') // Coming from "sceneAddProductsListModal"
			thisForm.querySelector('[data-field="products_sc"]').value = auto_p
		}
		const idProduct = (auto_p) ? auto_p : thisBtn.value
		const refProduct = (reference_p) ? reference_p : thisBtn.selectedOptions[0].dataset.reference
		const nameProduct = (name_p) ? name_p : thisBtn.selectedOptions[0].dataset.name
		const priceProduct = (price_p) ? price_p : thisBtn.selectedOptions[0].dataset.price
		const typeProduct = (type_p) ? type_p : thisBtn.selectedOptions[0].dataset.type
		if(idProduct==0) return; // ListBox option "Sélectionner" is selected => Do nothing
		const sceneProductsCont = thisForm.querySelector('[data-scene-products]')
		sceneProductsCont.classList.add('list-group', 'list-group-numbered', 'my-2', 'px-2')
		// Next is similar to jQuery $(sceneProductsCont).append(`...`)
		sceneProductsCont.insertAdjacentHTML('beforeend',`
			<li class="list-group-item list-group-item-action list-group-item-primary d-flex justify-content-between align-items-start">
				<input type="hidden" name="auto_scp[]" data-field="auto_scp" value="0" data-scene-id-line>
				<div class="container-fluid">
					<div class="row">
						<div class="col-md-8">
							<div class="ms-2 me-auto">
								<div class="fw-bold">${refProduct}</div>
								${nameProduct}<br><b>${typeProduct}</b>
								<input type="hidden" name="product_scp[]" value="${idProduct}" data-scene-product>
								<input type="hidden" name="category_scp[]" value="" data-scene-category>
							</div>
						</div>
						<div class="col-md-4">
							<div class="input-group">
								<span class="input-group-text">Quantité</span>
								<input class="form-control" name="quantity_scp[]" type="number" step="0.01" min="0" value="1" placeholder="Quantité" data-scene-quantity"/>
							</div>
							<div class="input-group">
								<span class="input-group-text">Prix Unitaire HT</span>
								<input class="form-control" name="price_scp[]" type="number" step="0.01" min="0" value="${priceProduct}" placeholder="Prix Unitaire HT" data-scene-price"/>
							</div>
						</div>
					</div>
				</div>
				<div class="d-flex flex-column g-1">
					<button class="badge btn btn-danger btn-icon btn-sm shadow" onclick="App.delProductFromScene(this, event)">
						<i class="fa fa-2x fa-times"></i>
					</button>
					<button class="handle badge btn btn-dark btn-icon btn-sm shadow mt-1" title="Maintenez puis glisser à la position souhaitée.">
						<i class="fa fa-2x fa-arrows-up-down"></i>
					</button>
				</div>
			</li>
		`);
		thisForm.querySelectorAll('.handle').forEach((item) => {
			item.addEventListener('click', (event) =>{
				event.preventDefault()
			})
		})
	},
	delProductFromScene: (thisBtn, event) => {
		event.preventDefault()
		thisBtn.closest('li').remove()
	},
	addLine2Scene: (thisBtn, event) => {
		event.preventDefault()
		// thisBtn is either a button in a popup modal or a select in the form
		const thisForm = thisBtn.closest('form')
		const lineText = thisForm.querySelector('[data-cat-input]').value
		if(lineText!='') {
			const sceneProductsCont = thisForm.querySelector('[data-scene-products]')
			sceneProductsCont.classList.add('list-group', 'list-group-numbered', 'my-2', 'px-2')
			// Next is similar to jQuery $(sceneProductsCont).append(`...`)
			sceneProductsCont.insertAdjacentHTML('beforeend',`
				<li class="list-group-item list-group-item-action list-group-item-primary d-flex justify-content-between align-items-start">
					<input type="hidden" name="auto_scp[]" data-field="auto_scp" value="0" data-scene-id-line>
					<div class="ms-2 me-auto">
						<div class="fw-bold">${lineText}</div>
						<input type="hidden" name="product_scp[]" value="0" data-scene-product-none>
						<input type="hidden" name="quantity_scp[]" value="1" data-scene-quantity"/>
						<input type="hidden" name="price_scp[]" value="0" data-scene-price"/>
						<input type="hidden" name="category_scp[]" value="${lineText}" data-scene-category>
					</div>
					<div class="d-flex flex-column g-1">
						<button class="badge btn btn-danger btn-icon btn-sm shadow" onclick="App.delProductFromScene(this, event)">
							<i class="fa fa-2x fa-times"></i>
						</button>
						<button class="handle badge btn btn-dark btn-icon btn-sm shadow mt-1" title="Maintenez puis glisser à la position souhaitée.">
							<i class="fa fa-2x fa-arrows-up-down"></i>
						</button>
					</div>
				</li>
			`);
			thisForm.querySelectorAll('.handle').forEach((item) => {
				item.addEventListener('click', (event) =>{
					event.preventDefault()
				})
			})
		}
		else {
			Swal.fire({
				title: 'Ajout ligne catégorie !',
				html: 'Veuillez renseigner le contenu de la ligne afin de l\'insérer dans le scénario.',
				icon: 'error',
				confirmButtonText: "Ok",
				confirmButtonColor: 'red',
				timer: 2600,
				timerProgressBar: true,
			})
		}
	},
	sceneAddProductsList: function(thisBtn, event) {
		event.preventDefault();
		const thisBtnHtml = $(thisBtn).html()
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const thisFormId = thisBtn.closest('form').getAttribute('id')
		$.post(globals.serverAddress, {...globals.baseQuery, formId: thisFormId, req: 'scenesController', action: 'sceneAddProductsList'}, function(data){
			$("#sceneAddProductsListCont").html(data.snippet);
		}, "json").done(function() {
			App.buildDataTables('#dataTableAssignProductsList', 25, true, 0, 'asc', 'Liste des Produits', [0,1,4,6], [2,3,5], '', '', '', [0,1,2,3,4,5,6], true);
		}).always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			let myModal = new bootstrap.Modal(document.getElementById('sceneAddProductsListModal'));
			myModal.show();
		});
	},
	modScene: function(myFormDiv, fromModal = false, popPlanning = false) {
		const anyProduct = document.querySelectorAll(myFormDiv+' [data-scene-product]')
		if(anyProduct && anyProduct.length>1) {
			$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
			$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
			let returns;
			let request = new FormData($(myFormDiv)[0]);
			request.append("req", "scenesController");
			request.append("action", "modScene");
			request.append("id", globals.id);
			request.append("pwd", globals.pwd);
			request.append("type", globals.type);
			request.append("ap", globals.adminPass);
			request.append("site", globals.site);
			$.ajax({
				url: globals.serverAddress,
				type: 'POST',
				data: request,
				dataType: "json",
				cache: false,
				contentType: false,
				processData: false
			}).done(function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Le Scénario a bien été mis à jour.</b></div>';
					Swal.fire({
						title: 'Action réussie',
						html: returns,
						icon: 'success',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
						timer: 2600,
						timerProgressBar: true,
					})
					if(fromModal) {
						App.setScenesListPage();
						setTimeout(function(){
							App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
							App.clearFormFields(myFormDiv);
							$(myFormDiv+' [data-scene-products]').empty()
						}, 2600);
					}
				}
				else returns = '<div class="alert alert-danger" role="alert"><b>Le Scénario n\'a pas été modifié suite à un problème technique.</b></div>';
			}, "json").always(function(){
				$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
				$(myFormDiv+' [data-successfail]').html(returns);
				$(myFormDiv+' :disabled').attr("disabled", true);
			});
		}
		else if(anyProduct.length==1) alert("Il n'y a qu'un produit ou service dans votre scénario !\r\nVeuillez en ajouter d'autres...")
		else alert("Veuillez ajouter des produits ou services à votre scénario !")
	},
	addScene: function(myFormDiv) {
		const anyProduct = document.querySelectorAll(myFormDiv+' [data-scene-product]')
		if(anyProduct && anyProduct.length>1) {
			$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
			$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
			let returns;
			let request = new FormData($(myFormDiv)[0]);
			request.append("req", "scenesController");
			request.append("action", "addScene");
			request.append("id", globals.id);
			request.append("pwd", globals.pwd);
			request.append("type", globals.type);
			request.append("ap", globals.adminPass);
			request.append("site", globals.site);
			$.ajax({
				url: globals.serverAddress,
				type: 'POST',
				data: request,
				dataType: "json",
				cache: false,
				contentType: false,
				processData: false
			}).done(function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Ce Scénario a bien été créé.</b></div>';
					Swal.fire({
						title: 'Action réussie',
						html: returns,
						icon: 'success',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
						timer: 2600,
						timerProgressBar: true,
					})
					App.setScenesListPage();
					setTimeout(function(){
						App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
						App.clearFormFields(myFormDiv);
						$(myFormDiv+' [data-scene-products]').empty()
					}, 2600);
				}
				else returns = '<div class="alert alert-danger" role="alert"><b>Le Scénario n\'a pas été créé suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
				$(myFormDiv+' [data-successfail]').html(returns);
				$(myFormDiv+' :disabled').attr("disabled", true);
			});
		}
		else if(anyProduct.length==1) alert("Il n'y a qu'un produit ou service dans votre scénario !\r\nVeuillez en ajouter d'autres...")
		else alert("Veuillez ajouter des produits ou services à votre scénario !")
	},
	delScene: function(thisBtn, event, auto_sc) {
		if(event) event.preventDefault(); // prevents mod form submission !
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce scénario ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir supprimer ce scénario ?",
			html: "Cette action n'est réversible que sur intervention de SNS Solutions.",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const thisBtnHtml = $(thisBtn).html();
				$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>');
				const myFormDiv = '#modSceneForm';
				const delId = (auto_sc) ? auto_sc : $(myFormDiv+' [data-field="auto_sc"]').val();
				const req = "scenesController";
				const action = "delScene";
				let query = "auto_sc=" + delId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						Swal.fire({
							title: 'Action réussie',
							html: '<div class="alert alert-success" role="alert"><b>Le Scénario a bien été supprimé.</b></div>',
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#1da1f5',
							timer: 2600,
							timerProgressBar: true,
						})
						App.setScenesListPage();
						if(event) { // We were in modSceneForm
							setTimeout(function(){
								App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
								App.clearFormFields(myFormDiv);
								$(myFormDiv+' [data-scene-products]').empty()
							}, 2100);
						}
					}
					else {
						Swal.fire({
							title: 'L\'action a échouée',
							html: '<div class="alert alert-danger" role="alert"><b>Ce Scénario n\'a pas été supprimé suite à un problème technique.</b></div>',
							icon: 'error',
							confirmButtonText: "Ok",
							confirmButtonColor: 'red',
							timer: 2600,
							timerProgressBar: true,
						})
					}
				}, "json").always(function(data){
					$(thisBtn).attr("disabled", false).html(thisBtnHtml);
				});
			}
		});
	},
	delScenesProduct: function(auto_scp, thisBtn, event) {
		event.preventDefault(); // prevents mod form submission !
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce produit du scénario ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir supprimer ce produit du scénario ?",
			html: "",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const thisBtnHtml = $(thisBtn).html();
				$(thisBtn).html('<i class="fa fa-spinner fa-pulse"></i>');
				const req = "scenesController";
				const action = "delScenesProduct";
				let query = "auto_scp=" + auto_scp + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						App.setScenesListPage();
						setTimeout(function(){
							App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
							App.clearFormFields(myFormDiv);
						}, 2600);
					}
					else alert("Ce scénario n'a pas été supprimé suite à un problème technique.");
				}, "json").always(function(){
					$(thisBtn).attr("disabled", false).html(thisBtnHtml);
				});
			}
		})
	},
	setBuyInvoicesListPage: async function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#addBuyInvoiceForm', '#modBuyInvoiceForm'], 'buyInvoicesController', ['client_bi', 'user_bi', 'site_bi']);
		$('#addBuyInvoiceForm [data-field=site_bi]').val(globals.site).attr('data-default', globals.site)
		$('#addBuyInvoiceForm [data-field=user_bi]').val(globals.id).attr('data-default', globals.id)
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'buyInvoicesController', action: 'getBuyInvoicesList'}, function(data){
			if(data.ok=='ok') {
				$("[data-table-view]").html(data.snippet);
				$("[data-kanban-view]").html(data.snippetPhone);
				App.buildDataFilterSearch();
			}
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("[data-table-view]").html(noOne);
				$("[data-kanban-view]").html(noOne);
			}
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableBuyInvoicesList', 25, true, 0, 'desc', 'Liste des Achats', [0,1,3,4,5,6], [2,7], '', '', '', [0,1,2,3,4,5,6,7], true);
		}).always(function() {
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		const date_start_bi = document.querySelectorAll('[data-field="date_start_bi"]');
		if(date_start_bi.length>0) {
			date_start_bi.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					elementEnd: thisItem.closest('.row').querySelector('[data-field="date_end_bi"]'),
					lang: 'fr-FR',
					format: 'DD/MM/YYYY',
					singleMode: false,
					numberOfMonths: 2,
					numberOfColumns: 2,
					tooltipText: {
						one: 'jour',
						other: 'jours'
					},
					tooltipNumber: (totalDays) => {
						return totalDays;
					},
				});
			});
		}
		const date_end_bi = document.querySelectorAll('[data-field="date_end_bi"]');
		if(date_end_bi.length>0) {
			date_end_bi.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		// else $("#clientListCont").html('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette liste !</div>');
		this.popFormFromUrlParam();
		// Building meta tags list...
		$('head title').text('Achats - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Achats');
	},
	setBuyInvoicePage: async function(buyInvoiceId) {
		// First We get client's select box then we fill form with values
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#modBuyInvoiceForm'], 'buyInvoicesController', ['client_bi', 'user_bi', 'site_bi']);
		const myFormDiv = '#modBuyInvoiceForm';
		App.clearFormFields(myFormDiv);
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_bi: buyInvoiceId, req: 'buyInvoicesController', action: 'fillModBuyInvoice'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.buyInvoices)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				document.querySelector('a[data-clients-link]').setAttribute('href', '/clients/'+data.buyInvoices.client_bi)
				document.querySelector(myFormDiv+' [data-display-range-value]').innerHTML = data.buyInvoices.payed_bi+' %'
				App.calculateBuyInvoiceTotals(null, document.querySelector(myFormDiv))
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé cet achat !");
		}, "json");
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		const date_start_bi = document.querySelectorAll('[data-field="date_start_bi"]');
		if(date_start_bi.length>0) {
			date_start_bi.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					elementEnd: thisItem.closest('.row').querySelector('[data-field="date_end_bi"]'),
					lang: 'fr-FR',
					format: 'DD/MM/YYYY',
					singleMode: false,
					numberOfMonths: 2,
					numberOfColumns: 2,
					tooltipText: {
						one: 'jour',
						other: 'jours'
					},
					tooltipNumber: (totalDays) => {
						return totalDays;
					},
				});
			});
		}
		const date_end_bi = document.querySelectorAll('[data-field="date_end_bi"]');
		if(date_end_bi.length>0) {
			date_end_bi.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		// Building meta tags list...
		$('head title').text('Achats - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Achats');
	},
	fillModBuyInvoice: function(auto_bi, thisBtn, myFormDiv, myFormModal) {
		App.clearFormFields(myFormDiv);
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_bi: auto_bi, req: 'buyInvoicesController', action: 'fillModBuyInvoice'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.buyInvoices)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				document.querySelector(myFormDiv+' a[data-clients-link]').setAttribute('href', '/clients/'+data.buyInvoices.client_bi)
				document.querySelector(myFormDiv+' [data-display-range-value]').innerHTML = data.buyInvoices.payed_bi+' %'
				App.calculateBuyInvoiceTotals(null, document.querySelector(myFormDiv))
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
				else {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.remove('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = '';
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé cet achat !");
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
			let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
				showCounter : true,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				closeText : 'X'
			});
			lightbox.refresh();
		});
	},
	calculateBuyInvoiceTotals: (thisBtn, thisForm = null) => {
		const myForm = (thisBtn) ? thisBtn.closest('form') : thisForm
		const amountInput = myForm.querySelector('[data-amount]')
		const amountTaxInputs = myForm.querySelectorAll('[data-amounts-tax]')
		const total = Number(amountInput.value)
		let totalTax = 0
		amountTaxInputs.forEach((item, index) => {
			// const tax = item.dataset.multiply
			totalTax += Number(item.value)
		})
		const totalTaxIncl = total + totalTax
		myForm.querySelector('[data-field="amount_tax_bi"]').value = totalTax.toFixed(2)
		myForm.querySelector('[data-field="amount_tax_incl_bi"]').value = totalTaxIncl.toFixed(2)
		const payedRange = myForm.querySelector('[data-payed-range]').value
		const payedAmount = totalTaxIncl * payedRange/100
		myForm.querySelector('[data-payed-amount]').value = payedAmount.toFixed(2)
	},
	modBuyInvoice: function(myFormDiv, fromModal = false) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let returns;
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "buyInvoicesController");
		request.append("action", "modBuyInvoice");
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("site", globals.site);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>L\'achat a bien été mis à jour.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				if(fromModal) {
					App.setBuyInvoicesListPage();
					setTimeout(function(){
						App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
						App.clearFormFields(myFormDiv);
					}, 2600);
				}
			}
			else returns = '<div class="alert alert-danger" role="alert"><b>L\'achat n\'a pas été modifié suite à un problème technique.</b></div>';
		}, "json").always(function(){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	addBuyInvoice: function(myFormDiv) {
		if(globals.site==0) {
			App.popCompanyChoiceModal(`App.addBuyInvoice('${myFormDiv}');`);
			return;
		}
		this.closeModal('companyChoiceModal') // In case we get back from popCompanyChoiceModal
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let returns;
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "buyInvoicesController");
		request.append("action", "addBuyInvoice");
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("site", globals.site);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>L\'achat a bien été créé.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				// In case We come from popFormFromUrlParam We have to clean URL before refreshing
				const goTo = App.urlParam('goto', document.URL);
				if(goTo) window.history.pushState({}, document.title, window.location.href.split("?")[0]);
				App.setBuyInvoicesListPage();
				setTimeout(function(){
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else returns = '<div class="alert alert-danger" role="alert"><b>L\'achat n\'a pas été créé suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	delBuyInvoice: function(myFormDiv, event) {
		event.preventDefault(); // prevents mod form submission !
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer cet achat ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir supprimer cet achat ?",
			html: "Cette action n'est réversible que sur intervention de SNS Solutions.",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const thisBtnHtml = $(myFormDiv+' [name=deleter]').html();
				$(myFormDiv+' [name=deleter]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i> Chargement');
				const delId = $(myFormDiv+' #auto_bi').val();
				const req = "buyInvoicesController";
				const action = "delBuyInvoice";
				let query = "auto_bi=" + delId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
				let returns = "";
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						returns = '<div class="alert alert-success" role="alert"><b>Cet Achat a bien été supprimé.</b></div>';
						Swal.fire({
							title: 'Action réussie',
							html: returns,
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#1da1f5',
							timer: 2600,
							timerProgressBar: true,
						})
						if(myFormDiv != '#modBuyInvoiceForm') {
							App.setBuyInvoicesListPage();
							setTimeout(function(){
								App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
								App.clearFormFields(myFormDiv);
							}, 2600);
						}
						else {
							setTimeout(function(){
								document.location.href = '/sales/buy/invoices/';
							}, 2600);
						}
					}
					else returns = '<div class="alert alert-danger" role="alert"><b>Cet Achat n\'a pas été supprimé suite à un problème technique.</b></div>';
				}, "json").always(function(data){
					$(myFormDiv+' [name=deleter]').attr("disabled", false).html(thisBtnHtml);
					$(myFormDiv+' [data-successfail]').html(returns);
				});
			}
		});
	},
	setPlanningPage: async function() {
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#addPlanningForm', '#modPlanningForm'], 'planningController', ['order_pl', 'user_pla', 'user_pl', 'site_pl']);
		$('#addPlanningForm [data-field=site_pl]').val(globals.site).attr('data-default', globals.site)
		$('#addPlanningForm [data-field=user_pl]').val(globals.id).attr('data-default', globals.id)
		let eventsArray = []
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'planningController', action: 'getPlanningsList'}, function(data){
			if(data.ok=='ok') {
				eventsArray = data.events
				console.log('eventsArray => '+eventsArray)
			}
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("#calendarCont").html(noOne);
			}
		}, "json").done(function(data) {
			// App.buildDataTables('#dataTablePlanningsList', 25, true, 0, 'desc', 'Liste des Achats', [0,1,3,4,5,6], [2,7], '', '', '', [0,1,2,3,4,5,6,7], true);
		}).always(function() {
			// console.warn('eventsArray => '+eventsArray)
			const calendarEl = document.getElementById('calendarCont')
			const calendar = new Calendar(calendarEl, {
				plugins: [timeGridPlugin, dayGridPlugin],
				locales: [frLocale],
				locale: 'fr', // the initial locale. if not specified, uses the first one
				initialView: 'timeGridWeek',
				themeSystem: 'bootstrap5',
				initialView: 'timeGridWeek',
				height: '100%',
				allDaySlot: false,
				slotMinTime: '06:00:00',
				slotMaxTime: '22:00:00',
				headerToolbar: {
					left: 'prev,today,next',
					center: 'title',
					right: 'dayGridMonth,timeGridWeek,timeGridDay' // user can switch between the two
				},
				businessHours: {
					daysOfWeek: [ 1, 2, 3, 4, 5 ], // Monday - Friday (0=Sunday)
					startTime: '08:00', // a start time (8am in this example)
					endTime: '18:00', // an end time (6pm in this example)
				},
				nowIndicator: true,
				weekNumbers: true,
				navLinks: true,
				events: eventsArray,
				eventClick: function(info) {
					info.jsEvent.preventDefault(); // don't let the browser navigate
					if (info.event.url) {
						window.open(info.event.url, '_blank');
					}
				}
			})
			calendar.render()
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		/*
		const date_start_pl = document.querySelectorAll('[data-field="date_start_pl"]');
		if(date_start_pl.length>0) {
			date_start_pl.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					elementEnd: thisItem.closest('.row').querySelector('[data-field="date_end_pl"]'),
					lang: 'fr-FR',
					format: 'DD/MM/YYYY',
					singleMode: false,
					numberOfMonths: 2,
					numberOfColumns: 2,
					tooltipText: {
						one: 'jour',
						other: 'jours'
					},
					tooltipNumber: (totalDays) => {
						return totalDays;
					},
				});
			});
		}
		const date_end_pl = document.querySelectorAll('[data-field="date_end_pl"]');
		if(date_end_pl.length>0) {
			date_end_pl.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY',
				});
			});
		}
		*/
		this.popFormFromUrlParam();
		// Building meta tags list...
		$('head title').text('Planning - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Planning');
	},
	setEventPage: async function(eventId) {
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#modPlanningForm'], 'planningController', ['order_pl', 'user_pla', 'user_pl', 'site_pl']);
		this.fillModPlanning(eventId, null, '#modPlanningForm', null)
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		/*
		const date_start_pl = document.querySelectorAll('[data-field="date_start_pl"]');
		if(date_start_pl.length>0) {
			date_start_pl.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					elementEnd: thisItem.closest('.row').querySelector('[data-field="date_end_pl"]'),
					lang: 'fr-FR',
					format: 'DD/MM/YYYY',
					singleMode: false,
					numberOfMonths: 2,
					numberOfColumns: 2,
					tooltipText: {
						one: 'jour',
						other: 'jours'
					},
					tooltipNumber: (totalDays) => {
						return totalDays;
					},
				});
			});
		}
		const date_end_pl = document.querySelectorAll('[data-field="date_end_pl"]');
		if(date_end_pl.length>0) {
			date_end_pl.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY',
				});
			});
		}
		*/
		this.popFormFromUrlParam();
		// Building meta tags list...
		$('head title').text('Planning - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Planning');
	},
	assignUser: function(thisBtn) {
		$(thisBtn).attr("disabled", true);
		const thisForm = thisBtn.closest('form')
		const idUser = thisBtn.value
		const idEvent = thisForm.querySelector('[data-field="auto_pl"]').value
		const initUser = thisBtn.selectedOptions[0].dataset.userInitials
		const nameUser = thisBtn.selectedOptions[0].dataset.userName
		const firstNameUser = thisBtn.selectedOptions[0].dataset.userFirstname
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, idEvent: idEvent, idUser: idUser, initUser: initUser, req: 'planningController', action: 'assignUser'}, function(data){
			if(data.ok=="ok") {
				thisForm.querySelector('[data-assignments]').insertAdjacentHTML('afterbegin',`<button onclick="App.dePreAssignUser('${idUser}', this, event);" id="assignmentsBtn_"${idUser}" class="btn btn-lg btn-icon btn-dark me-1" data-id="${idUser}" data-init="${initUser}" title="Retirer l'intervenant ${nameUser} ${firstNameUser}" data-assigned-user>${initUser}</button>`)
				$(thisBtn).closest('.modal').find('.btn-close').trigger('click');
			}
			else alert("L'opération a écouée !!");
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false);
		});
	},
	deAssignUser: function(idAssign, thisBtn, event) {
		event.preventDefault();
		$(thisBtn).attr("disabled", true);
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, auto_pla: idAssign, req: 'planningController', action: 'deAssignUser'}, function(data){
			if(data.ok=="ok") $(thisBtn).remove();
			else {
				$(thisBtn).attr("disabled", false);
				alert("L'opération a écouée !!");
			}
		}, "json");
	},
	preAssignUser: function(thisBtn, event) {
		event.preventDefault();
		const thisForm = thisBtn.closest('form')
		const idUser = thisBtn.value
		const initUser = thisBtn.selectedOptions[0].dataset.userInitials
		const nameUser = thisBtn.selectedOptions[0].dataset.userName
		const firstNameUser = thisBtn.selectedOptions[0].dataset.userFirstname
		if($('#assignments_'+idUser).length) { // In case user is already assigned
			$('#assignments_'+idUser).remove(); // Hidden input
			$('#assignmentsBtn_'+idUser).remove(); // isAssigned / deAssign button
		}
		else {
			thisForm.querySelector('[data-assignments]').insertAdjacentHTML('afterbegin',`<button onclick="App.dePreAssignUser('${idUser}', this, event);" id="assignmentsBtn_${idUser}" class="btn btn-lg btn-icon btn-dark me-1" data-id="${idUser}" data-init="${initUser}" title="Retirer l'intervenant ${nameUser} ${firstNameUser}" data-assigned-user>${initUser}</button>`)
			thisForm.querySelector('[data-hidden-assignments]').insertAdjacentHTML('beforeend','<input type="hidden" name="assigned_pl[]" id="assignments_'+idUser+'" value="'+idUser+'">');
		}
	},
	dePreAssignUser: function(idUser, thisBtn, event) {
		event.preventDefault();
		const thisForm = thisBtn.closest('form')
		thisBtn.remove();
		thisForm.querySelector('#assignments_'+idUser).remove()
	},
	updatePlanningForm: (thisBtn) => {
		const thisForm = thisBtn.closest('form')
		const idOrder = thisBtn.value
		const labelOrder = thisBtn.selectedOptions[0].dataset.label
		const idClient = thisBtn.selectedOptions[0].dataset.clientId
		const clientAddress = thisBtn.selectedOptions[0].dataset.clientAddress
		thisForm.querySelector('[data-field="label_pl"]').value = labelOrder
		thisForm.querySelector('[data-field="address_pl"]').value = clientAddress
	},
	fillModPlanning: function(auto_pl, thisBtn, myFormDiv, myFormModal) {
		if(myFormModal) {
			App.clearFormFields(myFormDiv);
			$(myFormDiv+' [data-assignments]').empty()
			$(myFormDiv+' [data-hidden-assignments]').empty()
		}
		const thisBtnHtml = ''
		if(thisBtn) {
			const thisBtnHtml = $(thisBtn).html();
			$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		}
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		const thisForm = document.querySelector(myFormDiv)
		// thisForm.querySelector('[data-select-assignments]').setAttribute('onchange', `App.assignUser(${auto_pl}, this)`) // No need for this
		$.post(globals.serverAddress, {...globals.baseQuery, auto_pl: auto_pl, req: 'planningController', action: 'fillModPlanning'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.plannings)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				// Handling Assignments...
				const assignmentsCont = thisForm.querySelector('[data-assignments]')
				const assignmentsHiddenCont = thisForm.querySelector('[data-hidden-assignments]')
				data.assignments.forEach((assignment) => {
					assignmentsCont.insertAdjacentHTML('afterbegin',`<button onclick="App.deAssignUser('${assignment.auto_pla}', this, event);" id="assignmentsBtn_${assignment.user_pla}" class="btn btn-lg btn-icon btn-dark me-1" data-id="${assignment.user_pla}" data-init="${assignment.init_u}" title="Retirer l'intervenant ${assignment.name_u} ${assignment.firstname_u}" data-assigned-user>${assignment.init_u}</button>`)
					assignmentsHiddenCont.insertAdjacentHTML('beforeend','<input type="hidden" name="assigned_pl[]" id="assignments_'+assignment.user_pla+'" value="'+assignment.user_pla+'">');
				})
				document.querySelector('[data-create-ics]').addEventListener('click', (event) => {
					App.calendarFileIntervention(auto_pl, event.target)
				})
				document.querySelector('a[data-clients-link]').setAttribute('href', '/clients/'+data.plannings.client_o)
				$('a[data-orders-link]').attr('href', '/sales/quotations/'+data.plannings.order_pl)
				if(data.plannings.name_delivery!='') {
					$('a[data-deliveries-link]').attr('href', '/deliveries/'+data.plannings.order_pl).removeClass('d-none');
					$('a[data-add-delivery]').hide().closest('.row').find('[data-add-delivery-prev-col]').removeClass('col-md-9').addClass('col-12')
				}
				else $('a[data-add-delivery]').show().closest('.row').find('[data-add-delivery-prev-col]').removeClass('col-12').addClass('col-md-9')
				thisForm.querySelector('a[data-add-delivery]').setAttribute('href', '/deliveries?goto=addDeliveryModal&fields=auto_o,client_o,project_o,date_dispatch_o,date_delivery_o&idFields='+data.plannings.order_pl+','+data.plannings.client_o+','+data.plannings.project_o+','+data.plannings.date_dispatch_o+','+data.plannings.date_delivery_o)
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
				else {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.remove('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = '';
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé cette intervention !");
		}, "json").always(function(){
			if(thisBtn) $(thisBtn).attr("disabled", false).html(thisBtnHtml);
			if(myFormModal) {
				let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
				myModal.show();
			}
		});
	},
	modPlanning: function(myFormDiv, fromModal = false) {
		const anyOneAssigned = document.querySelectorAll(myFormDiv+' [data-assigned-user]')
		if(anyOneAssigned && anyOneAssigned.length>0) {
			$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
			$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
			let returns;
			let request = new FormData($(myFormDiv)[0]);
			request.append("req", "planningController");
			request.append("action", "modPlanning");
			request.append("id", globals.id);
			request.append("pwd", globals.pwd);
			request.append("type", globals.type);
			request.append("site", $(myFormDiv+' [data-field="site_pl"]').val());
			$.ajax({
				url: globals.serverAddress,
				type: 'POST',
				data: request,
				dataType: "json",
				cache: false,
				contentType: false,
				processData: false
			}).done(function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Cette Intervention a bien été mis à jour.</b></div>';
					Swal.fire({
						title: 'Action réussie',
						html: returns,
						icon: 'success',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
						timer: 2600,
						timerProgressBar: true,
					})
					if(fromModal) {
						App.setPlanningPage();
						setTimeout(function(){
							App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
							App.clearFormFields(myFormDiv);
							$(myFormDiv+' [data-assignments]').empty()
							$(myFormDiv+' [data-hidden-assignments]').empty()
						}, 2600);
					}
					setTimeout(function(){
						Swal.fire({
							title: "Envoyer cette intervention ?",
							html: "Voulez-vous envoyer cette intervention ?<br>Les adresses mails du client seront définies en destinataires par défaut et les adresses de ses contacts en copie.<br>Vous pourrez modifier tout cela...",
							icon: "question",
							confirmButtonText: "Envoyer",
							confirmButtonColor: '#1da1f5',
							showDenyButton: true,
							denyButtonText: "Pas maintenant"
						}).then(response => {
							if(response.isConfirmed && !response.isDenied && !response.isDismissed) {
								const eventDate = {
									start: data.sentData.date_start_pl,
									end: data.sentData.date_end_pl
								}
								document.querySelector('#sendMailForm [data-field="object_m"]').value = 'planning'
								document.querySelector('#sendMailForm [data-field="id_ext_m"]').value = data.auto_pl
								document.querySelector('#sendMailForm [data-field="attach_ics_m"]').value = App.createIcsFile(eventDate, data.sentData.label_pl, data.sentData.desc_pl, data.sentData.label_pl, false).icsText
								document.querySelector('#sendMailForm [data-field="to_m"]').value = data.clientMails
								document.querySelector('#sendMailForm [data-field="cc_m"]').value = data.contactMails
								document.querySelector('#sendMailForm [data-field="subject_m"]').value = `${data.sentData.label_pl} | ${data.sentData.date_start_mail} - ${data.sentData.date_end_mail}`
								tinymce.get('tinyMceMailBody').setContent(data.sentData.desc_mail)
								// tinymce.activeEditor.setContent(data.sentData.desc_mail)
								let myModal = new bootstrap.Modal(document.getElementById('sendMailModal'));
								myModal.show();
								globals.originalMailSubject = document.querySelector('#sendMailForm [data-field="subject_m"]').value
								globals.originalMailBody = data.sentData.desc_mail
							}
						})
					}, 2600);
				}
				else returns = '<div class="alert alert-danger" role="alert"><b>Cette Intervention n\'a pas été modifiée suite à un problème technique.</b></div>';
			}, "json").always(function(){
				$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
				$(myFormDiv+' [data-successfail]').html(returns);
				$(myFormDiv+' :disabled').attr("disabled", true);
			});
		}
		else alert('Veuillez assigner au moins un utilisateur !');
	},
	addPlanning: function(myFormDiv) {
		if(globals.site==0) {
			App.popCompanyChoiceModal(`App.addPlanning('${myFormDiv}');`);
			return;
		}
		this.closeModal('companyChoiceModal') // In case we get back from popCompanyChoiceModal
		const anyOneAssigned = document.querySelectorAll(myFormDiv+' [data-assigned-user]')
		if(anyOneAssigned && anyOneAssigned.length>0) {
			$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
			$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
			let returns;
			let request = new FormData($(myFormDiv)[0]);
			request.append("req", "planningController");
			request.append("action", "addPlanning");
			request.append("id", globals.id);
			request.append("pwd", globals.pwd);
			request.append("type", globals.type);
			request.append("site", globals.site);
			$.ajax({
				url: globals.serverAddress,
				type: 'POST',
				data: request,
				dataType: "json",
				cache: false,
				contentType: false,
				processData: false
			}).done(function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Cette intervention a bien été créée.</b></div>';
					Swal.fire({
						title: 'Action réussie',
						html: returns,
						icon: 'success',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
						timer: 2600,
						timerProgressBar: true,
					})
					// In case We come from popFormFromUrlParam We have to clean URL before refreshing
					const goTo = App.urlParam('goto', document.URL);
					if(goTo) window.history.pushState({}, document.title, window.location.href.split("?")[0]);
					App.setPlanningPage();
					setTimeout(function(){
						App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
						App.clearFormFields(myFormDiv);
						$(myFormDiv+' [data-assignments]').empty()
						$(myFormDiv+' [data-hidden-assignments]').empty()
						Swal.fire({
							title: "Envoyer cette intervention ?",
							html: "Voulez-vous envoyer cette intervention ?<br>Les adresses mails du client seront définies en destinataires par défaut et les adresses de ses contacts en copie.<br>Vous pourrez modifier tout cela...",
							icon: "question",
							confirmButtonText: "Envoyer",
							confirmButtonColor: '#1da1f5',
							showDenyButton: true,
							denyButtonText: "Pas maintenant"
						}).then(response => {
							if(response.isConfirmed && !response.isDenied && !response.isDismissed) {
								const eventDate = {
									start: data.sentData.date_start_pl,
									end: data.sentData.date_end_pl
								}
								document.querySelector('#sendMailForm [data-field="object_m"]').value = 'planning'
								document.querySelector('#sendMailForm [data-field="id_ext_m"]').value = data.auto_pl
								document.querySelector('#sendMailForm [data-field="attach_ics_m"]').value = App.createIcsFile(eventDate, data.sentData.label_pl, data.sentData.desc_pl, data.sentData.label_pl, false).icsText
								document.querySelector('#sendMailForm [data-field="to_m"]').value = data.clientMails
								document.querySelector('#sendMailForm [data-field="cc_m"]').value = data.contactMails
								document.querySelector('#sendMailForm [data-field="subject_m"]').value = `${data.sentData.label_pl} | ${data.sentData.date_start_mail} - ${data.sentData.date_end_mail}`
								tinymce.get('tinyMceMailBody').setContent(data.sentData.desc_mail)
								// tinymce.activeEditor.setContent(data.sentData.desc_mail)
								let myModal = new bootstrap.Modal(document.getElementById('sendMailModal'));
								myModal.show();
								globals.originalMailSubject = document.querySelector('#sendMailForm [data-field="subject_m"]').value
								globals.originalMailBody = data.sentData.desc_mail
							}
						})
					}, 2600);
				}
				else returns = '<div class="alert alert-danger" role="alert"><b>Cette intervention n\'a pas été créée suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
				$(myFormDiv+' [data-successfail]').html(returns);
				$(myFormDiv+' :disabled').attr("disabled", true);
			});
		}
		else alert('Veuillez assigner au moins un utilisateur !');
	},
	delPlanning: function(myFormDiv, event, fromModal = false) {
		event.preventDefault(); // prevents mod form submission !
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce devis ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir supprimer cette intervention ?",
			html: "Cette action n'est réversible que sur intervention de SNS Solutions.",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const thisBtnHtml = $(myFormDiv+' [name=deleter]').html();
				$(myFormDiv+' [name=deleter]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i> Chargement');
				const delId = $(myFormDiv+' #auto_pl').val();
				const query = "auto_pl=" + delId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=planningController&action=delPlanning";
				let returns = "";
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						returns = '<div class="alert alert-success" role="alert"><b>Cette intervention a bien été supprimée.</b></div>';
						if(fromModal) {
							Swal.fire({
								title: 'Action réussie',
								html: returns,
								icon: 'success',
								confirmButtonText: 'Ok',
								confirmButtonColor: '#1da1f5',
								timer: 2600,
								timerProgressBar: true,
							})
							App.setPlanningPage();
							setTimeout(function(){
								App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
								App.clearFormFields(myFormDiv);
								$(myFormDiv+' [data-assignments]').empty()
								$(myFormDiv+' [data-hidden-assignments]').empty()
							}, 2600);
						}
						else {
							setTimeout(function(){
								document.location.href = '/planning';
							}, 1600);
						}
					}
					else returns = '<div class="alert alert-danger" role="alert"><b>Cette intervention n\'a pas été supprimée suite à un problème technique.</b></div>';
				}, "json").always(function(data){
					$(myFormDiv+' [name=deleter]').attr("disabled", false).html(thisBtnHtml);
					$(myFormDiv+' [data-successfail]').html(returns);
				});
			}
		});
	},
	setDeliveriesListPage: async function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#addDeliveryForm', '#modDeliveryForm'], 'deliveriesController', ['auto_o', 'client_o', 'project_o', 'user_o', 'site_o']);
		$('#addDeliveryForm [data-field=site_o]').val(globals.site).attr('data-default', globals.site)
		$('#addDeliveryForm [data-field=user_o]').val(globals.id).attr('data-default', globals.id)
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'deliveriesController', action: 'getDeliveriesList'}, function(data){
			if(data.ok=='ok') {
				$("[data-table-view]").html(data.snippet);
				$("[data-kanban-view]").html(data.snippetPhone);
				App.buildDataFilterSearch();
			}
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("[data-table-view]").html(noOne);
				$("[data-kanban-view]").html(noOne);
			}
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableDeliveriesList', 25, true, 0, 'desc', 'Liste des Achats', [0,1,3,4,5,6], [2,7], '', '', '', [0,1,2,3,4,5,6,7], true);
		}).always(function() {
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
		});
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		const date_dispatch_o = document.querySelectorAll('[data-field="date_dispatch_o"]');
		if(date_dispatch_o.length>0) {
			date_dispatch_o.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					elementEnd: thisItem.closest('.row').querySelector('[data-field="date_delivery_o"]'),
					lang: 'fr-FR',
					format: 'DD/MM/YYYY',
					singleMode: false,
					numberOfMonths: 2,
					numberOfColumns: 2,
					tooltipText: {
						one: 'jour',
						other: 'jours'
					},
					tooltipNumber: (totalDays) => {
						return totalDays;
					},
				});
			});
		}
		const date_delivery_o = document.querySelectorAll('[data-field="date_delivery_o"]');
		if(date_delivery_o.length>0) {
			date_delivery_o.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		this.setupCanvases()
		this.popFormFromUrlParam();
		// Building meta tags list...
		$('head title').text('Bons de Livraison - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Bons de Livraison');
	},
	setDeliveryPage: async function(idOrder) {
		const myFormDiv = '#modDeliveryForm';
		const generateSelectBoxes = await this.getFormsSelectBoxes([myFormDiv], 'deliveriesController', ['auto_o', 'client_o', 'project_o', 'user_o', 'site_o']);
		this.fillModDelivery(idOrder, null, myFormDiv, null)
		// Dealing with upload buttons and inputs logics
		this.buildUploadZone()
		const date_dispatch_o = document.querySelectorAll('[data-field="date_dispatch_o"]');
		if(date_dispatch_o.length>0) {
			date_dispatch_o.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					elementEnd: thisItem.closest('.row').querySelector('[data-field="date_delivery_o"]'),
					lang: 'fr-FR',
					format: 'DD/MM/YYYY',
					singleMode: false,
					numberOfMonths: 2,
					numberOfColumns: 2,
					tooltipText: {
						one: 'jour',
						other: 'jours'
					},
					tooltipNumber: (totalDays) => {
						return totalDays;
					},
				});
			});
		}
		const date_delivery_o = document.querySelectorAll('[data-field="date_delivery_o"]');
		if(date_delivery_o.length>0) {
			date_delivery_o.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'fr-FR',
					format: 'DD/MM/YYYY'
				});
			});
		}
		this.setupCanvases()
		this.popFormFromUrlParam();
		// Building meta tags list...
		$('head title').text('Bon de livraison - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Bon de livraison');
	},
	fillModDelivery: function(auto_o, thisBtn, myFormDiv, myFormModal) {
		this.clearFormFields(myFormDiv);
		const thisForm = document.querySelector(myFormDiv)
		thisForm.querySelector('[data-order-lines]').innerHTML = '';
		// const myFormId = (myFormDiv.includes('#')) ? myFormDiv.replace('#','') : myFormDiv;
		let thisBtnHtml = '';
		if(thisBtn) {
			thisBtnHtml = $(thisBtn).html();
			$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		}
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_o: auto_o, req: 'deliveriesController', action: 'fillModDelivery'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.orders)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				const orderProductsCont = thisForm.querySelector('[data-order-lines]')
				orderProductsCont.classList.add('list-group', 'list-group-numbered', 'my-2', 'px-2')
				data.orderLines.forEach((item, index) => {
					console.log(index, item.auto_ol, item.name_ol, item.desc_ol)
					if(item.product_ol!=0) { // It's a Product
						orderProductsCont.innerHTML += `
							<li class="list-group-item list-group-item-action list-group-item-primary d-flex justify-content-between align-items-start">
								<input type="hidden" name="auto_ol[]" data-field="auto_ol" value="0" data-order-line-id>
								<div class="container-fluid">
									<div class="row">
										<div class="col-md-9">
											<div class="ms-2 me-auto">
												<div class="fw-bold">${item.reference_p}</div>
												${item.name_ol} | <b>${item.type_p}</b><br>
												${item.desc_ol}
											</div>
										</div>
										<div class="col-md-3">
											<div class="input-group">
												<span class="input-group-text">Quantité</span>
												<input class="form-control" name="howmany_ol[]" type="number" value="${item.howmany_ol}" placeholder="Quantité" data-order-line-quantity" readonly/>
											</div>
										</div>
									</div>
								</div>
							</li>
						`;
					}
					else { // It's a category line
						orderProductsCont.insertAdjacentHTML('beforeend',`
							<li class="list-group-item list-group-item-action list-group-item-primary d-flex justify-content-between align-items-start">
								<div class="col mx-2">
									<h3 class="m-0">${item.desc_ol}</h3>
								</div>
							</li>
						`);
					}
				})
				document.querySelector(myFormDiv+' a[data-quotations-link]').setAttribute('href', '/sales/quotations/'+data.orders.auto_o)
				document.querySelector(myFormDiv+' a[data-clients-link]').setAttribute('href', '/clients/'+data.orders.client_o)
				document.querySelector(myFormDiv+' a[data-projects-link]').setAttribute('href', '/sales/projects/'+data.orders.project_o)
				const docElem = document.querySelector('a[data-docs-link]')
				if(docElem) docElem.setAttribute('href', globals.serverBaseUrl+'docs/delivery/'+data.orders.order_doc_delivery)
				// Handling Attachments...
				if(data.snippetUploads!='') {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.add('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = `<ul class="mb-0">${data.snippetUploads}</ul>`;
				}
				else {
					const fileListCont = document.querySelector(`${myFormDiv} [data-upload-list]`);
					fileListCont.classList.remove('alert', 'alert-primary', 'my-1')
					fileListCont.innerHTML = '';
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce bon de livraison !");
		}, "json").always(function(){
			if(thisBtn) $(thisBtn).attr("disabled", false).html(thisBtnHtml);
			if(myFormModal) {
				let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
				myModal.show();
			}
		});
	},
	updateDeliveryForm: (thisBtn) => {
		const thisForm = thisBtn.closest('form')
		const idOrder = thisBtn.value
		const labelOrder = thisBtn.selectedOptions[0].dataset.label
		if(labelOrder) thisForm.querySelector('[data-field="label_delivery"]').value = 'BL : '+labelOrder
		else thisForm.querySelector('[data-field="label_delivery"]').value = 'BL : '+labelOrder
		const orderProductsCont = thisForm.querySelector('[data-order-lines]')
		orderProductsCont.innerHTML = '';
		$.post(globals.serverAddress, {...globals.baseQuery, auto_o: idOrder, req: 'deliveriesController', action: 'fillModDelivery'}, (data) => {
			if(data.ok=="ok") {
				orderProductsCont.classList.add('list-group', 'list-group-numbered', 'my-2', 'px-2')
				data.orderLines.forEach((item) => {
					if(item.product_ol!=0) { // It's a Product
						orderProductsCont.innerHTML += `
							<li class="list-group-item list-group-item-action list-group-item-primary d-flex justify-content-between align-items-start">
								<input type="hidden" name="auto_ol[]" data-field="auto_ol" value="0" data-order-line-id>
								<div class="container-fluid">
									<div class="row">
										<div class="col-md-9">
											<div class="ms-2 me-auto">
												<div class="fw-bold">${item.reference_p}</div>
												${item.name_ol} | <b>${item.type_p}</b><br>
												${item.desc_ol}
											</div>
										</div>
										<div class="col-md-3">
											<div class="input-group">
												<span class="input-group-text">Quantité</span>
												<input class="form-control" name="howmany_ol[]" type="number" value="${item.howmany_ol}" placeholder="Quantité" data-order-line-quantity" readonly/>
											</div>
										</div>
									</div>
								</div>
							</li>
						`;
					}
					else { // It's a category line
						orderProductsCont.insertAdjacentHTML('beforeend',`
							<li class="list-group-item list-group-item-action list-group-item-primary d-flex justify-content-between align-items-start">
								<div class="col mx-2">
									<h3 class="m-0">${item.desc_ol}</h3>
								</div>
							</li>
						`);
					}
				})
			}
		}, "json");
	},
	modDelivery: function(myFormDiv, fromModal = false, popSomething = false, what2Pop = null) {
		const myForm = document.querySelector(myFormDiv)
		const canvasClientSign = myForm.querySelector('[name="client_sign_delivery"]')
		const canvasUserSign = myForm.querySelector('[name="user_sign_delivery"]')
		if(!this.isCanvasEmpty(canvasClientSign) && !this.isCanvasEmpty(canvasUserSign)) {
			$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
			$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
			const clientSignBase64Enc = canvasClientSign.toDataURL('image/png');
			const userSignBase64Enc = canvasUserSign.toDataURL('image/png');
			let returns;
			let request = new FormData($(myFormDiv)[0]);
			request.append("req", "deliveriesController");
			request.append("action", "modDelivery");
			request.append("id", globals.id);
			request.append("pwd", globals.pwd);
			request.append("type", globals.type);
			request.append("site", $(myFormDiv+' [data-field="site_o"]').val());
			request.append("type_doc", "delivery");
			request.append("client_sign_delivery", clientSignBase64Enc);
			request.append("user_sign_delivery", userSignBase64Enc);
			$.ajax({
				url: globals.serverAddress,
				type: 'POST',
				data: request,
				dataType: "json",
				cache: false,
				contentType: false,
				processData: false
			}).done(function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Le bon de livraison a bien été mis à jour.</b></div>';
					Swal.fire({
						title: 'Action réussie',
						html: returns,
						icon: 'success',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
						timer: 2600,
						timerProgressBar: true,
					})
					if(fromModal) {
						App.setDeliveriesListPage();
						setTimeout(function(){
							App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
							App.clearFormFields(myFormDiv);
							myForm.querySelector('[data-order-lines]').innerHTML = '';
						}, 2600);
					}
					else {
						document.querySelector('a[data-clients-link]').setAttribute('href', '/clients/'+data.sentData.client_o)
						document.querySelector('a[data-docs-link]').setAttribute('href', data.pdfLink)
					}
					if(popSomething) window.location.href = `${what2Pop}&idFields=${data.sentData.client_o},${data.auto_o}` // what2Pop=`/invoices?goto=addInvoiceModal&fields=client_o,order_o`
					else {
						setTimeout(function(){
							Swal.fire({
								title: "Envoyer le bon de livraison ?",
								html: "Voulez-vous envoyer ce bon de livraison ?<br>Les adresses mails du client seront définies en destinataires par défaut et les adresses de ses contacts en copie.<br>Vous pourrez modifier tout cela...",
								icon: "question",
								confirmButtonText: "Envoyer",
								confirmButtonColor: '#1da1f5',
								showDenyButton: true,
								denyButtonText: "Pas maintenant"
							}).then(response => {
								if(response.isConfirmed && !response.isDenied && !response.isDismissed) {
									App.popSendMail('delivery', data.auto_o, data.pdfPath+data.pdfName, data.clientMails, data.contactMails, data.sentData.label_delivery+' #'+data.sentData.name_delivery, {subject: 'subject_m_delivery', body: 'body_m_delivery'}, data.sentData.client_o)
								}
							})
						}, 2600);
					}
				}
				else returns = '<div class="alert alert-danger" role="alert"><b>Le bon de livraison n\'a pas été modifié suite à un problème technique.</b></div>';
			}, "json").always(function(){
				$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
				$(myFormDiv+' [data-successfail]').html(returns);
				$(myFormDiv+' :disabled').attr("disabled", true);
			});
		}
		else {
			Swal.fire({
				title: 'Attention !',
				html: '<b class="text-danger">Vous devez impérativement signer et faire signer le bon de livraison.</b>',
				icon: 'error',
				confirmButtonText: 'Ok',
				confirmButtonColor: 'red',
				timer: 2600,
				timerProgressBar: true,
			})
			if(this.isCanvasEmpty(canvasClientSign)) {
				canvasClientSign.classList.add('field-error', 'shadow-danger');
				canvasClientSign.classList.remove('shadow');
			}
			else $(canvasClientSign).removeClass('field-error, shadow-danger').addClass('shadow')
			if(this.isCanvasEmpty(canvasUserSign)) {
				canvasUserSign.classList.add('field-error', 'shadow-danger');
				canvasUserSign.classList.remove('shadow');
			}
			else $(canvasUserSign).removeClass('field-error, shadow-danger').addClass('shadow')
		}
	},
	addDelivery: function(myFormDiv) {
		if(globals.site==0) {
			App.popCompanyChoiceModal(`App.addDelivery('${myFormDiv}');`);
			return;
		}
		this.closeModal('companyChoiceModal') // In case we get back from popCompanyChoiceModal
		const myForm = document.querySelector(myFormDiv)
		const canvasClientSign = myForm.querySelector('[name="client_sign_delivery"]')
		const canvasUserSign = myForm.querySelector('[name="user_sign_delivery"]')
		if(!this.isCanvasEmpty(canvasClientSign) && !this.isCanvasEmpty(canvasUserSign)) {
			$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
			$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
			const clientSignBase64Enc = canvasClientSign.toDataURL('image/png');
			const userSignBase64Enc = canvasUserSign.toDataURL('image/png');
			let returns;
			let request = new FormData($(myFormDiv)[0]);
			request.append("req", "deliveriesController");
			request.append("action", "addDelivery");
			request.append("id", globals.id);
			request.append("pwd", globals.pwd);
			request.append("type", globals.type);
			request.append("site", globals.site);
			request.append("type_doc", "delivery");
			request.append("client_sign_delivery", clientSignBase64Enc);
			request.append("user_sign_delivery", userSignBase64Enc);
			$.ajax({
				url: globals.serverAddress,
				type: 'POST',
				data: request,
				dataType: "json",
				cache: false,
				contentType: false,
				processData: false
			}).done(function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Ce bon de livraison a bien été créé.</b></div>';
					Swal.fire({
						title: 'Action réussie',
						html: returns,
						icon: 'success',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
						timer: 2600,
						timerProgressBar: true,
					})
					// In case We come from popFormFromUrlParam We have to clean URL before refreshing
					const goTo = App.urlParam('goto', document.URL);
					if(goTo) window.history.pushState({}, document.title, window.location.href.split("?")[0]);
					App.setDeliveriesListPage();
					setTimeout(function(){
						App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
						App.clearFormFields(myFormDiv);
						myForm.querySelector('[data-order-lines]').innerHTML = '';
						Swal.fire({
							title: "Envoyer le bon de livraison ?",
							html: "Voulez-vous envoyer ce bon de livraison ?<br>Les adresses mails du client seront définies en destinataires par défaut et les adresses de ses contacts en copie.<br>Vous pourrez modifier tout cela...",
							icon: "question",
							confirmButtonText: "Envoyer",
							confirmButtonColor: '#1da1f5',
							showDenyButton: true,
							denyButtonText: "Pas maintenant"
						}).then(response => {
							if(response.isConfirmed && !response.isDenied && !response.isDismissed) {
								App.popSendMail('delivery', data.auto_o, data.pdfPath+data.pdfName, data.clientMails, data.contactMails, data.sentData.label_delivery+' #'+data.name_delivery, {subject: 'subject_m_delivery', body: 'body_m_delivery'}, data.sentData.client_o)
							}
						})
					}, 2600);
				}
				else returns = '<div class="alert alert-danger" role="alert"><b>Le bon de livraison n\'a pas été créé suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
				$(myFormDiv+' [data-successfail]').html(returns);
				$(myFormDiv+' :disabled').attr("disabled", true);
			});
		}
		else {
			Swal.fire({
				title: 'Attention !',
				html: '<b class="text-danger">Vous devez impérativement signer et faire signer le bon de livraison.</b>',
				icon: 'error',
				confirmButtonText: 'Ok',
				confirmButtonColor: 'red',
				timer: 2600,
				timerProgressBar: true,
			})
			if(this.isCanvasEmpty(canvasClientSign)) {
				canvasClientSign.classList.add('field-error', 'shadow-danger');
				canvasClientSign.classList.remove('shadow');
			}
			else $(canvasClientSign).removeClass('field-error, shadow-danger').addClass('shadow')
			if(this.isCanvasEmpty(canvasUserSign)) {
				canvasUserSign.classList.add('field-error', 'shadow-danger');
				canvasUserSign.classList.remove('shadow');
			}
			else $(canvasUserSign).removeClass('field-error, shadow-danger').addClass('shadow')
		}
	},
	delDelivery: function(myFormDiv, event, fromModal = false) {
		event.preventDefault(); // prevents mod form submission !
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce devis ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir supprimer ce bon de livraison ?",
			html: "Cette action n'est réversible que sur intervention de SNS Solutions.",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const thisBtnHtml = $(myFormDiv+' [name=deleter]').html();
				$(myFormDiv+' [name=deleter]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i> Chargement');
				const delId = $(myFormDiv+' #auto_o').val();
				const req = "planningController";
				const action = "delDelivery";
				let query = "auto_o=" + delId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
				let returns = "";
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						returns = '<div class="alert alert-success" role="alert"><b>Ce bon de livraison a bien été supprimé.</b></div>';
						if(fromModal) {
							Swal.fire({
								title: 'Action réussie',
								html: returns,
								icon: 'success',
								confirmButtonText: 'Ok',
								confirmButtonColor: '#1da1f5',
								timer: 2600,
								timerProgressBar: true,
							})
							App.setDeliveriesListPage();
							setTimeout(function(){
								App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
								App.clearFormFields(myFormDiv);
								myForm.querySelector('[data-order-lines]').innerHTML = '';
							}, 2600);
						}
						else {
							setTimeout(function(){
								document.location.href = '/deliveries/';
							}, 2600);
						}
					}
					else returns = '<div class="alert alert-danger" role="alert"><b>Ce bon de livraison n\'a pas été supprimé suite à un problème technique.</b></div>';
				}, "json").always(function(data){
					$(myFormDiv+' [name=deleter]').attr("disabled", false).html(thisBtnHtml);
					$(myFormDiv+' [data-successfail]').html(returns);
				});
			}
		});
	},
	setLinksListPage: async function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		await this.getFormsSelectBoxes(['#addLinkForm'], 'linksController', ['client_l', 'site_l']);
		document.querySelector('#addLinkForm #site_l').value = globals.site;
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'linksController', action: 'listLinks'}, function(data){
			$("#linksListCont").html(data.snippet);
		}, "json").done(function() {
			App.buildDataTables('#dataTableLinksList', 25, true, 0, 'asc', 'CRM SecureBoat Boîte à liens', [0,1,3,4], [2], '', '', '', [0,1,2,3,4], true);
		}).always(function() {
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
		});
		// Building meta tags list...
		$('head title').text('Boîte à Liens - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Boîte à Liens');
	},
	setLinksSearchPage: async function(search) {
		await this.getFormsSelectBoxes(['#addLinkForm'], 'linksController', ['site_l']);
		document.querySelector('#addLinkForm #site_l').value = globals.site;
		$.post(globals.serverAddress, {...globals.baseQuery, search: search, req: 'linksController', action: 'searchLinks'}, function(data){
			$("#linksListCont").html(data.snippet);
		}, "json").done(function() {
			App.buildDataTables('#dataTableLinksList', 25, true, 0, 'asc', 'CRM SecureBoat Boîte à liens', [0,1,3,4], [2], '', '', '', [0,1,2,3,4], true);
		});
		// Building meta tags list...
		$('head title').text('Boîte à Liens - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Boîte à Liens');
	},
	fillModLink: async function(auto_l, name_l, url_l, client_l, site_l) {
		const previousFormId = globals.currentFormId;
		globals.currentFormId = '#modLinkForm';
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#modLinkForm'], 'linksController', ['client_l', 'site_l']);
		$('#modLinkForm #auto_l').val(auto_l);
		$('#modLinkForm #site_l').val(site_l);
		$('#modLinkForm #name_l').val(name_l);
		$('#modLinkForm #url_l').val(url_l);
		$('#modLinkForm #client_l').val(client_l);
		let myModal = new bootstrap.Modal(document.getElementById('modLinkModal'));
		myModal.show();
	},
	modLink: function(myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=linksController&action=modLink";
		let returns = "";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Le lien a bien été mis à jour.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				App.setLinksListPage();
				setTimeout(function(){
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Le lien n\'a pas été modifié suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	addLink: function(myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=linksController&action=addLink";
		let returns = "";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Le lien a bien été ajouté.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				App.setLinksListPage();
				setTimeout(function(){
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Le lien n\'a pas été ajouté, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	delLink: function(myFormDiv, event) {
		event.preventDefault(); // prevents mod form submission !
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce lien ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir supprimer ce lien ?",
			html: "Cette action n'est réversible que sur intervention de SNS Solutions.",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				$(myFormDiv+' [name=deleter]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
				const delId = $(myFormDiv+' #auto_l').val();
				let query = "auto_l=" + delId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=linksController&action=delLink";
				let returns = "";
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Le lien a bien été supprimé.</b></div>';
						Swal.fire({
							title: 'Action réussie',
							html: returns,
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#1da1f5',
							timer: 2600,
							timerProgressBar: true,
						})
						App.setLinksListPage();
						setTimeout(function(){
							App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
							App.clearFormFields(myFormDiv);
						}, 2600);
					}
					else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Le lien n\'a pas été supprimé suite à un problème technique.</b></div>';
				}, "json").always(function(data){
					$(myFormDiv+' [name=deleter]').attr("disabled", false).html('<i class="fa fa-eraser me-1"></i>Supprimer');
					$(myFormDiv+' [data-successfail]').html(returns);
				});
			}
		})
	},
	setMailsListPage: async function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'mailController', action: 'listMails'}, function(data){
			if(data.ok=='ok') $("#mailsListCont").html(data.snippet);
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("#mailsListCont").html(noOne);
			}
		}, "json").done(function() {
			App.buildDataTables('#dataTableMailsList', 25, true, 5, 'desc', 'CRM SecureBoat Liste eMails', [0,1,2,3,4,6], [5], '', '', '', [0,1,2,3,4,5,6], true);
		}).always(function() {
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
		});
		// Building meta tags list...
		$('head title').text('Liste eMails - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Liste eMails');
	},
	setMailsSearchPage: async function(search) {
		$.post(globals.serverAddress, {...globals.baseQuery, search: search, req: 'mailController', action: 'searchMails'}, function(data){
			if(data.ok=='ok') $("#mailsListCont").html(data.snippet);
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("#mailsListCont").html(noOne);
			}
		}, "json").done(function() {
			App.buildDataTables('#dataTableMailsList', 25, true, 5, 'desc', 'CRM SecureBoat Liste eMails', [0,1,2,3,4,6], [5], '', '', '', [0,1,2,3,4,5,6], true);
		});
		// Building meta tags list...
		$('head title').text('Liste eMails - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Liste eMails');
	},
	sendMail: (myFormDiv) => {
		const thisBtn = $(myFormDiv+' [type="submit"]')
		const thisBtnHtml = thisBtn.html()
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=mailController&action=sendMail";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=='ok') {
				Swal.fire({
					title: 'Demande traitée',
					html: 'Le message a bien été envoyé',
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				setTimeout(function(){
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
					App.emptyActiveTinyMce('tinyMceMailBody');
				}, 2100);
			}
			else {
				Swal.fire({
					title: 'Une erreur est survenue',
					html: 'Le message n\'a pas été envoyé.<br>Merci de réssayer plus tard...',
					icon: 'error',
					confirmButtonText: "Ok",
					confirmButtonColor: 'red',
					timer: 2600,
					timerProgressBar: true,
				})
			}
		}, "json").always(function(){
			thisBtn.attr("disabled", false).html(thisBtnHtml);
		});
	},
	emptyActiveTinyMce: (editorId, event) => {
		if(event) event.preventDefault();
		tinymce.get(editorId).resetContent(globals.tinyInitialContent)
		// tinymce.activeEditor.resetContent(globals.tinyInitialContent) // Not always acccurate when you have several ones
	},
	previewMail: function(auto_m, thisBtn) {
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		$.post(globals.serverAddress, {...globals.baseQuery, auto_m: auto_m, req: 'mailController', action: 'previewMail'}, function(data){
			$('#previewMailSubjectCont').html(data.mails.subject_m);
			$('#previewMailCont').html(data.mails.body_m);
			let attachments = '<hr><ul class="list-group">';
			const attachments_m = data.mails.attachments_m.split(';')
			attachments_m.forEach(elem => {
				attachments += `
					<a class="list-group-item list-group-item-action active" href="${elem}" target="_blank">
						<i class="fa fa-file-circle-check me-1"></i>${elem.substring(elem.lastIndexOf('/')+1)}
					</a>
				`
			})
			attachments += '</ul>';
			if(attachments_m.length > 0) $('#previewMailCont').append(attachments);
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			let myModal = new bootstrap.Modal(document.getElementById('previewMailModal'));
			myModal.show();
		});
	},
	popSendMail: function (object_m, id_ext_m, attachments_m, to_m, cc_m, subject_m, mailDefaults = {subject:'',body:''}, client_m = 0, type_d = 'attachment') {
		if(globals.site==0) {
			App.popCompanyChoiceModal(`App.popSendMail('${object_m}', '${id_ext_m}', '${attachments_m}', '${to_m}', '${cc_m}', '${subject_m}', '${mailDefaults}', '${client_m}, '${type_d}');`);
			return;
		}
		this.closeModal('companyChoiceModal') // In case we get back from popCompanyChoiceModal
		const mailForm = document.querySelector('#sendMailForm')
		mailForm.querySelector('[data-field="object_m"]').value = object_m
		mailForm.querySelector('[data-field="id_ext_m"]').value = id_ext_m
		mailForm.querySelector('[data-field="client_m"]').value = client_m
		mailForm.querySelector('[data-field="attachments_m"]').value = attachments_m // data.pdfPath+data.pdfName
		mailForm.querySelector('[data-field="type_d"]').value = type_d // type in documents table
		mailForm.querySelector('[data-field="to_m"]').value = to_m
		mailForm.querySelector('[data-field="cc_m"]').value = cc_m
		if(App.settings.siteSettings) {
			mailForm.querySelector('[data-field="subject_m"]').value = (App.settings.siteSettings[mailDefaults.subject] && App.settings.siteSettings[mailDefaults.subject]!='') ? App.settings.siteSettings[mailDefaults.subject]+' '+subject_m : subject_m
			tinymce.get('tinyMceMailBody').setContent(App.settings.siteSettings[mailDefaults.body] || '')
			// tinymce.activeEditor.setContent(App.settings.siteSettings[mailDefaults.body] || '')
		}
		else mailForm.querySelector('[data-field="subject_m"]').value = subject_m;
		let myModal = new bootstrap.Modal(document.getElementById('sendMailModal'));
		myModal.show();
		globals.originalMailSubject = mailForm.querySelector('[data-field="subject_m"]').value
		globals.originalMailBody = App.settings.siteSettings[mailDefaults.body] || ''
	},
	setMailsModelsListPage: async function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		await this.getFormsSelectBoxes(['#addMailForm', '#modMailForm'], 'mailController', ['site_mm']);
		document.querySelector('#addMailForm #site_mm').value = globals.site;
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'mailController', action: 'listMailsModels'}, function(data){
			if(data.ok=='ok') $("#mailsListCont").html(data.snippet);
			else {
				const noOne = '<div class="alert alert-warning text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Il n\'y a aucun élément à afficher</div>'
				$("#mailsListCont").html(noOne);
			}
		}, "json").done(function() {
			App.buildDataTables('#dataTableMailsList', 25, true, 0, 'asc', 'CRM SecureBoat eMails Types', [0,1,3,4], [2], '', '', '', [0,1,2,3,4], true);
		}).always(function() {
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
		});
		this.buildTinyMceTextArea('addMailFormBodyInput')
		this.buildTinyMceTextArea('modMailFormBodyInput')
		// Building meta tags list...
		$('head title').text('eMails Types - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | eMails Types');
	},
	fillModMail: function(auto_mm, thisBtn, myFormDiv, myFormModal) {
		App.clearFormFields(myFormDiv);
		const thisBtnHtml = $(thisBtn).html();
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, auto_mm: auto_mm, req: 'mailController', action: 'fillModMail'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.mailsModels)) {
					$(myFormDiv+' [data-field="'+key+'"]').val(value);
				}
				tinymce.get('modMailFormBodyInput').setContent(data.mailsModels?.body_mm)
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce modèle !");
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
		});
	},
	setMailModel: function(thisBtn, event) {
		event.preventDefault();
		const myForm = thisBtn.closest('form')
		const auto_mm = thisBtn.value
		if(auto_mm>0) {
			$.post(globals.serverAddress, {...globals.baseQuery, auto_mm: auto_mm, req: 'mailController', action: 'fillModMail'}, function(data){
				// Here We don't check if it's ok because if auto_mm=0 We should empty next fields
				myForm.querySelector('[data-field=subject_m]').value = `${data.mailsModels?.subject_mm} ${globals.originalMailSubject}`;
				// for (const [key, value] of Object.entries(data.mailsModels)) myForm.querySelector('[data-field="'+key+'"]')?.value(value);
				tinymce.get('tinyMceMailBody').setContent(data.mailsModels?.body_mm)
				// tinymce.activeEditor.setContent(data.mailsModels?.body_mm)
			}, "json");
		}
		else {
			document.querySelector('#sendMailForm [data-field="subject_m"]').value = globals.originalMailSubject
			tinymce.get('tinyMceMailBody').setContent(globals.originalMailBody)
		}
	},
	modMail: function(myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=mailController&action=modMail";
		let returns = "";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Le modèle a bien été mis à jour.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				App.setMailsModelsListPage();
				App.getFormsSelectBoxes(['#sendMailForm'], 'mailController', ['mail_model'])
				setTimeout(function(){
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
					App.emptyActiveTinyMce('modMailFormBodyInput')
				}, 2600);
			}
			else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Le modèle n\'a pas été modifié suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	addMail: function(myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=mailController&action=addMail";
		let returns = "";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Le modèle a bien été ajouté.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				App.setMailsModelsListPage();
				App.getFormsSelectBoxes(['#sendMailForm'], 'mailController', ['mail_model'])
				setTimeout(function(){
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
					App.emptyActiveTinyMce('addMailFormBodyInput')
				}, 2600);
			}
			else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Le modèle n\'a pas été ajouté, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	delMail: function(myFormDiv, event) {
		event.preventDefault(); // prevents mod form submission !
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce modèle ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir supprimer ce modèle ?",
			html: "Cette action n'est réversible que sur intervention de SNS Solutions.",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				$(myFormDiv+' [name=deleter]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
				const delId = $(myFormDiv+' #auto_mm').val();
				let query = "auto_mm=" + delId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=mailController&action=delMail";
				let returns = "";
				$.post(globals.serverAddress, query, function(data){
					if(data.ok=="ok") {
						returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Le modèle a bien été supprimé.</b></div>';
						Swal.fire({
							title: 'Action réussie',
							html: returns,
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#1da1f5',
							timer: 2600,
							timerProgressBar: true,
						})
						App.setMailsModelsListPage();
						App.getFormsSelectBoxes(['#sendMailForm'], 'mailController', ['mail_model'])
						setTimeout(function(){
							App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
							App.clearFormFields(myFormDiv);
						}, 2600);
					}
					else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Le modèle n\'a pas été supprimé suite à un problème technique.</b></div>';
				}, "json").always(function(data){
					$(myFormDiv+' [name=deleter]').attr("disabled", false).html('<i class="fa fa-eraser me-1"></i>Supprimer');
					$(myFormDiv+' [data-successfail]').html(returns);
				});
			}
		})
	},
	setUploadsListPage: function(thisBtn) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		// this.getFormsSelectBoxes(['#addUploadForm'], 'uploadsController', ['client_l', 'site_l']);
		$.post(globals.serverAddress, {...globals.baseQuery, req: 'uploadsController', action: 'listUploads'}, function(data){
			$("#uploadsListCont").html(data.snippet);
		}, "json").done(function() {
			App.buildDataTables('#dataTableUploadsList', 25, true, 0, 'asc', 'CRM SecureBoat Coffre fort', [0,3,4], [1,2], '', '', '', [0,1,2,3,4], true);
		}).always(function() {
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-sync"></i>');
		});
		// Building meta tags list...
		$('head title').text('Coffre fort - CRM SecureBoat');
		$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Coffre fort');
	},
	addUpload: function(myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		$(myFormDiv+' [data-successfail]').append('<div class="alert alert-info text-center text-large" role="alert">Veuillez patienter pendant le traitement de la demande...<hr><i class="fa fa-2x fa-spinner fa-pulse"></i></div>');
		let returns = "";
		let request = new FormData($(myFormDiv)[0]);
		request.append("id", globals.id);
		request.append("type", globals.type);
		request.append("pwd", globals.pwd);
		request.append("site", globals.site);
		request.append("req", "uploadsController");
		request.append("action", "addUpload");
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success text-center text-large" role="alert"><i class="fa fa-check-circle me-1"></i>Les fichiers ont bien été intégrés.</div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				setTimeout(function(){
					App.setUploadsListPage();
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else
				returns = '<div class="alert alert-danger text-center text-large" role="alert"><i class="fa fa-times-circle me-1"></i>Au moins un fichier n\'a pas été intégré, suite à un problème technique.</div>';
		}).always(function(){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-upload me-1"></i>Envoyer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	fillModUpload: function(id_d, event, myFormDiv, myFormModal) {
		event.preventDefault(); // Here buttons pressed are in modClientForm !
		App.clearFormFields(myFormDiv);
		const previousFormId = globals.currentFormId;
		globals.currentFormId = myFormDiv;
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
		$.post(globals.serverAddress, {...globals.baseQuery, id_d: id_d, req: 'uploadsController', action: 'fillModUpload'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.uploads)) {
					$(myFormDiv+' #'+key).val(value);
				}
				$(myFormDiv+' #name_d').html(data.uploads.name_d);
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce fichier joint !");
		}, "json").always(function(){
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
		});
	},
	modUploads: function(myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		$(myFormDiv+' [data-successfail]').append('<div class="alert alert-info text-center text-large" role="alert">Veuillez patienter pendant le traitement de la demande...<hr><i class="fa fa-2x fa-spinner fa-pulse"></i></div>');
		let returns = "";
		let request = new FormData($(myFormDiv)[0]);
		request.append("id", globals.id);
		request.append("type", globals.type);
		request.append("pwd", globals.pwd);
		request.append("site", globals.site);
		request.append("req", "uploadsController");
		request.append("action", "modUpload");
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success text-center text-large" role="alert"><i class="fa fa-check-circle me-1"></i>La modification a bien été prise en compte.</div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				setTimeout(function(){
					App.setUploadsListPage();
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else
				returns = '<div class="alert alert-danger text-center text-large" role="alert"><i class="fa fa-times-circle me-1"></i>La modification n\'a pas été prise en compte, suite à un problème technique.</div>';
		}).always(function(){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-upload me-1"></i>Envoyer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	setSalesExportPage: function(companyId = globals.site) {
		if(companyId==0) {
			App.popCompanyChoiceModal('App.setSettingsPage();');
			return;
		}
		this.closeModal('companyChoiceModal') // In case we get back from popCompanyChoiceModal
		const myForm = document.getElementById('salesExportForm')
		const previousFormId = globals.currentFormId
		globals.currentFormId = '#salesExportForm'
		if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId
		if(localStorage.getItem('exportDest')) myForm.querySelector('[data-field=to_m]').value = localStorage.getItem('exportDest');
		if(localStorage.getItem('exportDestCC')) myForm.querySelector('[data-field=cc_m]').value = localStorage.getItem('exportDestCC');
		if(localStorage.getItem('exportDestBCC')) myForm.querySelector('[data-field=bcc_m]').value = localStorage.getItem('exportDestBCC');
		const aWeekAgo = new Date(new Date().setDate(new Date().getDate()-7));
		const aMonthAgo = new Date(new Date().setDate(new Date().getDate()-29));
		const litepickerRangePluginSalesExport = document.getElementById('litepickerRangePluginSalesExport');
		if (litepickerRangePluginSalesExport) {
			new Litepicker({
				element: litepickerRangePluginSalesExport,
				startDate: aMonthAgo,
				endDate: new Date(),
				singleMode: false,
				numberOfMonths: 2,
				numberOfColumns: 2,
				format: 'DD/MM/YYYY',
				delimiter: ' > ',
				lang: 'fr-FR',
				tooltipText: {
					one: 'jour',
					other: 'jours'
				},
				tooltipNumber: (totalDays) => {
					return totalDays;
				},
				plugins: ['ranges'],
				ranges: {
					customRanges: {
						'Aujourd\'hui': [new Date(), new Date()],
						'Hier': [new Date(new Date().setDate(new Date().getDate()-1)), new Date(new Date().setDate(new Date().getDate()-1))],
						'Derniers 7 Jours': [aWeekAgo, new Date()],
						'Derniers 30 jours': [new Date(new Date().setDate(new Date().getDate()-29)), new Date()],
						'Ce Mois': [new Date(new Date().setDate(1)), new Date()],
						'Dernier Mois': [new Date(new Date().getFullYear(), new Date().getMonth()-1, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)],
						'Cette Année': [new Date(new Date().getFullYear(), 0, 1), new Date()],
						'Année 365': [new Date(new Date().setDate(new Date().getDate()-364)), new Date()],
					},
					// customRangesLabels:["Aujourd'hui","Hier","Derniers 7 Jours","Derniers 30 jours","Ce Mois","Dernier Mois","Cette Année"],
				},
				setup: (picker) => {
					picker.on('selected', (dateStart, dateEnd) => {
						console.warn(dateStart, dateEnd)
					});
				},
			});
		}
		// Building meta tags list...
		$('head title').text('Export Ventes - FACT SNS');
		$('head meta[name="description"]').text('FACT SNS | SNS SOLUTIONS | Export Ventes');
	},
	salesExport: function(myFormDiv) {
		const jqFormSubmit = $(myFormDiv+' [type="submit"]')
		const previousHtml = jqFormSubmit.html()
		jqFormSubmit.attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' [data-successfail]').append('<div class="alert alert-info text-center text-large" role="alert">Veuillez patienter pendant le traitement de la demande...<hr><i class="fa fa-2x fa-spinner fa-pulse"></i></div>');
		const myForm = document.querySelector(myFormDiv)
		const thisQuery = {...globals.baseQuery}
		myForm.querySelectorAll('input').forEach(item => {
			thisQuery[item.getAttribute('name')] = item.value
		})
		localStorage.setItem('exportDest', thisQuery.to_m)
		localStorage.setItem('exportDestCC', thisQuery.cc_m)
		localStorage.setItem('exportDestBCC', thisQuery.bcc_m)
		let returns = '';
		$.post(globals.serverAddress, {...thisQuery, req: 'bookingExportController', action: 'salesExport'}, function(data){
			const filenameVE = data.filenameVE ? `
				<a href="${globals.serverBaseUrl}docs/exports/${data.filenameVE}" class="list-group-item list-group-item-action list-group-item-warning" target="_blank">
					<i class="fa fa-file-excel text-success me-1"></i>Journal des ventes
				<a>
			` : '';
			const filenameREG = data.filenameREG ? `
				<a href="${globals.serverBaseUrl}docs/exports/${data.filenameREG}" class="list-group-item list-group-item-action list-group-item-warning" target="_blank">
					<i class="fa fa-file-excel text-success me-1"></i>Journal des réglements
				</a>
			` : '';
			const zipFile = data.zipFile ? `
				<a href="${globals.serverBaseUrl}docs/exports/${data.zipFile}" class="list-group-item list-group-item-action list-group-item-warning" target="_blank">
					<i class="fa fa-file-archive text-primary me-1"></i>Archive des factures
				</a>
			` : '';
			if(data.ok=="ok") {
				if (data.sizeLimit == 1) {
					returns = `
						<div class="alert alert-warning text-center text-large" role="alert">
							<i class="fa fa-exclamation-triangle me-1"></i>L\'export comptable a été réalisé mais il est probablement trop conséquent pour être envoyé par email !<hr>
							<ul class="list-group">
								${filenameVE}
								${filenameREG}
								${zipFile}
							</ul>
						</div>
					`;
					Swal.fire({
						title: 'Action réussie, mais...',
						text: "L'export comptable a été réalisé mais il est probablement trop conséquent pour être envoyer par email !",
						icon: 'warning',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
						timer: 2600,
						timerProgressBar: true,
					})
				}
				else {
					returns = `
						<div class="alert alert-success text-center text-large" role="alert">
							<i class="fa fa-check-circle me-1"></i>L\'export comptable a été réalisé et envoyé.<hr>
							<ul class="list-group">
								${filenameVE}
								${filenameREG}
								${zipFile}
							</ul>
						</div>
					`;
					Swal.fire({
						title: 'Action réussie',
						text: "L'export comptable a été réalisé et envoyé.",
						icon: 'success',
						confirmButtonText: 'Ok',
						confirmButtonColor: '#1da1f5',
						timer: 2600,
						timerProgressBar: true,
					})
				}
				// App.clearFormFields(myFormDiv);
			}
			else { // It's still possible that export went fine but mail failed to be sent...
				returns =
					`<div class="alert alert-danger text-center text-large" role="alert">
						<i class="fa fa-times-circle me-1"></i>L\'export comptable n\'a pas été réalisé, suite à un problème technique.<br><b>'${data.error}'</b>
						<ul class="list-group">
							${filenameVE}
							${filenameREG}
							${zipFile}
						</ul>
					</div>`;
			}
		}, 'json').always(function(){
			jqFormSubmit.attr("disabled", false).html(previousHtml);
			$(myFormDiv+' [data-successfail]').html(returns);
		});
	},
	popCompanyChoiceModal: async function(callBackFunction) {
		// Pop Company Choice => Cloning: <select class="form-select" name="siteGlobal" id="siteGlobal" onchange="App.changeSite(this);">
		await this.getSitesListBox()
		const elemenExists = document.getElementById('siteGlobalCloned')
		if(!elemenExists) { // If clone does not already exists...
			const cloneSiteSelectBox = document.getElementById('sitesListBoxCont').cloneNode(true);
			const cloneSiteSelect = cloneSiteSelectBox.querySelector('select')
			const companyChoiceModal = document.getElementById('companyChoiceModal')
			cloneSiteSelect.setAttribute('id', 'siteGlobalCloned')
			cloneSiteSelect.setAttribute('onchange', `App.changeSite(this, false);${callBackFunction}`)
			companyChoiceModal.querySelector('.modal-body').appendChild(cloneSiteSelectBox)
		}
		let myModal = new bootstrap.Modal(companyChoiceModal);
		myModal.show();
	},
	setSettingsPage: async function(companyId = globals.site) {
		if(companyId==0) {
			App.popCompanyChoiceModal('App.setSettingsPage();');
			return;
		}
		this.closeModal('companyChoiceModal') // In case we get back from popCompanyChoiceModal
		this.buildUploadZone() // Dealing with upload buttons and inputs logics
		this.buildTinyMceTextArea('body_m_quotation')
		this.buildTinyMceTextArea('body_m_invoice')
		this.buildTinyMceTextArea('body_m_delivery')
		// this.buildTinyMceTextArea('body_m_report')
		if(globals.type=='TheKingOfInvoice') { // If not elevated user
			const myFormDiv = '#modCompanyForm';
			if(globals.type=='TheKingOfInvoice' && globals.adminPass=='') await this.checkAccountStatus();
			App.clearFormFields(myFormDiv);
			const previousFormId = globals.currentFormId;
			globals.currentFormId = myFormDiv;
			if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
			await this.getFormsSelectBoxes([myFormDiv, "#addCompanyForm"], 'settingsController', ['taxes_s','currencies_s']);
			$.post(globals.serverAddress, {...globals.baseQuery, companyId: companyId, req: 'settingsController', action: 'fillModCompany'}, function(data){
				if(data.ok=="ok") {
					for (const [key, value] of Object.entries(data.company)) {
						$(myFormDiv+' [data-field="'+key+'"]').val(value);
						// let target = $(myFormDiv+' [data-field="'+key+'"]');
						// if(target.is('input[type=checkbox]') && value==1) $(myFormDiv+' #'+key).prop('checked', true); // Dealing with Checkboxes
					}
					if(data.company.picture_s != '') {
						$('#pageHeaderCompanyLogo').html(`<a href="${globals.serverBaseUrl}docs/logos/${data.company.picture_s}" id="clientLogoLink" data-simplelightbox="all">
							<img class="profile-clients-img rounded-xl shadow-lg" src="${globals.serverBaseUrl}docs/logos/${data.company.picture_s}" />
						</a>`);
					}
					for (const [index, item] of Object.entries(data.uploads)) {
						const filesArray = item.split(';');
						const fileListCont = document.querySelector(`[data-${index}]`); // data-upload-list
						const fieldName = index.replaceAll('-', '_') // Get DB fields' names
						if(fileListCont && filesArray.length>0) {
							let fileList = '';
							filesArray.forEach((fileName) => {
								if(fileName!='') {
									const delBtn = `<button onclick="App.delAttachedFile(0, '${fileName}', '${fieldName}', '../docs/uploads/', 'settingsController', this, event);" class="btn btn-danger btn-xs btn-icon ms-1" title="Supprimer ce document"><i class="fa fa-2x fa-times mr-0"></i></button>`
									fileList += `<li data-upload-list-item><a href="${globals.serverBaseUrl}docs/uploads/${fileName}" target="_blank">${fileName}</a> ${delBtn}</li>`
								}
							})
							if(fileList!='') {
								fileListCont.classList.add('alert', 'alert-primary', 'my-1')
								fileListCont.innerHTML = `<ul class="mb-0">${fileList}</ul>`;
							}
						}
					}
					tinymce.get('body_m_quotation').setContent(App.settings.siteSettings?.body_m_quotation)
					tinymce.get('body_m_invoice').setContent(App.settings.siteSettings?.body_m_invoice)
					tinymce.get('body_m_delivery').setContent(App.settings.siteSettings?.body_m_delivery)
					// tinymce.get('body_m_report').setContent(App.settings.siteSettings?.body_m_report)
				}
				else if(data.error=='administrator') {
					$(myFormDiv).closest('div').html('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette ressource !</div><hr><a href="/users/'+globals.id+'" class="btn btn-dark btn-lg btn-block shadow"><i class="fa fa-user-circle me-1"></i>Voir Mon Profil</a>');
				}
				else alert("Suite à un problème technique, je n'ai pas retrouvé cet société !");
				// Building meta tags list...
				$('head title').text(data.company.name_s+' - Paramètres Sociétés - CRM SecureBoat');
				$('head meta[name="description"]').text('CRM SecureBoat | SNS SOLUTIONS | Paramètres Sociétés');
			}, "json").always(function(){
				let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
					showCounter : true,
					history : false,
					captionType : 'data',
					captionsData : 'caption',
					closeText : 'X'
				});
				lightbox.refresh();
			});
			if(globals.type=='user') {
				$('.userReadOnly').attr('readonly', true);
				$('.userDisabled').attr('disabled', true);
			}
		}
		else {
			$("#settingsPageBody").html('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette ressource !</div>');
			$("#userPageBody").append('<hr><a href="/users/'+globals.id+'" class="btn btn-dark btn-lg btn-block shadow"><i class="fa fa-user-circle me-1"></i>Voir Mon Profil</a>');
		}
	},
	modCompany: function(myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let returns = '';
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "settingsController");
		request.append("action", "modCompany");
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("site", globals.site);
		request.append("ap", globals.adminPass);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>La société a bien été mise à jour.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				setTimeout(function(){
					document.location.reload()
				}, 2600);
			}
			else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>La société n\'a pas été modifiée suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	addCompany: function(myFormDiv) {
		$(myFormDiv+' [type="submit"]').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv+' :disabled').attr("disabled", false); // We include all fields in FormData
		let returns = '';
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "settingsController");
		request.append("action", "addCompany");
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("site", globals.site);
		request.append("ap", globals.adminPass);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>La société a bien été ajoutée.</b></div>';
				Swal.fire({
					title: 'Action réussie',
					html: returns,
					icon: 'success',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#1da1f5',
					timer: 2600,
					timerProgressBar: true,
				})
				App.getSitesListBox()
				setTimeout(function(){
					App.closeModal(document.querySelector(myFormDiv).closest('.modal').getAttribute('id'));
					App.clearFormFields(myFormDiv);
					location.reload()
				}, 2600);
			}
			else if(data.error=='administrator') returns = data.snippet;
			else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>La société n\'a pas été ajoutée, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' [type="submit"]').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
			$(myFormDiv+' [data-successfail]').html(returns);
			$(myFormDiv+' :disabled').attr("disabled", true);
		});
	},
	delCompany: function(myFormDiv, thisBtn, event, fromModal = false) {
		event.preventDefault()
		const auto_s = $(myFormDiv+' #auto_s').val()
		const name_s = $(myFormDiv+' #name_s').val()
		$.post(globals.serverAddress, {...globals.baseQuery, auto_s: auto_s, req: 'settingsController', action: 'delCompanyCheck'}, function(data){
			const confirmText = (data.deletions>0) ? data.snippet+"<br>" : "";
			Swal.fire({
				title: "Êtes-vous certain de vouloir supprimer la société "+name_s+" ?",
				html: confirmText+"Cette action n'est réversible que sur intervention de SNS Solutions.",
				icon: "warning",
				confirmButtonText: "J'en suis sûr !",
				confirmButtonColor: '#1da1f5',
				showDenyButton: true,
				denyButtonText: "Peut-être pas..."
			}).then(response => {
				if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
					const thisBtnHtml = $(thisBtn).html();
					$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
					let query = "auto_s=" + auto_s + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=settingsController&action=delCompany";
					$.post(globals.serverAddress, query, function(data){
						if(data.ok=="ok") {
							alert("La société a bien été supprimée.");
							if(fromModal) {
								App.setClientsListPage();
								$(thisBtn).closest('.modal').find('.btn-close').trigger('click');
							}
							else document.location.href='/clients';
						}
						else alert("La société n'a pas été supprimée suite à un problème technique.");
					}, "json").always(function(data){
						$(thisBtn).attr("disabled", false).html(thisBtnHtml);
					});
				}
			});
		}, "json").always(function(data){
		});
	},
	delAttachedFile: function(auto_d=0, filename, filefield, filepath, controller, thisBtn, event) {
		event.preventDefault();
		// const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce document ?");
		Swal.fire({
			title: "Êtes-vous certain de vouloir supprimer ce document ?",
			html: "",
			icon: "warning",
			confirmButtonText: "J'en suis sûr !",
			confirmButtonColor: '#1da1f5',
			showDenyButton: true,
			denyButtonText: "Peut-être pas..."
		}).then(response => {
			if(response.isConfirmed && !response.isDenied && !response.isDismissed) { // if (confirmDeletion)
				const thisBtnHtml = $(thisBtn).html();
				$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>');
				$.post(globals.serverAddress, {auto_d: auto_d, auto_s: globals.site, filename: filename, filefield: filefield, filepath: filepath, ...globals.baseQuery, req: controller, action: 'delAttachedFile'}, function(data){
					if(data.ok=="ok") {
						const parentUl = thisBtn.closest('ul');
						if(parentUl) {
							const parentDiv = thisBtn.closest('div.alert');
							thisBtn.closest('li').remove();
							if(parentUl.innerHTML=='') { // No more files in the list so we clean this up...
								parentUl.remove()
								parentDiv.classList.remove('alert', 'alert-primary', 'my-1')
							}
						}
						else {
							Swal.fire({
								title: 'Action réussie',
								html: '<b>Document supprimé.</b>',
								icon: 'success',
								confirmButtonText: 'Ok',
								confirmButtonColor: '#1da1f5',
								timer: 2600,
								timerProgressBar: true,
							})
							App.setUploadsListPage();
						}
					}
					else alert('Le document n\'a pas été supprimé.');
				}, "json").always(function(){
					$(thisBtn).attr("disabled", false).html(thisBtnHtml);
				});
			}
		});
	},
	printSettingsTest: (typeDoc = 'invoice', thisBtn, event) => {
		event.preventDefault()
		const thisBtnHtml = $(thisBtn).html()
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		let query = "site=" + globals.site + "&type_doc=" + typeDoc + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=settingsController&action=printSettingsTest";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				const a = $("<a>").attr("href", data.pdfLink).attr("download", data.pdfName).attr("target", "_blank").appendTo("footer");
				a[0].click();
				a.remove();
				// const link = document.createElement('a');
				// link.href = data.pdflink;
				// link.setAttribute('download', data.pdfname);
				// document.body.appendChild(link);
				// link.click();
			}
			else alert('Suite à un problème technique le fichier n\'a pas été créé !');
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
		});
	},
	addWasValidatedClass: function (myFormDiv) {
		$(myFormDiv).addClass('was-validated');
		var wrongFields = "Les champs suivants sont mal renseignés : <br>";
		var wrongPop = false;
		$(myFormDiv).find(':invalid').each(function () {
			wrongPop = true;
			$(this).closest('.form-group').find('label').not('.notValidatedClass').each(function () {
				wrongFields += $(this).text()+"<br>";
			});
		});
		if(wrongPop) {
			Swal.fire({
				title: 'Erreur',
				html: '<p class="text-danger">'+wrongFields+'</p>',
				icon: 'error',
				confirmButtonText: 'Ok',
				confirmButtonColor: '#d01b09',
				timer: 3600,
				timerProgressBar: true,
			})
			// alert(wrongFields);
		}
		return !wrongPop;
	},
	clearFormFields: function(myFormDiv, event) {
		if(event) event.preventDefault(); // prevents form submission when button is inside it !
		document.querySelectorAll(`${myFormDiv} select, ${myFormDiv} input, ${myFormDiv} textarea`).forEach((elem) => {
			elem.value = (elem.dataset.default) ? elem.dataset.default : ''
		})
		// $(myFormDiv).find("input, select, textarea").val('');
		$(myFormDiv).find("input[type=checkbox]").prop("checked", false).val(1);
		$(myFormDiv).find("input[type=radio]").prop("checked", false).val(1);
		$(myFormDiv).find("input[type=range], input[type=number]").val(0); // Making sure Numbers & Ranges inputs are reset to 0
		$(myFormDiv).removeClass('was-validated');
		$(myFormDiv+' [data-successfail]').empty();
		$(myFormDiv+' [data-upload-list]').empty();
	},
	popModal: function(myModalId, thisBtn, event) {
		if(event) event.preventDefault(); // prevents form submission when button is inside it !
		if(thisBtn) {
			const closePreviousModal = thisBtn.closest('.modal').getAttribute('id');
			this.closeModal(closePreviousModal);
			console.warn(closePreviousModal)
		}
		const myModalObject = document.getElementById(myModalId);
		if (myModalObject) {
			const myModal = bootstrap.Modal.getOrCreateInstance(myModalObject);
			// const myModal = new bootstrap.Modal(myModalObject);
			myModal.show();
			const myFormObject = myModalObject.getElementsByTagName('form')[0];
			const previousFormId = globals.currentFormId;
			globals.currentFormId = myFormObject.getAttribute('id');
			if(previousFormId!=globals.currentFormId) globals.previousFormId = previousFormId;
			console.log(globals.previousFormId, globals.currentFormId);
		}
		else {
			setTimeout(function() { // Little timer to let the system charge page if needed
				App.popModal(myModalId);
			}, 1000);
		}
	},
	closeModal: function(modalDiv) {
		const myModalId = (modalDiv.includes('#')) ? modalDiv : '#'+modalDiv
		const myModal = bootstrap.Modal.getInstance(myModalId)
		if(myModal) myModal.hide()
	},
	enablePopovers: () => {
		const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
		const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
	},
	safeJsonParse: function(input) {
		try {
			return JSON.parse(input);
		} catch (e) {
			return undefined;
		}
	},
	urlParam: function(name, url) {
		// Get parameters from an URL
		var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
		//For current URL
		//var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
		if (results==null){
			return null;
		}
		else{
			return results[1] || 0;
		}
	},
	popFormFromUrlParam: function() {
		const goTo = App.urlParam('goto', document.URL);
		if(goTo) {
			const fields = App.urlParam('fields', document.URL)?.split(',');
			const idFields = App.urlParam('idFields', document.URL)?.split(',');
			if(Array.isArray(fields)) { // Multiple fields => GET is an Array
				fields.forEach((field, index) => {
					$(`#${goTo} [data-field=${field}]`).val(decodeURI(idFields[index])).trigger('change')
					// document.querySelector(`#${goTo} [data-field=${field}]`).value = idFields[index]
				})
			}
			else { // Only one field there
				$(`#${goTo} [data-field=${fields}]`).val(decodeURI(idFields)).trigger('change')
				// document.querySelector(`#${goTo} [data-field=${fields}]`).value = idFields
			}
			this.popModal(goTo);
		}
	},
	refreshSmoothScroll: function() {
		// Smooth Scroll to div on anchors click...
		$('a[href*="#"]').not('a.noscroll').on('click', function (event) {
			event.preventDefault();
			let offset = 0;
			const target = this.hash;
			if($(this).data('offset') != undefined) offset = $(this).data('offset'); // if set data-offset="pixels"
			if($(target).length) {
				$('html, body').stop().animate({
					'scrollTop': $(target).offset().top - offset
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
			else { // Scrolls to top...
				$('html, body').stop().animate({
					'scrollTop': 0
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
		});
	},
	smoothScrollTo: (target, offset) => { // Smooth Scroll to div...
        if($(target).length) {
            $('html, body').stop().animate({
                'scrollTop': $(target).offset().top - offset
            }, 2600, 'swing', function() {
                // window.location.hash = target;
            });
        }
	},
	number_format: function(number, decimals, dec_point, thousands_sep) {
		// *     example: App.number_format(1234.56, 2, ',', ' ');
		// *     return: '1 234,56'
		number = (number + "").replace(",", "").replace(" ", "");
		var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
			dec = typeof dec_point === "undefined" ? "." : dec_point,
			s = "",
			toFixedFix = function(n, prec) {
				var k = Math.pow(10, prec);
				return "" + Math.round(n * k) / k;
			};
		// Fix for IE parseFloat(0.55).toFixed(0) = 0;
		s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || "").length < prec) {
			s[1] = s[1] || "";
			s[1] += new Array(prec - s[1].length + 1).join("0");
		}
		return s.join(dec);
	},
	buildTinyMceTextArea: (inputId) => {
		const inputSelector = '#'+inputId
		if(tinymce.get(inputSelector) && document.getElementById(inputId)) { // If Editors already are in TinyMCE's Editors list it won't initialize them again !
			tinymce.remove(inputSelector)
		}
		tinymce.init({
			selector: inputSelector,
			plugins: 'advlist code emoticons link lists table image',
			toolbar: 'toolbar: undo redo styles bold italic underline fontfamily forecolor backcolor removeformat | link image print | alignleft aligncenter alignright alignjustify bullist numlist | outdent indent | emoticons ',
			skin: false,
			content_css: false,
			// content_style: contentUiCss.toString() + '\n' + contentCss.toString(),
			language: 'fr_FR',
			license_key: 'gpl'
		})
	},
	updateObjectLinks: function(thisBtn, thisObject, objectPath, fromData = false) {
		const idObject = (fromData) ? thisBtn.selectedOptions[0].dataset.objectId : thisBtn.value;
		const path = (objectPath) ? objectPath : thisObject; // If no objectPath then path is thisObject (clients path is clients, projects path is sales/projects)
		// const formId = '#'+$(thisBtn).closest('form').attr('id');
		// $(formId+' a[data-clients-link]').attr('href', '/clients/'+idClient);
		if(idObject) {
			document.querySelectorAll(`a[data-${thisObject}-link]`).forEach((item) => {
				item.setAttribute('href', '/'+path+'/'+idObject)
			})
		}
	},
	buildDataTables: async function(myDiv, pageLength = 25, colReorder = true, orderCol = 0, orderWay = 'desc', expTitle = '', filterColumsText = [], filterColumsSelect = [], myFilterField, myFilterCondition, myFilter, pdfExtraExportOptions, fixedHeader) {
		// Like : App.buildDataTables('#dataTable', 25, true, 4, 'desc', 'Titre', [0,1,2,3,4,5,6], '');
		// Or : App.buildDataTables('#dataTable', 25, true, 4, 'desc', 'Titre', [0,1,2,3,4,5,6], [7,8], 'Centre', '=', myFilter, [0,1,2,3,4,5,6], true);
		fixedHeader = (typeof fixedHeader !== 'undefined') ? fixedHeader : true;
		let fixedFooter = false;
		let headerOffset = 60;
		if(globals.isMobile) fixedHeader = false;
		const dom = (globals.type=='user') ? '<"row"<"col-12 col-md-6 d-flex align-items-center py-1"l><"col-12 col-md-6 d-flex align-items-center justify-content-end py-1"f>rt<"col-12 col-md-6 py-1"i><"col-12 col-md-6 py-1"p>>' : '<"row"<"col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start py-1"l><"col-12 col-md-4 d-flex align-items-center justify-content-center py-1"f><"col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end py-1"B>>rt<"row"<"col-12 col-md-6 py-1"i><"col-12 col-md-6 py-1"p>>';
		// Add row class to dataTable wrapper => Usefull to correcty display buttons using the dom option => Done in css directly on .dataTables_wrapper
		// $(myDiv+'_wrapper').addClass('row');
		// Add filter row in header...
		// $(myDiv+' thead tr').clone(true).addClass('filters').appendTo(myDiv+' thead');
		const pdfExtra = (typeof pdfExtraExportOptions !== 'undefined') ? pdfExtraExportOptions : '';
		const baseFiltering = (typeof myFilter !== 'undefined') ? myFilter : '';
		let myTable;
		let dataTableOptions = {
			destroy: true,
			dom: dom,
			language: globals.datatablesFrench,
			// language: {
			//	url: 'https://cdn.datatables.net/plug-ins/1.11.3/i18n/fr_fr.json'
			// },
			pageLength: pageLength,
			colReorder: colReorder,
			fixedHeader: {header: fixedHeader, footer: fixedFooter, headerOffset: headerOffset},
			order: [], // disable default sorting order wi'll apply ordering conditionaly below
			buttons: [
				{text:'<li class="fa fa-2x fa-copy"></li>', title: expTitle, extend:'copy'},
				{text:'<li class="fa fa-2x fa-file-excel"></li>', title: expTitle, extend:'excel'},
				{text:'<li class="fa fa-2x fa-file-pdf"></li>', title: expTitle, exportOptions: {stripNewlines: false, columns: pdfExtra}, orientation: 'landscape', pageSize: 'LEGAL', extend:'pdf'},
				{text:'<li class="fa fa-2x fa-print"></li>', title: expTitle, exportOptions: {stripHtml: false, stripNewlines: false}, extend:'print'}
			],
			initComplete: function () {
				if(filterColumsText.length>0 || filterColumsSelect.length>0) {
					$(myDiv+' thead tr').clone(false).addClass('filters').appendTo(myDiv+' thead');
					$(myDiv+' tr.filters .filter-false').empty().removeClass('sorting');
				}
				if(filterColumsText.length>0) {
					this.api().columns(filterColumsText).every( function () {
						let column = this;
						let columnIndex = $(column.header()).index()
						const cell = $(myDiv+' tr.filters th').eq(columnIndex).empty().removeAttr('data-dt-column');
						// const cell = $(myDiv+' tr.filters th').eq(columnIndex).empty().removeClass(['sorting', 'sorting_asc', 'sorting_desc']);
						let input = $('<input class="form-control form-control-sm table-filter" type="text" placeholder="Rechercher" data-filter-column="'+columnIndex+'" />')
						.appendTo( $(cell) )
						// .appendTo( $(column.header()) )
						.off('keyup change')
						.on('keyup change', function (e) {
							// e.stopPropagation();
							let val = $.fn.dataTable.util.escapeRegex(
								$(this).val()
							);
							let regexr = '({search})';
							column.search(val != '' ? regexr.replace('{search}', '(((' + val + ')))') : '', val != '', val == '').draw();
						});
					});
				}
				if(filterColumsSelect.length>0) {
					this.api().columns(filterColumsSelect).every( function () {
						let column = this;
						let columnIndex = $(column.header()).index()
						let filterDataArray = [];
						const cell = $(myDiv+' tr.filters th').eq(columnIndex).empty().removeAttr('data-dt-column');
						let select = $('<select class="form-select form-select-sm table-filter" data-filter-column="'+columnIndex+'"><option value=""></option></select>')
						.appendTo( $(cell) )
						// .appendTo( $(column.header()) )
							.on( 'change', function () {
								let val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								);
								column
									.search( val ? '^'+val+'$' : '', true, false )
									.draw();
							});
						column.data().unique().sort().each( function ( d, j ) {
							let filterData = d;
							if(d.indexOf('</') !== -1) { // In case there is html tags we strip those...
								filterData = ($(d).length) ? $(d).text() : d;
								// console.warn(d+' - '+$(d));
							}
							// select.append( '<option value="'+filterData+'">'+filterData+'</option>' );
							if(filterData!='') filterDataArray.push(filterData);
						});
						// Perform case-insensitive sort if not all capital letters are set before lower-case ones, besides we stripped html tags just befor so...
						filterDataArray.sort((a,b) => a.localeCompare(b));
						filterDataArray.forEach(element => {
							select.append( '<option value="'+element+'">'+element+'</option>' );
						});
					});
				}
			}
		}
		if(baseFiltering.length>0) {
			let tabFilter = new Array(myFilter);
			dataTableOptions = {
				searchBuilder: {
					preDefined: {
						criteria: [
							{
								data: myFilterField,
								condition: myFilterCondition,
								value: tabFilter
							}
						]
					}
				},
				...dataTableOptions
			}
		}
		myTable = $(myDiv).DataTable(dataTableOptions);
		// Conditionaly ordering now
		if(orderWay) myTable.order([ orderCol, orderWay ]).draw(); // Type comparison here is important because of col zero
		// myTable.fixedHeader.adjust();
		// if(globals.isMobile) myTable.fixedHeader.disable();
	},
	resetFilters: function() {
		$('.table-filter').each(function() {
			$(this).val('');
		}).trigger('change');
		$('.dt-search input[type=search]').each(function() {
			$(this).val('').trigger('keyup');
		});
	},
	buildDataFilterSearch: () => {
		const dataFiltersContainer = document.querySelector('[data-filters]')
		const filtersVal = dataFiltersContainer?.getAttribute('data-filters').split(',') // Get filters from results container
		const filtersText = dataFiltersContainer?.getAttribute('data-filters-text').split(',') // Get filters from results container
		let filterBoxOptions = '<option value="">Tous</option>';
		if(filtersVal && filtersVal.length > 0) {
			filtersVal.forEach((item, index) => {
				filterBoxOptions += `<option value="${item}">${filtersText[index]}</option>`;
			});
		}
		const filterInput = document.querySelector('[data-filter-input]')
		if(filterInput) filterInput.innerHTML = filterBoxOptions;
	},
	dataFilterField: function(thisInput) {
		const filter = thisInput.value;
		const searchInput = document.querySelector('[data-search-input]')
		const search = (searchInput) ? this.normalizeSearchFields(searchInput.value) : '';
		if(filter!='' && search!='') {
			$('[data-filter=1]').each(function(){
				const filterText = App.normalizeSearchFields($(this).data('filter-'+filter))
				if(filterText.includes(search)) $(this).fadeIn('slow');
				else $(this).fadeOut('slow');
			});
		}
		else $('[data-filter=1]').fadeIn('slow');
	},
	dataFilterSearch: function(thisInput) {
		const dataFiltersContainer = document.querySelector('[data-filters]')
		const filters = dataFiltersContainer.getAttribute('data-filters').split(',') // Get filters from results container
		const search = (thisInput) ? this.normalizeSearchFields(thisInput.value) : '';
		const filterInput = document.querySelector('[data-filter-input]')
		const filter = (filterInput) ? filterInput.value : '';
		if(search!='') {
			$('div[data-filter=1]').each(function(){
				const _thisResult = $(this)
				// console.log(_thisResult)
				if(filter!='') { // Searching in a single data-filter
					const filterText = App.normalizeSearchFields(_thisResult.data('filter-'+filter))
					if(filterText.includes(search)) _thisResult.fadeIn('slow');
					else _thisResult.fadeOut('slow');
				}
				else { // Searching in all filters
					let found = false
					filters.forEach((item) => {
						const filterText = App.normalizeSearchFields(_thisResult.attr('data-filter-'+item))
						if(filterText.includes(search)) {
							found = true;
						}
					})
					if(found) _thisResult.fadeIn('slow');
					else _thisResult.fadeOut('slow');
				}
			});
		}
		else $('[data-filter=1]').fadeIn('slow');
	},
	normalizeSearchFields: function(myString) {
		// const objectAlphabetAll = {'Š':'S', 'š':'s', 'Đ':'Dj', 'đ':'dj', 'Ž':'Z', 'ž':'z', 'Č':'C', 'č':'c', 'Ć':'C', 'ć':'c', 'À':'A', 'Á':'A', 'Â':'A', 'Ã':'A', 'Ä':'A', 'Å':'A', 'Æ':'A', 'Ç':'C', 'È':'E', 'É':'E', 'Ê':'E', 'Ë':'E', 'Ì':'I', 'Í':'I', 'Î':'I', 'Ï':'I', 'Ñ':'N', 'Ò':'O', 'Ó':'O', 'Ô':'O', 'Õ':'O', 'Ö':'O', 'Ø':'O', 'Ù':'U', 'Ú':'U', 'Û':'U', 'Ü':'U', 'Ý':'Y', 'Þ':'B', 'ß':'Ss', 'à':'a', 'á':'a', 'â':'a', 'ã':'a', 'ä':'a', 'å':'a', 'æ':'a', 'ç':'c', 'è':'e', 'é':'e', 'ê':'e', 'ë':'e', 'ì':'i', 'í':'i', 'î':'i', 'ï':'i', 'ð':'o', 'ñ':'n', 'ò':'o', 'ó':'o', 'ô':'o', 'õ':'o', 'ö':'o', 'ø':'o', 'ù':'u', 'ú':'u', 'û':'u', 'ý':'y', 'ý':'y', 'þ':'b', 'ÿ':'y', 'Ŕ':'R', 'ŕ':'r'}
		const objectAlphabetFr = {'à':'a', 'á':'a', 'â':'a', 'ä':'a', 'ç':'c', 'è':'e', 'é':'e', 'ê':'e', 'ë':'e', 'ì':'i', 'í':'i', 'î':'i', 'ï':'i', 'ò':'o', 'ó':'o', 'ô':'o', 'ö':'o', 'ù':'u', 'ú':'u', 'û':'u'}
		myString = myString.toString().toLowerCase()
		for (const [replaced, replacement] of Object.entries(objectAlphabetFr)) {
			myString = myString.replaceAll(replaced, replacement)
		}
		return myString
	},
	emptySearchField: function(thisBtn) {
		$(thisBtn).closest('.form-group').find('input').val('');
		this.dataFilterSearch()
	},
	setListView: function(view, thisBtn) {
		const reverseView = (view=='kanban') ? 'table' : 'kanban';
		const btnToShow = document.querySelector(`[data-set-view-${reverseView}]`)
		const contanerToHide= document.querySelector(`[data-${reverseView}-view]`)
		const contanerToShow = document.querySelector(`[data-${view}-view]`)
		thisBtn.classList.remove('d-lg-none', 'd-lg-inline-flex')
		btnToShow.classList.remove('d-lg-none', 'd-none')
		thisBtn.classList.add('d-none')
		btnToShow.classList.add('d-inline-flex')
		contanerToHide.classList.remove('d-lg-none', 'd-lg-block', 'd-block')
		contanerToShow.classList.remove('d-lg-none', 'd-none', 'd-lg-block')
		contanerToHide.classList.add('d-none')
		contanerToShow.classList.add('d-block')
		const dataFiltersContainer = document.getElementById('dataFiltersContainer')
		dataFiltersContainer.classList.remove('d-lg-none', 'd-none', 'd-flex')
		const btnErase = document.querySelector(`[data-erase-filters]`)
		btnErase.classList.remove('d-none', 'd-lg-inline-flex')
		if(view=='table') {
			dataFiltersContainer.classList.add('d-none')
			btnErase.classList.add('d-inline-flex')
			document.querySelector('.dataTable')?.classList.remove('d-none') // In case we've hidden DataTable's Floating Header
		}
		else {
			dataFiltersContainer.classList.add('d-flex')
			btnErase.classList.add('d-none')
			document.querySelector('.dataTable')?.classList.add('d-none') // Hidding DataTable's Floating Header
		}
	},
	displayRangeValue: (thisRange, unit = '', precision = 0) => {
		// console.warn(thisRange)
		const target = thisRange.previousElementSibling
		const rangeValue = Number(thisRange.value).toFixed(precision)
		target.innerHTML = rangeValue+unit
	},
	calculatePayedRangeTotals: (thisBtn) => {
		const myForm = thisBtn.closest('form')
		const payedRange = myForm.querySelector('[data-payed-range]').value
		const totalTaxIncl = myForm.querySelector('[data-total-tax-incl]').value
		const payedAmount = totalTaxIncl * payedRange/100
		myForm.querySelector('[data-payed-amount]').value = payedAmount.toFixed(2)
	},
	displayRelated: (thisBtn, targetField, dataAttr) => {
		const myForm = thisBtn.closest('form')
		const filterValue = thisBtn.value
		const targetSelect = myForm.querySelector(`[data-field="${targetField}"]`)
		// targetSelect.value = ''
		const targetSelectOptions = targetSelect.querySelectorAll(`option`)
		targetSelectOptions.forEach((item) => {
			if(item.getAttribute(dataAttr)!=filterValue) $(item).not('[data-empty]').hide().attr('disabled',true);
			else $(item).show().attr('disabled',false);
		})
	},
	changeRelated: (thisBtn, targetField, dataAttr) => {
		const myForm = thisBtn.closest('form')
		const targetSelect = myForm.querySelector(`[data-field="${targetField}"]`)
		targetSelect.value = thisBtn.selectedOptions[0].getAttribute(dataAttr)
		$(targetSelect).trigger('change')
	},
	setNowDate: function(thisBtn, targetElementName) {
		const thisForm = thisBtn.closest('form')
		const targetInput = thisForm.querySelector('[name="'+targetElementName+'"]')
		if(targetInput) targetInput.value = (thisBtn.value==1) ? this.makeNiceDateTime(new Date).split(' ')[0] : '' // Set target to now only if thisBtn value is 1 else empty it
	},
	setFutureDate: function(thisBtn, targetElementName) {
		const thisForm = thisBtn.closest('form')
		const daysToGo = thisBtn.selectedOptions[0].dataset.dateAdd
		const myFutureDate = new Date(new Date().setDate(new Date().getDate()+Number(daysToGo)))
		const targetInput = thisForm.querySelector('[name="'+targetElementName+'"]')
		if(targetInput) targetInput.value = this.makeNiceDateTime(myFutureDate).split(' ')[0];
	},
	getFutureDate: function(daysToGo) {
		const myFutureDate = new Date(new Date().setDate(new Date().getDate()+Number(daysToGo)))
		return this.makeNiceDateTime(myFutureDate).split(' ')[0]
	},
	makeNiceDateTime: function(d) {
		const jsDay = d.getDate();
		const jsMonth = d.getMonth()+1; // January is zero
		const jsHours = d.getHours();
		const jsMinutes = d.getMinutes();
		const jsSeconds = d.getSeconds();
		const niceDay = (jsDay<10) ? '0'+jsDay : jsDay;
		const niceHours = (jsHours<10) ? '0'+jsHours : jsHours;
		const niceMinutes = (jsMinutes<10) ? '0'+jsMinutes : jsMinutes;
		const niceSeconds = (jsSeconds<10) ? '0'+jsSeconds : jsSeconds;
		const niceMonth = (jsMonth<10) ? '0'+jsMonth : jsMonth;
		const niceDate = niceDay+'/'+niceMonth+'/'+d.getFullYear()+' '+niceHours+':'+niceMinutes+':'+niceSeconds;
		return niceDate;
	},
	makeNiceTime: function(timeInMinutes) {
		const jsHours = Math.floor(timeInMinutes/60);
		const jsMinutes = timeInMinutes-jsHours*60;
		console.debug(jsMinutes);
		const niceHours = (jsHours<10) ? '0'+jsHours : jsHours;
		const niceMinutes = (jsMinutes<10) ? '0'+jsMinutes : jsMinutes;
		const niceTime = niceHours+'h'+niceMinutes;
		return niceTime;
	},
	makeNiceTime24: function(timeInMinutes, offset) {
		const tempDate = new Date(Math.floor(timeInMinutes*60*1000)); // UNIX Timestamp here is GMT+1 Winter time so an hour is added
		const jsHours = Math.floor(tempDate.getHours()-offset);
		const jsMinutes = tempDate.getMinutes();
		const niceHours = (jsHours<10) ? '0'+jsHours : jsHours;
		const niceMinutes = (jsMinutes<10) ? '0'+jsMinutes : jsMinutes;
		const niceTime = niceHours+'h'+niceMinutes;
		console.warn(tempDate+' - '+niceTime+' - '+Math.floor(timeInMinutes*60*1000));
		return niceTime;
	},
	convertStringToDate: function(niceDateTime) { // See makeNiceDateTime => convertStringToDate("19/04/2022 11:20:30")
		let dateComponents = niceDateTime.split(' ');
		let datePieces = dateComponents[0].split("/");
		let timePieces = dateComponents[1].split(":");
		return(new Date(datePieces[2], (datePieces[1] - 1), datePieces[0], timePieces[0], timePieces[1], timePieces[2]));
	},
	printDiv: function(divName, reload = false){
		var printContents = document.getElementById(divName).innerHTML;
		var originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();
		if(reload) document.location.reload();
		else document.body.innerHTML = originalContents;
	},
	downloadFiles: function (link, name, thisBtn, event) { // WARNING: Won't really work on localhost but it's fine online so don't trouble yourself
		event.preventDefault();
		$(thisBtn).attr("disabled", true);
		const a = $("<a>").attr("href", link).attr("download", name).appendTo("footer");
		a[0].click();
		a.remove();
		$(thisBtn).attr("disabled", false);
	},
	buildUploadZone: () => { // Dealing with upload buttons and inputs logics
		document.querySelectorAll('[data-upload-btn]').forEach((element) => {
			const uploadInput = element.nextElementSibling // data-upload-input
			element.addEventListener('click', () => {
				uploadInput.click()
			})
			uploadInput.addEventListener('change', (event) => {
				const filesArray = event.target.files
				const fileListCont = event.target.nextElementSibling // data-upload-list
				let prevFileList = '<ul class="mb-0">'
				fileListCont.querySelectorAll('[data-upload-list-item]').forEach((item) => { // Used on Edit Forms
					prevFileList += item.outerHTML
				})
				prevFileList += '</ul>'
				// const prevFileList = fileListCont.innerHTML
				fileListCont.classList.add('alert', 'alert-primary', 'my-1')
				let fileList = '<ul class="mb-0">';
				for(const file of filesArray) {
					fileList += `<li>${file.name} - ${App.calculateFileSize(file.size)}</li>`
				}
				fileList += '</ul>'
				fileListCont.innerHTML = prevFileList+fileList;
				// 0: File { name: "Liste des Contacts.pdf", lastModified: 1697026886175, size: 18212, type: "application/pdf", … }
				// 1: File { name: "moi.jpg", lastModified: 1696279543911, size: 416942, type: "image/jpeg", … }
			});
		})
	},
	calculateFileSize: (fileSize) => {
		let numberOfBytes = fileSize
		// Calculate total size
		// let numberOfBytes = 0;
		// for (const file of uploadInput.files) {
		// 	numberOfBytes += file.size;
		// }
		// Approximate to the closest prefixed unit
		const units = [
			"B",
			"KB",
			"MB",
			"GB",
			"TB",
			"PB",
			"EB",
			"ZB",
			"YB",
		];
		const exponent = Math.min(
			Math.floor(Math.log(numberOfBytes) / Math.log(1024)),
			units.length - 1,
		);
		const approx = numberOfBytes / 1024 ** exponent;
		const output = (exponent === 0) ? `${numberOfBytes} octets` : `${new Intl.NumberFormat().format(approx.toFixed(3))} ${units[exponent]}`;
		// const output = (exponent === 0) ? `${numberOfBytes} bytes` : `${approx.toFixed(3)} ${units[exponent]} (${numberOfBytes} bytes)`;
		// console.log(new Intl.NumberFormat('fr-FR', { maximumSignificantDigits: 3 }).format(approx));
		return output;
	},
	imgToBase64: async (src, outputFormat = 'image/png') => { // Most of the time throws error : The operation is insecure.
		const imgToExport = document.getElementById('imgToConvertToBase64'); // Hidden Element on index page
		imgToExport.setAttribute('src', src);
		let canvas = document.createElement('canvas');
		canvas.width = imgToExport.width;
		canvas.height = imgToExport.height;
		canvas.getContext('2d').drawImage(imgToExport, 0, 0);
		return canvas.toDataURL(outputFormat)
	},
	setupCanvases: function() {
		const canvases = document.querySelectorAll('canvas');
		canvases.forEach((canvas, canvasArrayIndex) => {
			// Initializing canvas Object in globals.canvas Array of Objects
			const canvasInit = {
				canvas: canvasArrayIndex,
				ctx: null,
				flag: false,
				prevX: 0,
				currX: 0,
				prevY: 0,
				currY: 0,
				dot_flag: false
			};
			if(!globals.canvases[canvasArrayIndex]) globals.canvases.push(canvasInit);
			globals.canvases[canvasArrayIndex].ctx = canvas.getContext("2d");
			// globals.canvases[Array.prototype.indexOf.call(canvases, canvas)].ctx = canvas.getContext("2d");
			// w = canvas.width;
			// h = canvas.height;
			canvas.addEventListener("mousemove", function (e) {
				App.findxy('move', e, canvas, 'mouse');
			}, false);
			canvas.addEventListener("mousedown", function (e) {
				App.findxy('down', e, canvas, 'mouse');
			}, false);
			canvas.addEventListener("mouseup", function (e) {
				App.findxy('up', e, canvas, 'mouse');
			}, false);
			canvas.addEventListener("mouseout", function (e) {
				App.findxy('out', e, canvas, 'mouse');
			}, false);
			canvas.addEventListener("touchstart", function (e) {
				App.findxy('down', e, canvas, 'touch');
			}, false);
			canvas.addEventListener("touchmove", function (e) {
				App.findxy('move', e, canvas, 'touch');
			}, false);
			canvas.addEventListener("touchend", function (e) {
				App.findxy('up', e, canvas, 'touch');
			}, false);
			canvas.addEventListener("touchcancel", function (e) {
				App.findxy('out', e, canvas, 'touch');
			}, false);
			// Setting Erase Buttons...
			$(canvas).closest('div').find('button').attr('data-canvas', canvasArrayIndex);
		})
	},
	findxy: function(res, e, canvas, eventType) {
		if(eventType=='touch') e.preventDefault();
		// https://gist.github.com/bencentra/91350fe91c377c1ca574
		const canvases = document.querySelectorAll('canvas');
		const currCanvas = Array.prototype.indexOf.call(canvases, canvas);
		const rect = canvas.getBoundingClientRect();
		let clientX = (eventType=='touch') ? e.touches[0].clientX  : e.clientX;
		let clientY = (eventType=='touch') ? e.touches[0].clientY : e.clientY;
		if (res == 'down') {
			globals.canvases[currCanvas].prevX = globals.canvases[currCanvas].currX;
			globals.canvases[currCanvas].prevY = globals.canvases[currCanvas].currY;
			globals.canvases[currCanvas].currX = clientX - rect.left;
			globals.canvases[currCanvas].currY = clientY - rect.top;

			globals.canvases[currCanvas].flag = true;
			globals.canvases[currCanvas].dot_flag = true;
			if (globals.canvases[currCanvas].dot_flag) {
				globals.canvases[currCanvas].ctx.beginPath();
				globals.canvases[currCanvas].ctx.fillStyle = "black";
				globals.canvases[currCanvas].ctx.fillRect(globals.canvases[currCanvas].currX, globals.canvases[currCanvas].currY, 2, 2);
				globals.canvases[currCanvas].ctx.closePath();
				globals.canvases[currCanvas].dot_flag = false;
			}
		}
		if (res == 'up' || res == "out") {
			globals.canvases[currCanvas].flag = false;
		}
		if (res == 'move') {
			if (globals.canvases[currCanvas].flag) {
				// const [x, y] = App.getTransformedPoint(clientX, clientY, canvas);
				globals.canvases[currCanvas].prevX = globals.canvases[currCanvas].currX;
				globals.canvases[currCanvas].prevY = globals.canvases[currCanvas].currY;
				globals.canvases[currCanvas].currX = clientX - rect.left;
				globals.canvases[currCanvas].currY = clientY - rect.top;
				App.draw();
			}
		}
	},
	getTransformedPoint: function (x, y, canvas) {
		const context = canvas.getContext('2d')
		const transform = context.getTransform();
		const transformedX = x - transform.e;
		const transformedY = y - transform.f;
		return [transformedX, transformedY];
	},
	draw: function () {
		const canvases = document.querySelectorAll('canvas');
		for(let canvas of canvases) {
			const currCanvas = Array.prototype.indexOf.call(canvases, canvas)
			// console.log(globals.canvases[currCanvas])
			globals.canvases[currCanvas].ctx.beginPath();
			globals.canvases[currCanvas].ctx.moveTo(globals.canvases[currCanvas].prevX, globals.canvases[currCanvas].prevY);
			globals.canvases[currCanvas].ctx.lineTo(globals.canvases[currCanvas].currX, globals.canvases[currCanvas].currY);
			globals.canvases[currCanvas].ctx.strokeStyle = "black";
			globals.canvases[currCanvas].ctx.lineWidth = 2;
			globals.canvases[currCanvas].ctx.stroke();
			globals.canvases[currCanvas].ctx.closePath();
		}
	},
	eraseCanvas: function(btn, event) {
		event.preventDefault()
		const canvas = document.querySelectorAll('canvas')[Number(btn.getAttribute('data-canvas'))]
		globals.canvases[Number(btn.getAttribute('data-canvas'))].ctx.clearRect(0, 0, canvas.width, canvas.height);
	},
	isCanvasEmpty: (canvas) => {
		const blank = document.createElement('canvas');
		blank.width = canvas.width;
		blank.height = canvas.height;
		const isIt = (canvas.toDataURL() === blank.toDataURL());
		blank.remove();
		return isIt;
	},
	btnCheckThatBox: function(thisBtn) {
		const box2Check = $(thisBtn).prev('input[type="checkbox"]');
		let state = $(box2Check).is(':checked');
		state = !state;
		$(box2Check).attr('checked', state);
	},
	darkSwitchAdditionnalClasses: function(thisBtn) {
		let state = $(thisBtn).is(':checked');
		if(state) $('.sidenav').removeClass('sidenav-light').addClass('sidenav-dark');
		else $('.sidenav').removeClass('sidenav-dark').addClass('sidenav-light');
		$('.sideNavLogo').toggleClass('d-none');
	},
	fullScreen: function(){
		$('body').addClass('sidenav-toggled');
		$('#sidebarToggle').html('<i class="fa fa-2x fa-bars"></i>');
		$('header').hide();
		$('#mainContent').removeClass('mt-n10');
		$('#fullScreenBack').show();
	},
	fullScreenBack: function(){
		if(localStorage.getItem('sb|sidebar-toggle') !== 'true') {
			$('body').removeClass('sidenav-toggled');
			$('#sidebarToggle').html('<i class="fa fa-2x fa-angle-double-left"></i>');
		}
		$('header').show();
		$('#mainContent').addClass('mt-n10');
		$('#fullScreenBack').hide();
	},
	refreshSettingsTextAreas: function(myFormDiv, targetElements, event) {
		event.preventDefault()
		const myForm = document.getElementById(myFormDiv)
		const fieldsObject = {'fullname_s':'', 'address_s': '', 'address2_s': '', 'address_s': '', 'zip_s': '', 'city_s': '', 'phone_s': '', 'phone2_s': '', 'mail_s': '', 'siret_s': '', 'tva_s': '', 'rcs_s': '', 'ape_s': '', 'capital_s': '', 'website_s': ''}
		Object.entries(fieldsObject).forEach(([key, value]) =>  {
			fieldsObject[key] = myForm.querySelector(`[data-field="${key}"]`).value
		})
		let headers = `${fieldsObject.fullname_s}\r\n${fieldsObject.address_s}\r\n`
		if(fieldsObject.address2_s!='') headers += `${fieldsObject.address2_s}\r\n`
		headers += `${fieldsObject.zip_s}, ${fieldsObject.city_s}\r\n`;
		headers += (fieldsObject.phone2_s!='') ? `${fieldsObject.phone_s} - ${fieldsObject.phone2_s}\r\n` : `${fieldsObject.phone_s}\r\n`;
		headers += fieldsObject.mail_s;
		let footers = 'SIRET : '+fieldsObject.siret_s
		if(fieldsObject.tva_s!='') footers += ` - TVA Intracommunautaire : ${fieldsObject.tva_s}`
		if(fieldsObject.rcs_s!='') footers += ` - RCS : ${fieldsObject.rcs_s}`
		if(fieldsObject.ape_s!='') footers += ` - APE : ${fieldsObject.ape_s}`
		if(fieldsObject.capital_s!='') footers += ` - Capital : ${fieldsObject.capital_s}`
		if(fieldsObject.website_s!='') footers += `\r\n${fieldsObject.website_s}`
		if(targetElements.length>0) {
			targetElements.forEach((targetElem) => {
				const myTextArea = myForm.querySelector(`[data-field="${targetElem}"]`)
				if(targetElem.includes('header')) myTextArea.value = headers
				else myTextArea.value = footers // Footer then
			})
		}
	},
	calendarFileIntervention: function(auto_pl, thisBtn) {
		const thisBtnHtml = $(thisBtn).html()
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		$.post(globals.serverAddress, {...globals.baseQuery, auto_pl: auto_pl, req: 'planningController', action: 'calendarFileIntervention'}, function(data){
			if(data.ok=="ok") {
				const eventDate = {
					start: data.plannings.date_start_pl,
					end: data.plannings.date_end_pl
				}
				const summary = data.plannings.label_pl;
				let description = globals.serverBaseUrl+'events/'+auto_pl+'\\n\\n'+data.plannings.desc_pl+'\\n\\nIntervenant(s) :\\n'+data.assignments.join('\\n');
				if(data.uploads!='') description += '\\n\\nPièce(s) jointe(s) :\\n'+data.uploads.join('\\n')
				App.createIcsFile(eventDate, summary, description, "evenement_"+auto_pl);
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé cette intervention !");
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html(thisBtnHtml);
		});
	},
	createIcsFile: function(eventDate, summary, description, filename, download = true) {
		const dtStamp = App.convertDateIcs(eventDate.start)
		const uid = new Date(eventDate.start).getTime();
		const text =
			"BEGIN:VCALENDAR\n" +
			"METHOD:PUBLISH\n" +
			"PRODID:-//Microsoft Corporation//Outlook 12.0 MIMEDIR//EN\n" +
			"VERSION:2.0\n" +
			"BEGIN:VEVENT\n" +
			"DTSTAMP:"+dtStamp + "\n" +
			"UID:"+uid+"\n" +
			"DTSTART:" + dtStamp + "\n" +
			"DTEND:" + App.convertDateIcs(eventDate.end) + "\n" +
			"SUMMARY:" + summary + "\n" +
			"DESCRIPTION:" + description + "\n" +
			"END:VEVENT\n" +
			"END:VCALENDAR";
		console.log(text);
		var data = new File([text], { type: "text/plain" });
		// If we are replacing a previously generated file we need to manually revoke the object URL to avoid memory leaks.
		if(globals.icsFile !== null) window.URL.revokeObjectURL(globals.icsFile);
		globals.icsFile = window.URL.createObjectURL(data);
		if(download) {
			const a = $("<a>").attr("href", globals.icsFile).attr("download", filename+".ics").appendTo("footer");
			a[0].click();
			a.remove();
		}
		else return {icsFile: globals.icsFile, icsText: text};
	},
	convertDateIcs(date) {
		let event = new Date(date);
		const timezoneOffset = event.getTimezoneOffset() * 60000;
		event = new Date(event.getTime() - timezoneOffset).toISOString().split(".")[0];
		event = event.replaceAll("-", "").replaceAll(":", "");
		// event = event.split("T")[0];
		// event = event.split("-");
		// event = event.join("");
		return event;
	},
	add2TextArea: function(myFormDiv, targetElem) {
		const d = new Date();
		const frenchDate = App.makeNiceDateTime(d);
		const line = "____________________________________________________________________";
		const infos = frenchDate+' - '+globals.name+' '+globals.firstname+' - '+$(myFormDiv+' #Lieu').val();
		const previousVal = $(myFormDiv+' '+targetElem).val();
		console.warn(previousVal);
		if(previousVal!=="") $(myFormDiv+' '+targetElem).val(previousVal+'\r\n'+line+'\r\n'+infos+'\r\n').attr('readonly', false).trigger('focus');
		else $(myFormDiv+' '+targetElem).val(line+'\r\n'+infos+'\r\n').attr('readonly', false).trigger('focus');
	},
	//below taken from http://www.howtocreate.co.uk/tutorials/javascript/browserwindow
	getScrollXY: function () {
		var scrOfX = 0, scrOfY = 0;
		if( typeof( window.scrollY ) == 'number' ) {
			//Netscape compliant
			scrOfY = window.scrollY;
			scrOfX = window.scrollX;
		} else if( document.body && ( document.body.scrollLeft || document.body.scrollTop ) ) {
			//DOM compliant
			scrOfY = document.body.scrollTop;
			scrOfX = document.body.scrollLeft;
		} else if( document.documentElement && ( document.documentElement.scrollLeft || document.documentElement.scrollTop ) ) {
			//IE6 standards compliant mode
			scrOfY = document.documentElement.scrollTop;
			scrOfX = document.documentElement.scrollLeft;
		}
		return [ scrOfX, scrOfY ];
	},
	//taken from http://james.padolsey.com/javascript/get-document-height-cross-browser/
	getDocHeight: function () {
		var D = document;
		return Math.max(
			D.body.scrollHeight, D.documentElement.scrollHeight,
			D.body.offsetHeight, D.documentElement.offsetHeight,
			D.body.clientHeight, D.documentElement.clientHeight
		);
	},
};

// Expose App object to window object => need this to use click event in html
window.App = App;
require('./router.js');

(function() {
	// If not logged in and page is not login redirects to login page...
	if (localStorage.getItem('pass')!='OK' && document.URL.indexOf( 'login.html' ) === -1)
	{
		document.location.href='/login.html';
	}
	else if (document.URL.indexOf( 'login.html' ) !== -1) {
		// It's login page.
		App.init();
	}
	else {
		// pass ok...
		App.init();
	}
})();